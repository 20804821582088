import { createAxiosInstance as myAxios } from "../../services/Helper";

const axiosInstance = await myAxios();

export const migrateJobmtd = async (selectedTransferDept, encryptedData) => {
  try {
    const response = await axiosInstance.post(
      `/moveto${selectedTransferDept.name.toLowerCase()}/mtd`,
      encryptedData
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const fetchVersionDataApimtd = async (viewRole, formData, e) => {
  try {
    let data;
    if (viewRole === "DEV") {
      const resp = await axiosInstance.get(
        `mtd/sch/getVersion?ruleId=${formData.ruleId}&version=${e.value}`
      );
      // console.log("version",data.version);
      data = resp.data;
    } else {
      const resp = await axiosInstance.get(
        `${viewRole.toLowerCase()}/mtd/sch/getVersion?ruleId=${
          formData.ruleId
        }&version=${e.value}`
      );
      data = resp.data;
      //   console.log("this i smount data", data);
    }
    return data;
  } catch (error) {
    throw error;
  }
};

export const reuseVersionApimtd = async (viewRole, encryptedData) => {
  try {
    if (viewRole === "DEV") {
      await axiosInstance.post(`mtd/sch/setVersion`, encryptedData);
    } else {
      await axiosInstance.post(
        `${viewRole.toLowerCase()}/mtd/sch/setVersion`,
        encryptedData
      );
    }
  } catch (error) {
    throw error;
  }
};
