import React from "react";
import { Skeleton } from "primereact/skeleton";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

export default function FileViewSkeleton() {
  const items = Array.from({ length: 20 }); // Simulating 5 rows of skeleton loaders
  const columns = [
    "Column 1",
    "Column 2",
    "Column 3",
    "Column 4",
    "Column 5",
    "Column 6",
    "Column 7",
    "Column 8",
    "Column 9",
    "Column 10",
  ]; // Replace with actual column names

  const skeletonHeader = () => {
    return (
      <div className="flex justify-content-end">
        <Skeleton width="15rem" height="2.5rem" />
      </div>
    );
  };

  const skeletonBody = () => <Skeleton width="100%" height="1.5rem" />;

  return (
    <div className="card">
      <div className="d-flex">
        <DataTable
          value={items}
          paginator
          rows={20}
          tableStyle={{ minWidth: "50rem" }}
          header={skeletonHeader()}
        >
          {columns.map((col, index) => (
            <Column
              key={index}
              field={col}
              header={<Skeleton width="50%" />}
              body={skeletonBody}
            />
          ))}
        </DataTable>
      </div>
    </div>
  );
}
