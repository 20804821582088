import React, { useState } from "react";
// import SearchBar from "../components/UsersComponent/SearchBar";
import UserList from "../components/UsersComponent/UserList";
import Breadcrumbs from "../components/Breadcrumb";

const Users = () => {
  const [results, setResult] = useState([]);
  return (
    <>
        <Breadcrumbs />

      <UserList results={results} />
    </>
  );
};

export default Users;
