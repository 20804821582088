import React from "react";
import { Skeleton } from "primereact/skeleton";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

export default function ViewPGPTableSkeleton() {
  // Create dummy data for the skeleton (5 rows)
  const items = Array.from({ length: 5 }, (v, i) => i);

  return (
    <div className="card">
      <DataTable
        value={items}
        rowGroupMode="subheader"
        sortMode="single"
        sortField="name"
        tableStyle={{ minWidth: "50rem" }}
      >
        {/* Name Column */}
        <Column
          field="name"
          header="Name"
          style={{ width: "20%" }}
          body={<Skeleton />} // Skeleton placeholder
        ></Column>

        {/* Dev Column */}
        <Column
          field="PublicKey"
          header="PublicKey"
          style={{ width: "20%" }}
          body={<Skeleton />} // Skeleton placeholder
        ></Column>

        {/* QA Column */}
        <Column
          field="PrivateKey"
          header="PrivateKey"
          style={{ width: "20%" }}
          body={<Skeleton />} // Skeleton placeholder
        ></Column>

        {/* Prod Column */}
        <Column
          field="Password"
          header="Password"
          style={{ width: "20%" }}
          body={<Skeleton />} // Skeleton placeholder
        ></Column>

        {/* Action Column (Optional) */}
        <Column
          header="Actions"
          style={{ width: "10%" }}
          body={<Skeleton />} // Skeleton placeholder
        ></Column>
      </DataTable>
    </div>
  );
}
