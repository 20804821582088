export const checkEmptyField = (data) => {
  // console.log("this is the password data", data);
  let emptyErrors = {};
  {
    Object.entries(data).map(([key, value]) => {
      if (value === "" || value === null) {
        //   console.log("inside empty value")
        emptyErrors[key] = `${key} can't be empty`;
      }
    });
  }
  //   console.log(emptyErrors)
  // console.log("this is the error data", emptyErrors);
  return emptyErrors;
};
export const checkEmptyFieldForVault = (data) => {
  let emptyErrors = {};
  Object.entries(data).map(([key, value]) => {
    if (key === "dev" || key === "qa" || key === "prod") {
      // console.log("key: " + key + " value: ", value);
      Object.entries(value).map(([subKey, subValue]) => {
        if (subValue === "") {
          emptyErrors[`${subKey}_${key}`] = `${subKey} can't be empty`;
        }
        // console.log("subkey: "+subKey+" sabvalue: ",subValue)
      });
    }
  });
  return emptyErrors;
};
