import React, { useState } from "react";
import { createAxiosInstance as myAxios } from "../services/Helper";
import JSEncrypt from "jsencrypt";

// const [publicKey, setPublicKey] = useState(null);

const getPublicKeyInstance = async () => {
  const axiosInstance = await myAxios();
  const publickeyResponse = await axiosInstance
    .get("/encryption/publicKey")
    .then((res) => res)
    .catch((e) => e.response);

  if (publickeyResponse.status === 200) {
    const publicKey = publickeyResponse.data;
    // console.log("public key retrieve successfully ");
    // localStorage.setItem("publicKey", publicKey);
    // setPublicKey(publickeyResponse.data);
    return publicKey;
  } else {
    // localStorage.setItem("publicKey", null);
    // console.log("public key not found  ");
    // setPublicKey(null);
    return null;
  }
};

const encryptDataWithRSA = (data, publicKey) => {
  const encrypt = new JSEncrypt();
  encrypt.setPublicKey(publicKey);
  return encrypt.encrypt(data);
};

const generateAESKey = async () => {
  const key = await crypto.subtle.generateKey(
    {
      name: "AES-GCM",
      length: 128,
    },
    true,
    ["encrypt", "decrypt"]
  );
  return key;
};

const getAesKeyAsString = async (aesKey) => {
  const exported = await crypto.subtle.exportKey("raw", aesKey);
  return btoa(String.fromCharCode(...new Uint8Array(exported)));
};

const encryptwithAES = async (data, aeskey) => {
  const iv = crypto.getRandomValues(new Uint8Array(12));
  const encoder = new TextEncoder();
  const encodedData = encoder.encode(data);
  const encrypted = await crypto.subtle.encrypt(
    {
      name: "AES-GCM",
      iv: iv,
    },
    aeskey,
    encodedData
  );

  return {
    encryptedData: btoa(String.fromCharCode(...new Uint8Array(encrypted))),
    iv: btoa(String.fromCharCode(...iv)),
  };
};

// export const handleEncryptedData = async (data ) =>{
//     if(localStorage.getItem("publicKey") ){
//         const dataJson  = JSON.stringify(data);
//         const aesKey = await generateAESKey();
//         const aesKeyBase64 = await getAesKeyAsString (aesKey);
//         const encryptedDatawithAES = await encryptwithAES(dataJson, aesKey);

//         const encodedDataEncryption = {
//             encyptedAesKey : encryptDataWithRSA(aesKeyBase64, publicKey),
//             encryptedData : encryptedDatawithAES.encryptedData,
//             iv: encryptedDatawithAES.iv,
//         }
//         return encodedDataEncryption;
//     }
// }

// export const handleEncryptedData = async (data, publicKey) => {
//   if (publicKey) {
//     const dataJson = JSON.stringify(data);
//     const aesKey = await generateAESKey();
//     const aesKeyBase64 = await getAesKeyAsString(aesKey);
//     const encryptedDatawithAES = await encryptwithAES(dataJson, aesKey);
//     const encodedDataEncryption = {
//       encryptedAesKey: encryptDataWithRSA(aesKeyBase64, publicKey),
//       encryptedData: encryptedDatawithAES.encryptedData,
//       iv: encryptedDatawithAES.iv,
//     };
//     return encodedDataEncryption;
//   }
// };
export const handleEncryptedData = async (data) => {
  const publicKey = await getPublicKeyInstance();
  if (publicKey) {
    const dataJson = JSON.stringify(data);
    const aesKey = await generateAESKey();
    const aesKeyBase64 = await getAesKeyAsString(aesKey);
    const encryptedDatawithAES = await encryptwithAES(dataJson, aesKey);
    const encodedDataEncryption = {
      encryptedAesKey: encryptDataWithRSA(aesKeyBase64, publicKey),
      encryptedData: encryptedDatawithAES.encryptedData,
      iv: encryptedDatawithAES.iv,
    };
    return encodedDataEncryption;
  } else {
    // console.log("unable to get the public key ");
    return data;
  }
};

// handleEncryptedData , getPublicKeyInstance
