import React from "react";
import { Dialog } from "primereact/dialog";
import { ProgressSpinner } from "primereact/progressspinner";

const ConnectionTestDialog = ({
  visible,
  setVisible,
  loading,
  responseStatus,
  responseMessage,
}) => {
  return (
    <Dialog
      header="Connection Test"
      visible={visible}
      style={{ width: "25vw" }}
      onHide={() => setVisible(false)}
    >
      {loading ? (
        <div className="card flex justify-content-center">
          <ProgressSpinner
            style={{ width: "50px", height: "50px" }}
            strokeWidth="5"
            fill="var(--surface-ground)"
            animationDuration=".5s"
          />
        </div>
      ) : (
        <div>
          <h3
            className="m-0 py-3"
            style={{
              color: responseStatus === 200 ? "green" : "red",
            }}
          >
            Status: {responseStatus}
          </h3>
          <p className="m-0">Message: {responseMessage}</p>
        </div>
      )}
    </Dialog>
  );
};

export default ConnectionTestDialog;
