import React, { useState, useRef } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { Card } from "primereact/card";
import { ProgressSpinner } from "primereact/progressspinner";
import { useNavigate } from "react-router-dom";
import { Divider } from "primereact/divider";
import { LuFolderLock } from "react-icons/lu";
import { LuFolderKey } from "react-icons/lu";
import { TestConnectionSFTP2Service } from "../API Services/TestConnectionService";
import { checkEmptyField } from "../common/handleEmptyField";
import { encrptionService } from "../API Services/FileEncryption/encryptionFileService";
import {
  editPGPService,
  editSFTPService,
} from "../API Services/fetchSecretsService";
import { Skeleton } from "primereact/skeleton";

const EncryptionFile = () => {
  const company = localStorage.getItem("company");
  const navigate = useNavigate();
  const toast = useRef(null);
  const [loading, setLoading] = useState(false);
  const [encloading, setEncLoading] = useState(false);
  const [loadingTestConnection, setLoadingTestConnection] = useState(false);
  const [visible, setVisible] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");
  const [responseStatus, setResponseStatus] = useState("");
  const [encryptionVisibile, setEncryptionVisible] = useState(false);
  const [decryptionVisible, setDecryptionVisible] = useState(false);
  const [emptyErrors, setEmptyErrors] = useState({});
  const [selectedSftpOption, setSelectedSftpOption] = useState("");
  const [encryptionLoading, setEncryptionLoading] = useState(false);
  const [selectedPGPOption, setSelectedPGPOption] = useState("");
  const [sftpDetails, setSftpDetails] = useState([]);
  const [pgpDetails, setPgpDetails] = useState([]);

  // Regular expression to match either .csv or .csv.pgp extension
  // const csvFileRegex = /\.csv(\.pgp)?$/i;
  const csvFileRegex = /\.\w{2,3}(\.pgp)?$/i;

  const [sftpServer1, setSftpServer1] = useState({
    server1: "",
    port1: "",
    user1: "",
    pass1: "",
    filePath: "",
  });

  const [sftpServer2, setSftpServer2] = useState({
    server1: "",
    port1: "",
    user1: "",
    pass1: "",
    filePath: "",
  });

  const [formData, setFormData] = useState({
    sftpHost: "",
    sftPort: "",
    sftpUser: "",
    sftPassword: "",
    filePath: "",
    outputPath: "",
    secretName: "",
  });

  const resetFormData = () => {
    setFormData({
      sftpHost: "",
      sftPort: "",
      sftpUser: "",
      sftPassword: "",
      filePath: "",
      outputPath: "",
      secretName: "",
    });

    setSftpServer1({
      server1: "",
      port1: "",
      user1: "",
      pass1: "",
      filePath: "",
    });

    setSftpServer2({
      server1: "",
      port1: "",
      user1: "",
      pass1: "",
      filePath: "",
    });

    setSelectedSftpOption("");
    setSelectedPGPOption(null);
  };

  const handleSftpSelectChange = (event) => {
    const selectedName = event.target.value;
    setSelectedSftpOption(selectedName);

    setEmptyErrors({});

    const selectedData = sftpDetails.find(
      (item) => item.name === selectedName.name
    );
    if (selectedData) {
      const data = selectedData["dev"];
      setSftpServer1((prev) => ({
        ...prev,
        server1: data.host,
        port1: data.port,
        user1: data.username,
        pass1: data.password,
      }));
      setSftpServer2((prev) => ({
        ...prev,
        server1: data.host,
        port1: data.port,
        user1: data.username,
        pass1: data.password,
      }));

      setFormData((prev) => ({
        ...prev,
        sftpHost: data.host,
        sftPort: data.port,
        sftpUser: data.username,
        sftPassword: data.password,
      }));
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    if (name === "filePath") {
      setSftpServer1((prev) => ({ ...prev, filePath: value }));
    } else if (name === "outputPath") {
      // console.log(value, " is the output file path value ");
      setSftpServer2((prev) => ({
        ...prev,
        filePath: filePathExtract(value),
      }));
    }

    setEmptyErrors({});
  };

  const handlecloseEncryptionDialog = () => {
    setDecryptionVisible(false);
    setEncryptionVisible(false);
    resetFormData();
    setEmptyErrors({});
  };
  const handlecloseDecryptionDialog = () => {
    setDecryptionVisible(false);
    resetFormData();
    setEmptyErrors({});
  };

  const filePathExtract = (pathtype) => {
    let lastIndexOfSlash = pathtype.lastIndexOf("/");
    if (lastIndexOfSlash !== -1) {
      let directoryPath = pathtype.substring(0, lastIndexOfSlash);
      return directoryPath;
    }
    return pathtype;
  };

  const callTestSFTP = async () => {
    // setLoading(true);
    setLoadingTestConnection(true);

    // console.log(handleCheckValidOrEmpty(), "is the handle chek ");
    if (handleCheckValidOrEmpty()) {
      return;
    }

    setVisible(true);
    let sftp_connect = null;
    let sftp_connect2 = null;
    try {
      sftp_connect = await TestConnectionSFTP2Service(sftpServer1);
      sftp_connect2 = await TestConnectionSFTP2Service(sftpServer2);

      if (sftp_connect.status === 200 && sftp_connect2.status === 200) {
        let status = sftp_connect.status;
        let message = sftp_connect.messages;

        setResponseMessage(message);
        setResponseStatus(status);
      }
    } catch (e) {
      console.log(e);

      let errorResponse = e.response ? e.response : null;
      if (errorResponse == null) {
        setResponseMessage("Server Link Failed");
        setResponseStatus("500");

        setTimeout(() => {
          localStorage.clear();
          navigate("/login");
        }, 2000);
        toast.current.show({
          severity: "error",
          summary: "Network Error",
          detail: "Server Link Failure",
          life: 2000,
        });

        return;
      } else if (errorResponse.status === 401) {
        setResponseMessage("Session Expired ");
        setResponseStatus("401");
        setTimeout(() => {
          navigate("/login");
        }, 2000);
        toast.current.show({
          severity: "error",
          summary: "Session Timeout",
          detail: "Session Expired!, Try Login Again.",
          life: 2000,
        });
        return;
      } else {
        if (sftp_connect === null && sftp_connect2 === null) {
          toast.current.show({
            severity: "error",
            summary: "File Path Error ",
            detail: " Check Source and Target File Path",
            life: 2000,
          });
        } else if (sftp_connect === null) {
          toast.current.show({
            severity: "error",
            summary: "Source File Path ",
            detail: " Check Source File Path with File Name",
            life: 2000,
          });
        } else if (sftp_connect2 === null) {
          toast.current.show({
            severity: "error",
            summary: "Target File Path ",
            detail: " Check Target File Path ",
            life: 2000,
          });
        }
        setResponseMessage(errorResponse.data.messages || "Failed to connect.");
        setResponseStatus(errorResponse.data.status || "Error");
      }
    } finally {
      setLoadingTestConnection(false);
    }
  };

  const resetStatausConnection = () => {
    setResponseMessage("");
    setResponseStatus("");
  };

  const handleTestConnection = async (e) => {
    e.preventDefault();
    resetStatausConnection();

    callTestSFTP();
  };

  const checkValidFilePath = (filePath) => {
    if (csvFileRegex.test(filePath)) {
      return true;
    } else {
      return false;
    }
  };

  const handlePGPSelectChange = (e) => {
    setSelectedPGPOption(e.value);
    setFormData((prev) => ({ ...prev, secretName: e.value }));
  };

  const handleCheckValidOrEmpty = () => {
    let emptyError = checkEmptyField(formData);

    if (Object.keys(emptyError).length !== 0) {
      setEmptyErrors(emptyError);

      toast.current.show({
        severity: "info",
        summary: "Missing Fields",
        detail: "All fields must be filled",
        life: 3000,
      });
      return true;
    }

    if (!checkValidFilePath(formData.filePath)) {
      setEmptyErrors({ filePath: formData.filePath });
      toast.current.show({
        severity: "error",
        summary: "Invalid File Path with File Name ",
        detail: "Provide Valid File Name with File Path at Source ",
        life: 3000,
      });
      return true;
    }
    if (!checkValidFilePath(formData.outputPath)) {
      setEmptyErrors({ outputPath: formData.outputPath });
      toast.current.show({
        severity: "error",
        summary: "Invalid File Path with File Name ",
        detail: "Provide Valid File Name with File Path at Target ",
        life: 3000,
      });
      return true;
    }

    return false;
  };

  const handleEncryption = async (e, actionType) => {
    e.preventDefault();

    if (handleCheckValidOrEmpty()) {
      return;
    }

    try {
      setEncryptionLoading(true);
      const apiUrl =
        actionType === "Encryption" ? "file/encrypt" : "file/decrypt";
      const response = await encrptionService(apiUrl, formData);
      if (response.status == 200) {
        toast.current.show({
          severity: "success",
          summary: "success",
          detail: `${actionType} successfull`,
          life: 2000,
        });
      } else {
        setEncryptionLoading(false);
        toast.current.show({
          severity: "error",
          summary: "error",
          detail: `${actionType} failed`,
          life: 2000,
        });
      }
      setEncryptionLoading(false);
    } catch (error) {
      setEncryptionLoading(false);
      toast.current.show({
        severity: "error",
        summary: "error",
        detail: "error occurred during API call",
        life: 2000,
      });
    } finally {
      setEncryptionVisible(false);
      setDecryptionVisible(false);
      resetFormData();
    }
  };

  const footerContent = (
    <div>
      <Button
        type="submit"
        severity="primary"
        label={"Done"}
        icon="pi pi-check"
        onClick={(e) => {
          e.preventDefault();
          setVisible(false);
        }}
        autoFocus
      />
    </div>
  );

  const handleEncrption = async () => {
    // resetStatausConnection();
    // setLoading(true);
    setEncryptionVisible(true);
    setEncLoading(true);
    const response = await editSFTPService(company);
    if (response.status !== undefined && response.status === 401) {
      setLoading(false);
      toast.error("Token Expired, Please Login Again", {
        theme: "colored",
      });
      localStorage.clear();
      navigate("/login");
      return;
    }

    const responsePGP = await editPGPService(company);
    if (responsePGP.status !== undefined && responsePGP.status === 401) {
      setLoading(false);
      toast.error("Token Expired, Please Login Again", {
        theme: "colored",
      });
      localStorage.clear();
      navigate("/login");
      return;
    }

    setSftpDetails(response);
    setPgpDetails(responsePGP);

    setEncLoading(false);
  };

  const handleDecrption = async () => {
    // resetStatausConnection();
    setDecryptionVisible(true);

    setEncLoading(true);
    const response = await editSFTPService(company);
    if (response.status !== undefined && response.status === 401) {
      setLoading(false);
      toast.error("Token Expired, Please Login Again", {
        theme: "colored",
      });
      localStorage.clear();
      navigate("/login");
      return;
    }

    const responsePGP = await editPGPService(company);
    if (responsePGP.status !== undefined && responsePGP.status === 401) {
      setLoading(false);
      toast.error("Token Expired, Please Login Again", {
        theme: "colored",
      });
      localStorage.clear();
      navigate("/login");
      return;
    }
    setSftpDetails(response);
    setPgpDetails(responsePGP);
    setEncLoading(false);
  };

  return (
    <div>
      <div>
        <Toast ref={toast} />
        <div className="card" style={{ width: "100%" }}>
          <h1 className="Header">File Encryption and Decryption </h1>
          <Divider />
        </div>
        <div className="card1">
          <Card title="Encrypt Decrypt">
            <div style={{ marginTop: "-20px" }}>
              <p>Select the type of Action you want to perform</p>
            </div>

            <div className="card flex flex-wrap justify-content-start">
              <div
                className="SFTPSecrets hover-effect"
                style={{
                  display: "flex", // Align items in a row (horizontal)
                  alignItems: "center", // Vertically center the items
                  padding: "20px", // Add padding to give some space around the content
                  borderRadius: "8px", // Optional: rounded corners for aesthetics
                  margin: "10px", // Optional: margin around the div
                  width: "100%",
                }}
                onClick={handleEncrption}
              >
                <div>
                  <LuFolderLock style={{ fontSize: "30px" }} />
                </div>
                <div style={{ marginLeft: "20px", marginTop: "-20px" }}>
                  <h4>File Encryption</h4>
                  <div style={{ marginTop: "-15px" }}>
                    Encrypt your files securely
                  </div>
                </div>
                <div style={{ marginLeft: "auto" }}>
                  <i className="pi pi-angle-right"></i>
                </div>
              </div>
              <div
                className="DBSecrets hover-effect"
                style={{
                  display: "flex", // Align items in a row (horizontal)
                  alignItems: "center", // Vertically center the items
                  padding: "20px", // Add padding to give some space around the content
                  borderRadius: "8px", // Optional: rounded corners for aesthetics
                  margin: "10px", // Optional: margin around the div
                  width: "100%",
                }}
                onClick={handleDecrption}
              >
                <LuFolderKey style={{ fontSize: "30px" }} />

                <div style={{ marginLeft: "20px", marginTop: "-20px" }}>
                  <h4>File Decryption</h4>
                  <div style={{ marginTop: "-15px" }}>
                    Decrypt your Files securely
                  </div>
                </div>
                <div style={{ marginLeft: "auto" }}>
                  <i className="pi pi-angle-right"></i>
                </div>
              </div>
            </div>
          </Card>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center", // Center horizontally
            alignItems: "center", // Center vertically
          }}
        >
          <Card
            style={{
              marginTop: "30px",
              background: " rgba(88, 219, 255, 0.2)",
              width: "70%",
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <i
                className="pi pi-lock"
                style={{
                  fontSize: "20px",
                  marginRight: "10px",
                }}
              ></i>
              <h4 style={{ margin: 0 }}>Secure Files</h4>
            </div>
            <div
              style={{
                marginTop: "10px",
                marginLeft: "30px",
              }}
            >
              <p>
                All Files are Encrypted and Decrypted using industry-standard
                algorithms and stored Securely. Only authorized users with
                appropriate permissions can access these Files.
              </p>
            </div>
          </Card>
        </div>

        {/* Encryption Dialog */}
        <Dialog
          header="File Encryption"
          visible={encryptionVisibile}
          style={{ width: "60vw" }}
          onHide={handlecloseEncryptionDialog}
          dismissableMask={true}
          closeOnEscape={true}
        >
          <div className="flex flex-column align-items-start justify-content-start">
            <form className="w-12 sm:w-8 m-auto p-3">
              <div className="grid">
                <div className="col-12 sm:col-6">
                  <div className="grid w-full">
                    <div className="col-12">
                      <span className="text-lg font-bold uppercase"></span>
                    </div>

                    <div className="col-12">
                      <div className="flex-auto">
                        {encloading ? (
                          <Skeleton width="100%" height="2rem" />
                        ) : (
                          <label
                            htmlFor="dns_input"
                            className="font-semibold text-xs block mx-1 mb-1"
                          >
                            Select SFTP Details
                          </label>
                        )}
                        {encloading ? (
                          <Skeleton width="100%" height="2rem" />
                        ) : (
                          <Dropdown
                            value={selectedSftpOption || null}
                            onChange={handleSftpSelectChange}
                            options={sftpDetails || []}
                            optionLabel="name"
                            placeholder="Select a Source SFTP"
                            className="w-full"
                            invalid={emptyErrors.sftpHost}
                          />
                        )}
                      </div>
                    </div>

                    <div className="col-12">
                      <div className="flex-auto">
                        {encloading ? (
                          <Skeleton width="100%" height="2rem" />
                        ) : (
                          <label
                            htmlFor="path_input"
                            className="font-semibold text-xs block mx-1 mb-1"
                          >
                            File Path
                          </label>
                        )}
                        {encloading ? (
                          <Skeleton width="100%" height="2rem" />
                        ) : (
                          <InputText
                            id="path_input"
                            name="filePath"
                            className="w-full"
                            value={formData.filePath}
                            onChange={handleChange}
                            placeholder="Enter File Path"
                            invalid={emptyErrors.filePath}
                          />
                        )}
                      </div>
                    </div>

                    {/* ///////////////////Added the new Input for filename////////////////// */}
                    {/* <div className="col-12">
                      <div className="flex-auto">
                        <label
                          htmlFor="path_input"
                          className="font-semibold text-xs block mx-1 mb-1"
                        >
                          Source File Name
                        </label>
                        <InputText
                          id="file_input"
                          name="fileName"
                          className="w-full"
                          value={setSftpServer1.fileName}
                          onChange={handleFilePath}
                          placeholder="Enter File Path"
                          invalid={emptyErrors.fileName}
                        />
                      </div>
                    </div> */}
                    {/* ///////////////////////////////ends here//////////////////////// */}
                    <div className="col-12">
                      <div className="flex-auto">
                        {encloading ? (
                          <Skeleton width="100%" height="2rem" />
                        ) : (
                          <Button
                            label="Check File Status"
                            type="test"
                            icon="pi pi-check"
                            onClick={(e) => handleTestConnection(e, 1)}
                            className="border-round-lg w-full"
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className="col-12 sm:col-6"
                  style={{ position: "relative", top: "18px" }}
                >
                  <div className="grid w-full">
                    <div className="col-12">
                      <div className="flex-auto">
                        {encloading ? (
                          <Skeleton width="100%" height="2rem" />
                        ) : (
                          <label
                            htmlFor="archiveLocation_input"
                            className="font-semibold text-xs block mx-1 mb-1"
                          >
                            Output Path
                          </label>
                        )}
                        {encloading ? (
                          <Skeleton width="100%" height="2rem" />
                        ) : (
                          <InputText
                            id="OutputLocation_input"
                            name="outputPath"
                            className="w-full"
                            value={formData.outputPath}
                            onChange={handleChange}
                            placeholder="Enter Output Path"
                            invalid={emptyErrors.outputPath}
                          />
                        )}
                      </div>
                    </div>

                    <div className="col-12">
                      <div className="flex-auto">
                        {encloading ? (
                          <Skeleton width="100%" height="2rem" />
                        ) : (
                          <label
                            htmlFor="archiveLocation_input"
                            className="font-semibold text-xs block mx-1 mb-1"
                          >
                            Secret Name
                          </label>
                        )}
                        {encloading ? (
                          <Skeleton width="100%" height="2rem" />
                        ) : (
                          <Dropdown
                            value={selectedPGPOption || null}
                            onChange={handlePGPSelectChange}
                            options={pgpDetails || []}
                            placeholder="Select a PGP Secret"
                            className="w-full"
                            invalid={emptyErrors.secretName}
                          />
                        )}
                      </div>
                    </div>

                    <div className="col-12 ">
                      <div className="flex-auto ">
                        {encloading ? (
                          <Skeleton width="100%" height="2rem" />
                        ) : (
                          <Button
                            label="Encrypt"
                            type="test"
                            icon="pi pi-check"
                            onClick={(e) => handleEncryption(e, "Encryption")}
                            className="border-round-lg w-full"
                            loading={encryptionLoading}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
            <Dialog
              header="Connection Test"
              visible={visible}
              style={{ width: "30vw" }}
              onHide={() => setVisible(false)}
              footer={footerContent}
            >
              <Card>
                {loadingTestConnection ? (
                  <div className="card flex justify-content-center">
                    <ProgressSpinner
                      style={{ width: "50px", height: "50px" }}
                      strokeWidth="5"
                      fill="var(--surface-ground)"
                      animationDuration=".5s"
                    />
                  </div>
                ) : (
                  <>
                    <h3 className="m-0 py-3">Status: {responseStatus}</h3>
                    <p className="m-0">Message: {responseMessage}</p>
                  </>
                )}
              </Card>
            </Dialog>
          </div>
        </Dialog>

        {/* Decryption Dialog */}
        <Dialog
          header="File Decryption"
          visible={decryptionVisible}
          style={{ width: "60vw" }}
          onHide={handlecloseDecryptionDialog}
          dismissableMask={true}
          closeOnEscape={true}
        >
          <div className="flex flex-column align-items-start justify-content-start">
            <form className="w-12 sm:w-8 m-auto p-3">
              <div className="grid">
                <div className="col-12 sm:col-6">
                  <div className="grid w-full">
                    <div className="col-12">
                      <span className="text-lg font-bold uppercase"></span>
                    </div>

                    <div className="col-12">
                      <div className="flex-auto">
                        {encloading ? (
                          <Skeleton width="100%" height="2rem" />
                        ) : (
                          <label
                            htmlFor="dns_input"
                            className="font-semibold text-xs block mx-1 mb-1"
                          >
                            Select SFTP Details
                          </label>
                        )}
                        {encloading ? (
                          <Skeleton width="100%" height="2rem" />
                        ) : (
                          <Dropdown
                            value={selectedSftpOption || null}
                            onChange={handleSftpSelectChange}
                            options={sftpDetails || []}
                            optionLabel="name"
                            placeholder="Select a Source SFTP"
                            className="w-full"
                            invalid={emptyErrors.sftpHost}
                          />
                        )}
                      </div>
                    </div>

                    <div className="col-12">
                      <div className="flex-auto">
                        {encloading ? (
                          <Skeleton width="100%" height="2rem" />
                        ) : (
                          <label
                            htmlFor="path_input"
                            className="font-semibold text-xs block mx-1 mb-1"
                          >
                            File Path
                          </label>
                        )}
                        {encloading ? (
                          <Skeleton width="100%" height="2rem" />
                        ) : (
                          <InputText
                            id="path_input"
                            name="filePath"
                            className="w-full"
                            value={formData.filePath}
                            onChange={handleChange}
                            placeholder="Enter File Path"
                            invalid={emptyErrors.filePath}
                          />
                        )}
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="flex-auto">
                        {encloading ? (
                          <Skeleton width="100%" height="2rem" />
                        ) : (
                          <Button
                            label="Check File Status"
                            type="test"
                            icon="pi pi-check"
                            onClick={(e) => handleTestConnection(e, 1)}
                            className="border-round-lg w-full"
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className="col-12 sm:col-6"
                  style={{ position: "relative", top: "18px" }}
                >
                  <div className="grid w-full">
                    <div className="col-12">
                      <div className="flex-auto">
                        {encloading ? (
                          <Skeleton width="100%" height="2rem" />
                        ) : (
                          <label
                            htmlFor="archiveLocation_input"
                            className="font-semibold text-xs block mx-1 mb-1"
                          >
                            Output Path
                          </label>
                        )}
                        {encloading ? (
                          <Skeleton width="100%" height="2rem" />
                        ) : (
                          <InputText
                            id="OutputLocation_input"
                            name="outputPath"
                            className="w-full"
                            value={formData.outputPath}
                            onChange={handleChange}
                            placeholder="Enter Output Path"
                            invalid={emptyErrors.outputPath}
                          />
                        )}
                      </div>
                    </div>

                    <div className="col-12">
                      <div className="flex-auto">
                        {encloading ? (
                          <Skeleton width="100%" height="2rem" />
                        ) : (
                          <label
                            htmlFor="archiveLocation_input"
                            className="font-semibold text-xs block mx-1 mb-1"
                          >
                            Secret Name
                          </label>
                        )}
                        {encloading ? (
                          <Skeleton width="100%" height="2rem" />
                        ) : (
                          <Dropdown
                            value={selectedPGPOption || null}
                            onChange={handlePGPSelectChange}
                            options={pgpDetails || []}
                            placeholder="Select a PGP Secret"
                            className="w-full"
                            invalid={emptyErrors.secretName}
                          />
                        )}
                      </div>
                    </div>

                    <div className="col-12 ">
                      <div className="flex-auto ">
                        {encloading ? (
                          <Skeleton width="100%" height="2rem" />
                        ) : (
                          <Button
                            label="Decrypt"
                            type="test"
                            icon="pi pi-check"
                            onClick={(e) => handleEncryption(e, "Decryption")}
                            className="border-round-lg w-full"
                            loading={encryptionLoading}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
            <Dialog
              header="Connection Test"
              visible={visible}
              style={{ width: "30vw" }}
              onHide={() => setVisible(false)}
              footer={footerContent}
            >
              <Card>
                {loadingTestConnection ? (
                  <div className="card flex justify-content-center">
                    <ProgressSpinner
                      style={{ width: "50px", height: "50px" }}
                      strokeWidth="5"
                      fill="var(--surface-ground)"
                      animationDuration=".5s"
                    />
                  </div>
                ) : (
                  <>
                    <h3 className="m-0 py-3">Status: {responseStatus}</h3>
                    <p className="m-0">Message: {responseMessage}</p>
                  </>
                )}
              </Card>
            </Dialog>
          </div>
        </Dialog>
      </div>
    </div>
  );
};

export default EncryptionFile;
