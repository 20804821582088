import React, { useState, useRef } from "react";
import "./CompanyRegistration.css"; // Optional custom styles
import "primereact/resources/themes/saga-blue/theme.css"; // Theme for PrimeReact
import "primereact/resources/primereact.min.css"; // PrimeReact styles
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import { Card } from "primereact/card";
import images from "..//assets/images"; // Adjust this path based on your project structure
import { Dialog } from "primereact/dialog"; // Import Dialog for pop-up
// import { createAxiosInstance as myAxios } from "../services/Helper";
import { useNavigate } from "react-router-dom";
import { checkEmptyField } from "../common/handleEmptyField";
import {
  registerCompany,
  verifyOtp,
} from "../API Services/companyregistrationservice";
import {
  handleEncryptedData,
  // getPublicKeyInstance,
} from "../Encrypt API/ModifiedEncrypt";
import { FileUpload } from "primereact/fileupload";

function CompanyRegistration() {
  const toast = useRef(null); // Toast reference
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    companyName: "",
    companyDesignation: "",
    companyPhone: "",
  });

  const [companyLogo, setCompanyLogo] = useState(null);
  const [otp, setOtp] = useState("");
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [displayDialog, setDisplayDialog] = useState(false); // State for dialog visibility
  const [loading, setLoading] = useState(false); // Loading state for spinner

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleLogoChange = (e) => {
    setCompanyLogo(e.target.files[0]);
  };

  // Email validation regex (accepts only 2-3 character TLDs like .com, .org)
  const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,3}$/;
  const [emptyErrors, setEmptyErrors] = useState({});
  
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Step 1: Check for empty fields
    let emptyErrorMsgs = checkEmptyField(formData);
    if (Object.keys(emptyErrorMsgs).length !== 0) {
      setEmptyErrors(emptyErrorMsgs);
      return;
    }

    // Step 2: Manual email validation
    if (!emailPattern.test(formData.email)) {
      toast.current.show({
        severity: "error",
        summary: "Invalid Email",
        detail:
          "Please enter a valid email address with a 2-3 character domain (e.g., .com, .org).",
        life: 3000,
      });
      return;
    }

    // Step 3: Check if all required fields are filled
    if (
      !formData.name ||
      !formData.email ||
      !formData.companyName ||
      !formData.companyDesignation ||
      !formData.companyPhone ||
      !companyLogo
    ) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "All fields, including company logo, are required.",
        life: 3000,
      });
      return;
    }

    setLoading(true);
    // const publicKey = await getPublicKeyInstance();
    // console.log(publicKey, " public key ");
    // Step 4: Call the API service for registration
    try {
      const encryptedData = await handleEncryptedData(formData);

      // console.log(encryptedData, " is the encrypted data ");
      // console.log(companyLogo, " is the company logo ");
      // const encryptedData = await handleEncryptedRequest(formData);
      const response = await registerCompany(encryptedData, companyLogo); // Call the service function

      // Step 5: Handle the response
      if (response.status === 200) {
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail:
            "OTP sent to your email. Please enter the OTP to complete registration.",
          life: 3000,
        });
        setIsOtpSent(true);
      } else if (response.status === 208) {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: response.data,
          life: 3000,
        });
        // setIsOtpSent(true);
      }
      setLoading(false);
    } catch (err) {
      // Step 6: Error handling
      if (err.response && err.response.status === 400) {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: err.response.data,
          life: 3000,
        });
      } else if (err.response && err.response.status === 403) {
        toast.current.show({
          severity: "warn",
          summary: "Warn",
          detail: err.response.data,
          life: 3000,
        });
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "An unexpected error occurred. Please try again later.",
          life: 3000,
        });
      }
      setLoading(false);
    }
  };

  const handleOtpSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (!otp) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Please enter the OTP.",
        life: 3000,
      });
      setLoading(false);
      return;
    }

    // Set loading to true to show spinner

    // const axiosInstance = await myAxios();

    // try {
    //   const response = await axiosInstance.post(
    //     `/companysignup/verify?email=${formData.email}&otp=${otp}`
    //   );

    try {
      const response = await verifyOtp(formData, otp);

      if (response.status === 200) {
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Waiting for admin approval.",
          life: 3000,
        });
        setFormData({
          name: "",
          email: "",
          companyName: "",
          companyDesignation: "",
          companyPhone: "",
        });
        setOtp("");
        setCompanyLogo(null);
        setIsOtpSent(false);
        setDisplayDialog(true); // Open dialog for admin approval message
      }
    } catch (err) {
      setLoading(false);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Invalid OTP. Please try again.",
        life: 3000,
      });
      setLoading(false);
    }
  };

  const dialogFooter = (
    <div>
      <Button
        label="Close"
        onClick={() => {
          setDisplayDialog(false);
          // Navigate to home page when closing dialog
          navigate("/");
        }}
      />
    </div>
  );

  const handleHome = () => {
    navigate("/");
  };

  return (
    <div className="flex flex-column w-screen h-screen overflow-y-auto surface-50">
      <Toast ref={toast} /> {/* Ensure Toast is here */}
      <div className="w-12 md:w-4 lg:w-4 m-auto">
        <div className="flex justify-content-center w-full">
          <Card className="w-full shadow-3 hover:shadow-5 border-round-lg">
            <div className="flex flex-column align-items-center justify-content-center">
              <img
                src={images.logo} // Replace with your actual logo path
                alt="Logo"
                className="logo m-auto"
                style={{ width: "150px", marginBottom: "20px" }} // Adjust size as needed
              />
              <h3 className="text-center">Sign Up</h3>
            </div>

            {!isOtpSent ? (
              <form onSubmit={handleSubmit} encType="multipart/form-data">
                <div className="flex flex-column align-items-start justify-content-start gap-3 p-4 w-full">
                  <div className="w-full my-2">
                    <InputText
                      id="name"
                      name="name"
                      onChange={handleInputChange}
                      placeholder="Enter your name"
                      className="p-inputtext-lg w-full border-round-lg"
                      // required
                      invalid={emptyErrors.name}
                    />
                  </div>

                  <div className="w-full my-2">
                    <InputText
                      id="email"
                      name="email"
                      type="email"
                      onChange={handleInputChange}
                      placeholder="Enter email address"
                      className="p-inputtext-lg w-full border-round-lg"
                      //required
                      invalid={emptyErrors.email}
                    />
                  </div>

                  <div className="w-full my-2">
                    <InputText
                      id="companyName"
                      name="companyName"
                      onChange={handleInputChange}
                      placeholder="Enter company name"
                      className="p-inputtext-lg w-full border-round-lg"
                      //required
                      invalid={emptyErrors.companyName}
                    />
                  </div>

                  <div className="w-full my-2">
                    <InputText
                      id="companyDesignation"
                      name="companyDesignation"
                      onChange={handleInputChange}
                      placeholder="Enter company designation"
                      className="p-inputtext-lg w-full border-round-lg"
                      //required
                      invalid={emptyErrors.companyDesignation}
                    />
                  </div>

                  <div className="w-full my-2">
                    <InputText
                      id="companyPhone"
                      name="companyPhone"
                      onChange={handleInputChange}
                      placeholder="Enter company phone"
                      className="p-inputtext-lg w-full border-round-lg"
                      //required
                      invalid={emptyErrors.companyPhone}
                    />
                  </div>

                  <div
                    className="w-full "
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <input
                      type="file"
                      id="companyLogo"
                      name="companyLogo"
                      accept="image/*"
                      onChange={handleLogoChange}
                      // required
                      className="p-inputtext-lg w-full border-round-lg"
                      // invalid = {emptyErrors.companyLogo}
                    />
                    {/* <FileUpload
                      mode="basic"
                      name="companyLogo"
                      id="companyLogo"
                      accept="image/*"
                      maxFileSize={1000000}
                      onChange={handleLogoChange}
                      className="fileBtn"
                    /> */}
                    <small>company logo</small>

                    {/* {companyLogo && <small className="color-red">company logo can't be empty</small>} */}
                  </div>
                  <div className="w-full">
                    <Button
                      label="Register"
                      type="submit"
                      className="border-round-lg w-full"
                      // style={{ marginRight: "10px",
                      //   width:"13rem"
                      //  }} // Add margin to the right of the button

                      loading={loading}
                    />
                    <p
                      onClick={handleHome}
                      style={{
                        cursor: "pointer",
                        textDecoration: "underline",
                        color: "blue",
                        marginLeft: "10px", // Optional spacing between the button and text
                      }}
                    >
                      Back to Login
                    </p>
                  </div>
                </div>
              </form>
            ) : (
              <form onSubmit={handleOtpSubmit}>
                <div className="w-full my-2">
                  <InputText
                    id="otp"
                    name="otp"
                    onChange={(e) => setOtp(e.target.value)}
                    placeholder="Enter the OTP sent to your email"
                    className="p-inputtext-lg w-full border-round-lg"
                    //required
                    // invalid={!otp}
                  />
                </div>

                <Button
                  label="Verify OTP"
                  type="submit"
                  className="border-round-lg"
                  loading={loading}
                />
              </form>
            )}

            {/* Show Progress Spinner if loading */}
          </Card>
        </div>
      </div>
      {/* Dialog for admin approval message */}
      <Dialog
        header="Approval Status"
        visible={displayDialog}
        style={{ width: "50vw" }}
        footer={dialogFooter}
        onHide={() => {
          setDisplayDialog(false); // Close the dialog
          navigate("/"); // Navigate to the home page
        }}
      >
        <p>Your registration is successful! Please wait for admin approval.</p>
      </Dialog>
    </div>
  );
}

export default CompanyRegistration;
