import { createAxiosInstance as myAxios } from "../../services/Helper";

const axiosInstance = await myAxios();

export const historyService = async (viewRole, ruleId) => {
  const response =
    viewRole === "DEV"
      ? await axiosInstance.get(`mtd/sch/archive/history/${ruleId}`)
      : await axiosInstance.get(
          `${viewRole.toLowerCase()}/mtd/sch/archive/history/${ruleId}`
        );
  return response;
};

export const fetchJobLogs = async (jobId, viewRole) => {
  const axiosInstance = await myAxios();
  let response;

  if (viewRole === "DEV") {
    response = await axiosInstance.get(`mtd/job1/${jobId}`);
  } else {
    response = await axiosInstance.get(
      `${viewRole.toLowerCase()}/mtd/job1/${jobId}`
    );
  }

  return response.data;
};

export const fetchFolderService = async (rowData, ruleId) => {
  const response = await axiosInstance.get(
    `folder?id=${ruleId}&folder=${encodeURIComponent(rowData.archivePath)}`
  );
  return response;
};

export const fetchFileInfoService = async (archivePath,ruleId) => {
  try {
    const response = await axiosInstance.get(
      `files/count?id=${ruleId}&archivePath=${archivePath}`
    );
    return response.data;
  } catch (error) {
    console.error("Error in fetchFileInfoService:", error);
    throw error; // Rethrow the error to handle it in the caller function
  }
};