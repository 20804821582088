// import React, { useEffect, useState, useRef } from "react";
// import { useNavigate } from "react-router-dom";
// import "./RuleList.css";
// import { FilterMatchMode } from "primereact/api";
// import { DataTable } from "primereact/datatable";
// import { Column } from "primereact/column";
// import { InputText } from "primereact/inputtext";
// import { IconField } from "primereact/iconfield";
// import { InputIcon } from "primereact/inputicon";
// import { Button } from "primereact/button";
// import { Tag } from "primereact/tag";
// import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
// import { format } from "date-fns";
// import { Tooltip } from "primereact/tooltip";
// import { Toast } from "primereact/toast";
// import { useUserContext } from "../contextAPIs/UserContext";
// import { Dropdown } from "primereact/dropdown";
// import { SiJira } from "react-icons/si";
// import {
//   deleteRuleService,
//   fetchRulesService,
//   pauseRuleService,
//   resumeRuleService,
//   startRuleService,
//   stopRuleService,
// } from "../../API Services/LandingPageService/RulelistService";

// import {
//   editDBService,
//   editPGPService,
//   editSFTPService,
// } from "../../API Services/fetchSecretsService";
// import RuleListSkeleton from "./skeleton/RuleListSkeleton";

// let checkStatus = (active) => {
//   switch (active) {
//     case 0:
//       return "Not Started ";
//     case 1:
//       return "Started ";
//     case 2:
//       return "Pause ";
//     case 3:
//       return "Resume ";
//     case 4:
//       return "Deleted ";
//     default:
//       return "Stopped ";
//   }
// };

// const confirm1 = () => {
//   confirmDialog({
//     group: "headless",
//     message: "Are you sure you want to Delete Now ?",
//     header: "Confirmation",
//     icon: "pi pi-exclamation-triangle",
//     defaultFocus: "accept",
//   });
// };

// //rule list
// const RuleList = ({ rules, setRules }) => {
//   const navigate = useNavigate();
//   const company = localStorage.getItem("company");

//   // State to manage actions for each rule
//   const [ruleActions, setRuleActions] = useState({});
//   const [ruleStatus, setRuleStatus] = useState({});
//   const [currentRuleId, setCurrentRuleId] = useState(null);
//   const [currentPage, setCurrentPage] = useState(1);
//   const itemsPerPage = 10;
//   const toast = useRef(null);

//   // drop down fields for the user dev/qa/prod
//   // const { getRole } = useUserContext();
//   // const role = getRole();

//   // const viewRoles = [
//   //   {
//   //     name: "DEV",
//   //     code: "DEV",
//   //     color: "green",
//   //     fetchApi: "rules/rulelist/dev",
//   //   },
//   //   { name: "QA", code: "QA", color: "blue", fetchApi: "rules/rulelist/qa" },
//   //   {
//   //     name: "PROD",
//   //     code: "PROD",
//   //     color: "red",
//   //     fetchApi: "rules/rulelist/prod",
//   //   },
//   // ];
//   // const [selectedViewRole, setSelectedViewRole] = useState(viewRoles[1]);

//   const { getRole } = useUserContext();
//   const role = getRole();

//   // Define your roles and corresponding views
//   const viewRoles = [
//     {
//       name: "DEV",
//       code: "DEV",
//       color: "green",
//       fetchApi: `rules/rulelist/dev?company=${company}`,
//     },
//     {
//       name: "QA",
//       code: "QA",
//       color: "blue",
//       fetchApi: `rules/rulelist/qa?company=${company}`,
//     },
//     {
//       name: "PROD",
//       code: "PROD",
//       color: "red",
//       fetchApi: `rules/rulelist/prod?company=${company}`,
//     },
//   ];

//   // Set the default selected view role based on the user role
//   const getDefaultRoleIndex = (role) => {
//     if (role === "DEV") return 0;
//     if (role === "QA") return 1;
//     if (role === "PROD" || role === "ADMIN") return 2;
//     return 1; // default to QA if role is unknown
//   };

//   const [selectedViewRole, setSelectedViewRole] = useState(
//     viewRoles[getDefaultRoleIndex(role)]
//   );

//   // const fetchRules = async () => {
//   //   const axiosInstance = await myAxios();
//   //   await axiosInstance
//   //     .get(selectedViewRole.fetchApi)
//   //     .then((res) => res.data)
//   //     .then((finalRes) => {
//   //       setRules(finalRes);
//   //       setLoading(false);
//   //     })
//   //     .catch((e) => {
//   //       if (e.response && e.response.status === 401) {
//   //         navigate("/login");
//   //       } else {
//   //         console.error(e);
//   //       }
//   //     });
//   // };

//   const fetchRules = async () => {
//     try {
//       const data = await fetchRulesService(selectedViewRole.fetchApi);
//       setRules(data);
//       setLoading(false);
//     } catch (e) {
//       if (e.response && e.response.status === 401) {
//         navigate("/login");
//       } else {
//         console.error(e);
//       }
//     }
//   };

//   // Call fetchRules whenever selectedViewRole changes
//   useEffect(() => {
//     setLoading(true);
//     fetchRules();
//   }, [selectedViewRole]);

//   // useEffect(() => {
//   //   fetchRules();
//   // }, []);

//   // const handleStart = async (ruleId) => {
//   //   setRuleActions({ ...ruleActions, [ruleId]: "started" });
//   //   setRuleStatus({ ...ruleStatus, [ruleId]: "Started" });
//   //   const ruleCode = ruleId.substring(0, 3);
//   //   const axiosInstance = await myAxios();
//   //   await axiosInstance
//   //     .post(`${ruleCode}/sch/start/${ruleId}`)
//   //     .then((res) => res.data)
//   //     .then((fres) => console.log(fres))
//   //     .catch((e) => console.error(e));

//   //   fetchRules();
//   // };

//   // const handleStop = async (ruleId) => {
//   //   setRuleActions({ ...ruleActions, [ruleId]: "stopped" });
//   //   setRuleStatus({ ...ruleStatus, [ruleId]: "Stopped" });
//   //   const ruleCode = ruleId.substring(0, 3);
//   //   const axiosInstance = await myAxios();
//   //   await axiosInstance
//   //     .post(`${ruleCode}/sch/stop/${ruleId}`)
//   //     .then((res) => res.data)
//   //     .then((fres) => console.log(fres))
//   //     .catch((e) => console.error(e));

//   //   fetchRules();
//   // };

//   // const handlePause = async (ruleId) => {
//   //   setRuleActions({ ...ruleActions, [ruleId]: "paused" });
//   //   setRuleStatus({ ...ruleStatus, [ruleId]: "Paused" });
//   //   const ruleCode = ruleId.substring(0, 3);
//   //   const axiosInstance = await myAxios();
//   //   await axiosInstance
//   //     .post(`${ruleCode}/sch/pause/${ruleId}`)
//   //     .then((res) => res.data)
//   //     .then((fres) => console.log(fres))
//   //     .catch((e) => console.error(e));

//   //   fetchRules();
//   // };

//   // const handleResume = async (ruleId) => {
//   //   setRuleActions({ ...ruleActions, [ruleId]: "resumed" });
//   //   setRuleStatus({ ...ruleStatus, [ruleId]: "Resumed" });
//   //   const ruleCode = ruleId.substring(0, 3);
//   //   const axiosInstance = await myAxios();
//   //   await axiosInstance
//   //     .post(`${ruleCode}/sch/resume/${ruleId}`)
//   //     .then((res) => res.data)
//   //     .then((fres) => console.log(fres))
//   //     .catch((e) => console.error(e));

//   //   fetchRules();
//   // };

//   // const handleDelete = async (ruleId) => {
//   //   setRuleActions({ ...ruleActions, [ruleId]: "delete" });
//   //   setRuleStatus({ ...ruleStatus, [ruleId]: "Deleted" });
//   //   const ruleCode = ruleId.substring(0, 3);
//   //   const axiosInstance = await myAxios();
//   //   await axiosInstance
//   //     .delete(`${ruleCode}/sch/delete/${ruleId}`)
//   //     .then((res) => res.data)
//   //     .then((fres) => console.log(fres))
//   //     .catch((e) => console.error(e));

//   //   fetchRules();
//   // };

//   // const handleStart = async (ruleId) => {
//   //   setRuleActions({ ...ruleActions, [ruleId]: "started" });
//   //   setRuleStatus({ ...ruleStatus, [ruleId]: "Started" });
//   //   const ruleCode = ruleId.substring(0, 3);
//   //   const axiosInstance = await myAxios();
//   //   selectedViewRole.name === "DEV"
//   //     ? await axiosInstance
//   //         .post(`${ruleCode}/sch/start/${ruleId}`)
//   //         .then((res) => res.data)
//   //         .then((fres) => console.log(fres))
//   //         .catch((e) => console.error(e))
//   //     : await axiosInstance
//   //         .post(
//   //           `${selectedViewRole.name.toLocaleLowerCase()}/${ruleCode}/sch/start/${ruleId}`
//   //         )
//   //         .then((res) => res.data)
//   //         .then((fres) => console.log(fres))
//   //         .catch((e) => console.error(e));

//   //   fetchRules();
//   // };

//   const handleStart = async (ruleId) => {
//     setRuleActions({ ...ruleActions, [ruleId]: "started" });
//     setRuleStatus({ ...ruleStatus, [ruleId]: "Started" });

//     try {
//       const fres = await startRuleService(ruleId, selectedViewRole);
//       console.log(fres);
//     } catch (error) {
//       // .catch((e) => {console.error(e)
//       toast?.current?.show({
//         severity: "error",
//         summary: "Error",
//         detail: error.response?.data,
//         life: 4000,
//       });
//       console.error("Error starting rule:", error);
//     }

//     fetchRules();
//   };

//   // const handleStop = async (ruleId) => {
//   //   setRuleActions({ ...ruleActions, [ruleId]: "stopped" });
//   //   setRuleStatus({ ...ruleStatus, [ruleId]: "Stopped" });
//   //   const ruleCode = ruleId.substring(0, 3);
//   //   const axiosInstance = await myAxios();
//   //   selectedViewRole.name === "DEV"
//   //     ? await axiosInstance
//   //         .post(`${ruleCode}/sch/stop/${ruleId}`)
//   //         .then((res) => res.data)
//   //         .then((fres) => console.log(fres))
//   //         .catch((e) => console.error(e))
//   //     : await axiosInstance
//   //         .post(
//   //           `${selectedViewRole.name.toLowerCase()}/${ruleCode}/sch/stop/${ruleId}`
//   //         )
//   //         .then((res) => res.data)
//   //         .then((fres) => console.log(fres))
//   //         .catch((e) => console.error(e));

//   //   fetchRules();
//   // };

//   const handleStop = async (ruleId) => {
//     setRuleActions({ ...ruleActions, [ruleId]: "stopped" });
//     setRuleStatus({ ...ruleStatus, [ruleId]: "Stopped" });

//     try {
//       const fres = await stopRuleService(ruleId, selectedViewRole);
//       console.log(fres);
//     } catch (error) {
//       console.error("Error stopping rule:", error);
//     }

//     fetchRules();
//   };

//   // const handlePause = async (ruleId) => {
//   //   setRuleActions({ ...ruleActions, [ruleId]: "paused" });
//   //   setRuleStatus({ ...ruleStatus, [ruleId]: "Paused" });
//   //   const ruleCode = ruleId.substring(0, 3);
//   //   const axiosInstance = await myAxios();
//   //   selectedViewRole.name === "DEV"
//   //     ? await axiosInstance
//   //         .post(`${ruleCode}/sch/pause/${ruleId}`)
//   //         .then((res) => res.data)
//   //         .then((fres) => console.log(fres))
//   //         .catch((e) => console.error(e))
//   //     : await axiosInstance
//   //         .post(
//   //           `${selectedViewRole.name.toLowerCase()}/${ruleCode}/sch/pause/${ruleId}`
//   //         )
//   //         .then((res) => res.data)
//   //         .then((fres) => console.log(fres))
//   //         .catch((e) => console.error(e));

//   //   fetchRules();
//   // };

//   const handlePause = async (ruleId) => {
//     setRuleActions({ ...ruleActions, [ruleId]: "paused" });
//     setRuleStatus({ ...ruleStatus, [ruleId]: "Paused" });

//     try {
//       const fres = await pauseRuleService(ruleId, selectedViewRole);
//       console.log(fres);
//     } catch (error) {
//       console.error("Error pausing rule:", error);
//     }

//     fetchRules();
//   };

//   // const handleResume = async (ruleId) => {
//   //   setRuleActions({ ...ruleActions, [ruleId]: "resumed" });
//   //   setRuleStatus({ ...ruleStatus, [ruleId]: "Resumed" });
//   //   const ruleCode = ruleId.substring(0, 3);
//   //   const axiosInstance = await myAxios();
//   //   selectedViewRole.name === "DEV"
//   //     ? await axiosInstance
//   //         .post(`${ruleCode}/sch/resume/${ruleId}`)
//   //         .then((res) => res.data)
//   //         .then((fres) => console.log(fres))
//   //         .catch((e) => console.error(e))
//   //     : await axiosInstance
//   //         .post(
//   //           `${selectedViewRole.name.toLowerCase()}/${ruleCode}/sch/resume/${ruleId}`
//   //         )
//   //         .then((res) => res.data)
//   //         .then((fres) => console.log(fres))
//   //         .catch((e) => console.error(e));

//   //   fetchRules();
//   // };

//   const handleResume = async (ruleId) => {
//     setRuleActions({ ...ruleActions, [ruleId]: "resumed" });
//     setRuleStatus({ ...ruleStatus, [ruleId]: "Resumed" });

//     try {
//       const fres = await resumeRuleService(ruleId, selectedViewRole);
//       console.log(fres);
//     } catch (error) {
//       console.error("Error resuming rule:", error);
//     }

//     fetchRules();
//   };

//   // const handleDelete = async (ruleId) => {
//   //   setRuleActions({ ...ruleActions, [ruleId]: "delete" });
//   //   setRuleStatus({ ...ruleStatus, [ruleId]: "Deleted" });
//   //   const ruleCode = ruleId.substring(0, 3);
//   //   const axiosInstance = await myAxios();
//   //   if (selectedViewRole.name === "DEV") {
//   //     await axiosInstance
//   //       .delete(`${ruleCode}/sch/delete/${ruleId}`)
//   //       .then((res) => res.data)
//   //       .then((fres) => console.log(fres))
//   //       .catch((e) => console.error(e));
//   //   } else if (selectedViewRole.name === "QA") {
//   //     await axiosInstance
//   //       .delete(`qa/${ruleCode}/sch/delete/${ruleId}`)
//   //       .then((res) => res.data)
//   //       .then((fres) => console.log(fres))
//   //       .catch((e) => console.error(e));
//   //   } else if (selectedViewRole.name === "PROD") {
//   //     await axiosInstance
//   //       .delete(`prod/${ruleCode}/sch/delete/${ruleId}`)
//   //       .then((res) => res.data)
//   //       .then((fres) => console.log(fres))
//   //       .catch((e) => console.error(e));
//   //   }

//   //   fetchRules();
//   // };

//   const handleDelete = async (ruleId) => {
//     setRuleActions({ ...ruleActions, [ruleId]: "delete" });
//     setRuleStatus({ ...ruleStatus, [ruleId]: "Deleted" });

//     try {
//       const fres = await deleteRuleService(ruleId, selectedViewRole);
//       console.log(fres);
//     } catch (error) {
//       console.error("Error deleting rule:", error);
//     }

//     fetchRules();
//   };

//   const renderActionButtons = (rowData) => {
//     const { ruleId, active } = rowData;

//     switch (active) {
//       case 0: // Not Started
//         return (
//           <Button
//             tooltip="Start"
//             tooltipOptions={{ position: "bottom" }}
//             className="i-20px"
//             onClick={() => handleStart(ruleId)}
//             style={{ fontSize: "1rem" }}
//             icon="pi pi-play-circle"
//             rounded
//             text
//             severity="danger"
//             aria-label="Start"
//           />
//         );
//       case 1:
//       case 3: // Started
//         return (
//           <>
//             <Button
//               tooltip="Stop"
//               tooltipOptions={{ position: "bottom" }}
//               className="i-20px"
//               icon="pi pi-stop-circle"
//               onClick={() => handleStop(ruleId)}
//               rounded
//               text
//               severity="danger"
//               aria-label="Stop"
//             />
//             <Button
//               tooltip="Pause"
//               tooltipOptions={{ position: "bottom" }}
//               className="i-20px"
//               icon="pi pi-pause-circle"
//               onClick={() => handlePause(ruleId)}
//               rounded
//               text
//               severity="danger"
//               aria-label="Pause"
//             />
//           </>
//         );
//       case 2: // Paused
//         return (
//           <>
//             <Button
//               tooltip="Stop"
//               tooltipOptions={{ position: "bottom" }}
//               className="i-20px"
//               icon="pi pi-stop-circle"
//               onClick={() => handleStop(ruleId)}
//               rounded
//               text
//               severity="danger"
//               aria-label="Stop"
//             />
//             <Button
//               tooltip="Resume"
//               tooltipOptions={{ position: "bottom" }}
//               className="i-20px"
//               icon="pi pi-circle-fill"
//               onClick={() => handleResume(ruleId)}
//               rounded
//               text
//               severity="success"
//               aria-label="Resume"
//             />
//           </>
//         );
//       case 4: // Deleted
//         return null; // or any specific UI for deleted rules
//       default:
//         return (
//           <Button
//             tooltip="Start"
//             tooltipOptions={{ position: "bottom" }}
//             className="i-20px"
//             onClick={() => handleStart(ruleId)}
//             icon="pi pi-play-circle"
//             rounded
//             text
//             severity="danger"
//             aria-label="Start"
//           />
//         );
//     }
//   };

//   const indexOfLastItem = currentPage * itemsPerPage;
//   const indexOfFirstItem = indexOfLastItem - itemsPerPage;
//   const currentItems = rules
//     .filter((rule) => rule.active !== 4)
//     .map((rule, idx) => ({
//       ...rule,
//       ruleID: (currentPage - 1) * itemsPerPage + idx + 1,
//       createdTime: format(rule.createdTime, "dd/MM/yyyy HH:mm:ss"),
//       modifiedTime: format(rule.modifiedTime, "dd/MM/yyyy HH:mm:ss"),
//       status: ruleStatus[rule.status]
//         ? ruleStatus[rule.status]
//         : checkStatus(rule.active), // status new_fix
//     }));

//   const [filters, setFilters] = useState({
//     global: { value: null, matchMode: FilterMatchMode.CONTAINS },
//     fileName: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
//     createdTime: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
//     modifiedTime: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
//     createdby: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
//     updatedby: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
//     status: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
//   });

//   const [loading, setLoading] = useState(true);
//   const [globalFilterValue, setGlobalFilterValue] = useState("");

//   const getSeverity = (status) => {
//     switch (status) {
//       case "Stopped":
//         return "danger";

//       case "Started":
//         return "success";

//       case "Resume":
//         return "info";

//       case "Pause":
//         return "warning";

//       case "Deleted":
//         return null;
//     }
//   };

//   const onGlobalFilterChange = (e) => {
//     const value = e.target.value;
//     let _filters = { ...filters };

//     _filters["global"].value = value;

//     setFilters(_filters);
//     setGlobalFilterValue(value);
//   };

//   const gotoCreatePost = () => {
//     navigate("/rules/create");
//   };

//   // const renderHeader = () => {
//   //   return (
//   //     <>
//   //     <div className="flex justify-content-end flex-row align-items-center gap-3">
//   //       <Button
//   //         onClick={() => gotoCreatePost()}
//   //         severity="primary"
//   //         size="small"
//   //         className="border-round-lg"
//   //         tooltip="Create Job"
//   //         tooltipOptions={{ position: "bottom" }}
//   //         label="Create Job"
//   //         icon="pi pi-plus"
//   //       />
//   //       <IconField iconPosition="left">
//   //         <InputIcon className="pi pi-search" />
//   //         <InputText
//   //           value={globalFilterValue}
//   //           onChange={onGlobalFilterChange}
//   //           placeholder="Keyword Search"
//   //           className="p-inputtext-sm"
//   //         />
//   //       </IconField>
//   //     </div>
//   // </>
//   //   );
//   // };

//   const renderHeader = () => {
//     return (
//       <>
//         {/* currenet dept status */}

//         <div className="">
//           <div className="current-view">
//             {
//               <div className="circleInd">
//                 {/* <FaCircle color={selectedViewRole ? selectedViewRole.color : ''}/>   */}
//                 <h4
//                   style={{
//                     margin: "auto",
//                   }}
//                 >
//                   {" "}
//                   {selectedViewRole ? selectedViewRole.name : ""}
//                 </h4>
//               </div>
//             }

//             <div className="viewas-createjob-search flex justify-content-end flex-row align-items-center gap-3">
//               <div>
//                 <Dropdown
//                   value={selectedViewRole}
//                   onChange={(e) => {
//                     setSelectedViewRole(e.value);
//                   }}
//                   options={viewRoles}
//                   optionLabel="name"
//                   placeholder="Select View As"
//                   className="p-inputtext-sm viewDd"
//                 />
//               </div>
//               {role !== "PROD" && (
//                 <Button
//                   onClick={() => gotoCreatePost()}
//                   severity="primary"
//                   size="small"
//                   className="border-round-lg"
//                   tooltip="Create Job"
//                   tooltipOptions={{ position: "bottom" }}
//                   label="Create Job"
//                   icon="pi pi-plus"
//                 />
//               )}

//               <IconField iconPosition="left">
//                 <InputIcon className="pi pi-search" />
//                 <InputText
//                   value={globalFilterValue}
//                   onChange={onGlobalFilterChange}
//                   placeholder="Keyword Search"
//                   className="p-inputtext-sm"
//                 />
//               </IconField>
//             </div>
//           </div>
//         </div>
//       </>
//     );
//   };

//   const statusBodyTemplate = (rowData) => {
//     return (
//       <Tag
//         value={rowData.status}
//         severity={getSeverity(rowData.status?.trim())}
//       />
//     );
//   };

//   const actionBodyTemplate = (rowData) => {
//     return (
//       <>
//         <div className="flex flex-row align-items-center justify-content-end gap-2">
//           {renderActionButtons(rowData)}
//           <Button
//             tooltip="Edit"
//             tooltipOptions={{ position: "bottom" }}
//             icon="pi pi-pencil"
//             rounded
//             text
//             severity="secondary"
//             aria-label="Edit"
//             onClick={() => handleEditClick(rowData.ruleId)}
//           />
//           <Button
//             tooltip="Delete"
//             tooltipOptions={{ position: "bottom" }}
//             icon="pi pi-trash"
//             rounded
//             text
//             severity="danger"
//             aria-label="Delete"
//             onClick={() => {
//               setCurrentRuleId(rowData.ruleId);
//               confirm1();
//             }}
//           />{" "}
//           {/**new_fix */}
//         </div>
//       </>
//     );
//   };

//   const header = renderHeader();

//   //this is for edit page

//   const handleEditClick = async (ruleId) => {
//     const ruleCode = ruleId.substring(0, 3);

//     // console.log("RuleCode:", ruleCode);

//     // If the ruleCode is "mtm", perform the logic similar to handleMountToMountClick
//     if (ruleCode === "mtm") {
//       setLoading(true); // Set loading state to true

//       // const axiosInstance = await myAxios();

//       // const response = await axiosInstance
//       //   .get(`/secrets/get-by-company/get?company=${company}&type=sftp`)
//       //   .then((res) => res.data)
//       //   .catch((e) => e.response);

//       const response = await editSFTPService(company);

//       setLoading(false);

//       if (response.status !== undefined && response.status === 401) {
//         toast.error("Token Expired, Please Login Again", {
//           theme: "colored",
//         });
//         localStorage.clear();
//         navigate("/login");
//         return;
//       }

//       // After successful response, navigate with the response and ruleId
//       navigate(`/${ruleCode}/rules/edit`, {
//         state: { ruleId, response, viewRole: selectedViewRole.name },
//       });
//     }
//     // If the ruleCode is "mtd", perform the logic similar to handleMountToDatabaseClick
//     else if (ruleCode === "mtd") {
//       setLoading(true); // Set loading state to true

//       // const axiosInstance = await myAxios();

//       // // Fetch SFTP data
//       // const sftpResponse = await axiosInstance
//       //   .get(`/secrets/get-by-company/get?company=${company}&type=sftp`)
//       //   .then((res) => res.data)
//       //   .catch((e) => e.response);
//       const sftpResponse = await editSFTPService(company);

//       if (sftpResponse.status !== undefined && sftpResponse.status === 401) {
//         setLoading(false);
//         toast.error("Token Expired, Please Login Again", {
//           theme: "colored",
//         });
//         localStorage.clear();
//         navigate("/login");
//         return;
//       }

//       // Fetch Database data
//       // const dbResponse = await axiosInstance
//       //   .get(`/secrets/get-by-company/get?company=${company}&type=db`)
//       //   .then((res) => res.data)
//       //   .catch((e) => e.response);

//       const dbResponse = await editDBService(company);

//       if (dbResponse.status !== undefined && dbResponse.status === 401) {
//         setLoading(false);
//         toast.error("Token Expired, Please Login Again", {
//           theme: "colored",
//         });
//         localStorage.clear();
//         navigate("/login");
//         return;
//       }

//       const responsePGP = await editPGPService(company);
//       if (responsePGP.status !== undefined && responsePGP.status === 401) {
//         setLoading(false);
//         toast.error("Token Expired, Please Login Again", {
//           theme: "colored",
//         });
//         localStorage.clear();
//         navigate("/login");
//         return;
//       }

//       setLoading(false);
//       // Navigate to the page with both SFTP and DB responses
//       navigate(`/${ruleCode}/rules/edit`, {
//         state: {
//           ruleId,
//           dbResponse,
//           sftpResponse,
//           responsePGP,

//           viewRole: selectedViewRole.name,
//         },
//       });
//     } else if (ruleCode === "dtm") {
//       setLoading(true); // Set loading state to true

//       // const axiosInstance = await myAxios();

//       // const sftpResponse = await axiosInstance
//       //   .get(`/secrets/get-by-company/get?company=${company}&type=sftp`)
//       //   .then((res) => res.data)
//       //   .catch((e) => e.response);

//       const sftpResponse = await editSFTPService(company);

//       if (sftpResponse.status !== undefined && sftpResponse.status === 401) {
//         setLoading(false);
//         toast.error("Token Expired, Please Login Again", {
//           theme: "colored",
//         });
//         localStorage.clear();
//         navigate("/login");
//         return;
//       }
//       // Fetch Database data

//       // const dbResponse = await axiosInstance
//       //   .get(`/secrets/get-by-company/get?company=${company}&type=db`)
//       //   .then((res) => res.data)
//       //   .catch((e) => e.response);
//       const dbResponse = await editDBService(company);
//       if (dbResponse.status !== undefined && dbResponse.status === 401) {
//         setLoading(false);
//         toast.error("Token Expired, Please Login Again", {
//           theme: "colored",
//         });
//         localStorage.clear();
//         navigate("/login");
//         return;
//       }

//       const responsePGP = await editPGPService(company);
//       if (responsePGP.status !== undefined && responsePGP.status === 401) {
//         setLoading(false);
//         toast.error("Token Expired, Please Login Again", {
//           theme: "colored",
//         });
//         localStorage.clear();
//         navigate("/login");
//         return;
//       }

//       setLoading(false);
//       // Navigate to the appropriate page with both responses
//       navigate(`/${ruleCode}/rules/edit`, {
//         state: {
//           ruleId,
//           dbResponse,
//           sftpResponse,
//           responsePGP,

//           viewRole: selectedViewRole.name,
//         },
//       });
//     } else if (ruleCode === "dtd") {
//       setLoading(true); // Set loading state to true
//       // console.log(ruleCode + "hhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhh");
//       // try {
//       // Fetch Database data
//       // const axiosInstance = await myAxios();

//       // const response = await axiosInstance
//       //   .get(`/secrets/get-by-company/get?company=${company}&type=db`)
//       //   .then((res) => res.data)
//       //   .catch((e) => e.response);

//       const response = await editDBService(company);

//       setLoading(false);
//       if (response.status !== undefined && response.status === 401) {
//         toast.error("Token Expired, Please Login Again", {
//           theme: "colored",
//         });
//         localStorage.clear();
//         navigate("/login");
//         return;
//       }
//       // Navigate to the appropriate page with Database response
//       navigate(`/${ruleCode}/rules/edit`, {
//         state: { ruleId, response, viewRole: selectedViewRole.name },
//       });
//     }
//   };

//   // const handleHistory = (rowData) => {
//   //   const ruleId = rowData.ruleId;
//   //   navigate("/home/history", { state: { ruleId } });
//   // };

//   const handleHistory = (rowData) => {
//     const ruleId = rowData.ruleId;
//     navigate("/home/history", {
//       state: { ruleId, viewRole: selectedViewRole.name },
//     });
//   };

//   // this method will get the name from the jira url
//   const getLastSegment = (url) => {
//     if (!url) return ""; // Handle null or undefined URLs
//     const segments = url.split("/"); // Split the URL by "/"
//     return segments.pop(); // Get the last segment
//   };

//   return loading ? (
//     <RuleListSkeleton />
//   ) : (
//     <>
//       <Toast ref={toast} />

//       <DataTable
//         value={currentItems}
//         paginator
//         rowsPerPageOptions={[10, 50, 100, 500]}
//         rows={itemsPerPage}
//         dataKey="id"
//         filters={filters}
//         filterDisplay="row"
//         globalFilterFields={[
//           "ruleID",
//           "fileName",
//           "createdTime",
//           "modifiedTime",
//           "createdby",
//           "updatedby",
//           "jiraUrl",
//           "modifiedTime",
//         ]}
//         header={header}
//         emptyMessage="No customers found."
//       >
//         <Column field="ruleID" header="Sl No" style={{ minWidth: "6rem" }} />
//         <Column
//           header="Job Name"
//           field="fileName"
//           filterField="fileName"
//           filter
//           filterPlaceholder="Search by file"
//           style={{ minWidth: "12rem", cursor: "pointer" }}
//           body={(rowData) => (
//             <div
//               style={{
//                 display: "inline-block",
//                 padding: "0.5rem",
//                 borderRadius: "4px",
//                 transition: "background-color 0.3s",
//                 cursor: "pointer",
//               }}
//               onClick={() => handleHistory(rowData)}
//               className="underline-text highlight-row"
//               data-pr-tooltip="View History" // Tooltip text
//             >
//               {rowData.fileName}
//               <hr style={{ marginTop: "2px" }}></hr>
//               <Tooltip target=".highlight-row" />
//             </div>
//           )}
//         />
//         <Column
//           header="Created Time"
//           field="createdTime"
//           filterField="createdTime"
//           filter
//           style={{ minWidth: "14rem" }}
//         />
//         <Column
//           field="modifiedTime"
//           header="Modified Time"
//           filterMenuStyle={{ width: "14rem" }}
//           filter
//           style={{ minWidth: "12rem" }}
//         />
//         <Column
//           field="createdby"
//           header="Created By"
//           filter
//           style={{ minWidth: "6rem" }}
//         />
//         <Column
//           field="updatedby"
//           header="Updated By"
//           filter
//           style={{ minWidth: "6rem" }}
//         />
//         <Column
//           field="jiraUrl"
//           header="JIRA URL"
//           body={(rowData) => (
//             <a href={rowData.jiraUrl} target="_blank" rel="noopener noreferrer">
//               <SiJira color="blue" />[{getLastSegment(rowData.jiraUrl)}]
//             </a>
//           )}
//           filter
//           style={{ minWidth: "6rem" }}
//         />
//         <Column
//           field="status"
//           header="Status"
//           filter
//           style={{ minWidth: "6rem" }}
//           body={statusBodyTemplate}
//         />
//         {/* <Column
//           header="Action"
//           style={{ minWidth: "6rem" }}
//           body={actionBodyTemplate}
//         /> */}
//         {((role === "DEV" && selectedViewRole.name === "DEV") ||
//           (role === "QA" && selectedViewRole.name === "QA") ||
//           role === "ADMIN" ||
//           (role === "PROD" && selectedViewRole.name === "PROD")) && (
//           <Column
//             header="Action"
//             style={{ minWidth: "6rem" }}
//             body={actionBodyTemplate}
//           />
//         )}
//       </DataTable>

//       {/* confirm dialog */}
//       {/* here starts the confirm dialog */}
//       <ConfirmDialog
//         group="headless"
//         content={({ headerRef, contentRef, footerRef, hide, message }) => (
//           <div className="flex flex-column align-items-center p-5 surface-overlay border-round">
//             <div className="border-circle bg-primary inline-flex justify-content-center align-items-center h-6rem w-6rem -mt-8">
//               <i className="pi pi-exclamation-triangle text-5xl"></i>
//             </div>
//             <span
//               className="font-bold text-2xl block mb-2 mt-4"
//               ref={headerRef}
//             >
//               {message.header}
//             </span>
//             <p className="mb-0" ref={contentRef}>
//               {message.message}
//             </p>
//             <div className="flex align-items-center gap-2 mt-4" ref={footerRef}>
//               <Button
//                 label="Yes"
//                 severity="danger"
//                 onClick={(event) => {
//                   hide(event);
//                   handleDelete(currentRuleId);
//                 }}
//                 className="w-8rem"
//               ></Button>
//               <Button
//                 label="No"
//                 outlined
//                 onClick={(event) => {
//                   hide(event);
//                   setCurrentRuleId(null);
//                 }}
//                 className="w-8rem"
//               ></Button>
//             </div>
//           </div>
//         )}
//       />
//     </>
//   );
// };
// export default RuleList;

import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import "./RuleList.css";
import { FilterMatchMode } from "primereact/api";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { IconField } from "primereact/iconfield";
import { InputIcon } from "primereact/inputicon";
import { Button } from "primereact/button";
import { Tag } from "primereact/tag";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { format } from "date-fns";
import { Tooltip } from "primereact/tooltip";
import { Toast } from "primereact/toast";
import { useUserContext } from "../contextAPIs/UserContext";
import { Dropdown } from "primereact/dropdown";
import { SiJira } from "react-icons/si";
import {
  deleteRuleService,
  fetchRulesService,
  pauseRuleService,
  resumeRuleService,
  startRuleService,
  stopRuleService,
} from "../../API Services/LandingPageService/RulelistService";

import {
  editDBService,
  editPGPService,
  editSFTPService,
} from "../../API Services/fetchSecretsService";
import RuleListSkeleton from "./skeleton/RuleListSkeleton";
import CreateJobSkeleton from "../../vaultSecrets/skeleton/CreateJobSkeleton";
import EditJobSkeleton from "../../vaultSecrets/skeleton/EditJobSekeleton";

let checkStatus = (active) => {
  switch (active) {
    case 0:
      return "Not Started ";
    case 1:
      return "Started ";
    case 2:
      return "Pause ";
    case 3:
      return "Resume ";
    case 4:
      return "Deleted ";
    default:
      return "Stopped ";
  }
};

const confirm1 = () => {
  confirmDialog({
    group: "headless",
    message: "Are you sure you want to Delete Now ?",
    header: "Confirmation",
    icon: "pi pi-exclamation-triangle",
    defaultFocus: "accept",
  });
};

//rule list
const RuleList = ({ rules, setRules }) => {
  const navigate = useNavigate();
  const company = localStorage.getItem("company");

  const [editLoading, setEditLoading] = useState(false);
  // State to manage actions for each rule
  const [ruleActions, setRuleActions] = useState({});
  const [ruleStatus, setRuleStatus] = useState({});
  const [currentRuleId, setCurrentRuleId] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const toast = useRef(null);

  // drop down fields for the user dev/qa/prod
  // const { getRole } = useUserContext();
  // const role = getRole();

  // const viewRoles = [
  //   {
  //     name: "DEV",
  //     code: "DEV",
  //     color: "green",
  //     fetchApi: "rules/rulelist/dev",
  //   },
  //   { name: "QA", code: "QA", color: "blue", fetchApi: "rules/rulelist/qa" },
  //   {
  //     name: "PROD",
  //     code: "PROD",
  //     color: "red",
  //     fetchApi: "rules/rulelist/prod",
  //   },
  // ];
  // const [selectedViewRole, setSelectedViewRole] = useState(viewRoles[1]);

  const { getRole } = useUserContext();
  const role = getRole();

  // Define your roles and corresponding views
  const viewRoles = [
    {
      name: "DEV",
      code: "DEV",
      color: "green",
      fetchApi: `rules/rulelist/dev?company=${company}`,
    },
    {
      name: "QA",
      code: "QA",
      color: "blue",
      fetchApi: `rules/rulelist/qa?company=${company}`,
    },
    {
      name: "PROD",
      code: "PROD",
      color: "red",
      fetchApi: `rules/rulelist/prod?company=${company}`,
    },
  ];

  // Set the default selected view role based on the user role
  const getDefaultRoleIndex = (role) => {
    if (role === "DEV") return 0;
    if (role === "QA") return 1;
    if (role === "PROD" || role === "ADMIN") return 2;
    return 1; // default to QA if role is unknown
  };

  const [selectedViewRole, setSelectedViewRole] = useState(
    viewRoles[getDefaultRoleIndex(role)]
  );

  // const fetchRules = async () => {
  //   const axiosInstance = await myAxios();
  //   await axiosInstance
  //     .get(selectedViewRole.fetchApi)
  //     .then((res) => res.data)
  //     .then((finalRes) => {
  //       setRules(finalRes);
  //       setLoading(false);
  //     })
  //     .catch((e) => {
  //       if (e.response && e.response.status === 401) {
  //         navigate("/login");
  //       } else {
  //         console.error(e);
  //       }
  //     });
  // };

  const fetchRules = async () => {
    try {
      const data = await fetchRulesService(selectedViewRole.fetchApi);
      setRules(data);
      setLoading(false);
    } catch (e) {
      if (e.response && e.response.status === 401) {
        navigate("/login");
      } else {
        console.error(e);
      }
    }
  };

  // Call fetchRules whenever selectedViewRole changes
  useEffect(() => {
    setLoading(true);
    fetchRules();
  }, [selectedViewRole]);

  // useEffect(() => {
  //   fetchRules();
  // }, []);

  // const handleStart = async (ruleId) => {
  //   setRuleActions({ ...ruleActions, [ruleId]: "started" });
  //   setRuleStatus({ ...ruleStatus, [ruleId]: "Started" });
  //   const ruleCode = ruleId.substring(0, 3);
  //   const axiosInstance = await myAxios();
  //   await axiosInstance
  //     .post(`${ruleCode}/sch/start/${ruleId}`)
  //     .then((res) => res.data)
  //     .then((fres) => console.log(fres))
  //     .catch((e) => console.error(e));

  //   fetchRules();
  // };

  // const handleStop = async (ruleId) => {
  //   setRuleActions({ ...ruleActions, [ruleId]: "stopped" });
  //   setRuleStatus({ ...ruleStatus, [ruleId]: "Stopped" });
  //   const ruleCode = ruleId.substring(0, 3);
  //   const axiosInstance = await myAxios();
  //   await axiosInstance
  //     .post(`${ruleCode}/sch/stop/${ruleId}`)
  //     .then((res) => res.data)
  //     .then((fres) => console.log(fres))
  //     .catch((e) => console.error(e));

  //   fetchRules();
  // };

  // const handlePause = async (ruleId) => {
  //   setRuleActions({ ...ruleActions, [ruleId]: "paused" });
  //   setRuleStatus({ ...ruleStatus, [ruleId]: "Paused" });
  //   const ruleCode = ruleId.substring(0, 3);
  //   const axiosInstance = await myAxios();
  //   await axiosInstance
  //     .post(`${ruleCode}/sch/pause/${ruleId}`)
  //     .then((res) => res.data)
  //     .then((fres) => console.log(fres))
  //     .catch((e) => console.error(e));

  //   fetchRules();
  // };

  // const handleResume = async (ruleId) => {
  //   setRuleActions({ ...ruleActions, [ruleId]: "resumed" });
  //   setRuleStatus({ ...ruleStatus, [ruleId]: "Resumed" });
  //   const ruleCode = ruleId.substring(0, 3);
  //   const axiosInstance = await myAxios();
  //   await axiosInstance
  //     .post(`${ruleCode}/sch/resume/${ruleId}`)
  //     .then((res) => res.data)
  //     .then((fres) => console.log(fres))
  //     .catch((e) => console.error(e));

  //   fetchRules();
  // };

  // const handleDelete = async (ruleId) => {
  //   setRuleActions({ ...ruleActions, [ruleId]: "delete" });
  //   setRuleStatus({ ...ruleStatus, [ruleId]: "Deleted" });
  //   const ruleCode = ruleId.substring(0, 3);
  //   const axiosInstance = await myAxios();
  //   await axiosInstance
  //     .delete(`${ruleCode}/sch/delete/${ruleId}`)
  //     .then((res) => res.data)
  //     .then((fres) => console.log(fres))
  //     .catch((e) => console.error(e));

  //   fetchRules();
  // };

  // const handleStart = async (ruleId) => {
  //   setRuleActions({ ...ruleActions, [ruleId]: "started" });
  //   setRuleStatus({ ...ruleStatus, [ruleId]: "Started" });
  //   const ruleCode = ruleId.substring(0, 3);
  //   const axiosInstance = await myAxios();
  //   selectedViewRole.name === "DEV"
  //     ? await axiosInstance
  //         .post(`${ruleCode}/sch/start/${ruleId}`)
  //         .then((res) => res.data)
  //         .then((fres) => console.log(fres))
  //         .catch((e) => console.error(e))
  //     : await axiosInstance
  //         .post(
  //           `${selectedViewRole.name.toLocaleLowerCase()}/${ruleCode}/sch/start/${ruleId}`
  //         )
  //         .then((res) => res.data)
  //         .then((fres) => console.log(fres))
  //         .catch((e) => console.error(e));

  //   fetchRules();
  // };

  const handleStart = async (ruleId) => {
    setRuleActions({ ...ruleActions, [ruleId]: "started" });
    setRuleStatus({ ...ruleStatus, [ruleId]: "Started" });

    try {
      const fres = await startRuleService(ruleId, selectedViewRole);
      console.log(fres);
    } catch (error) {
      // .catch((e) => {console.error(e)
      toast?.current?.show({
        severity: "error",
        summary: "Error",
        detail: error.response?.data,
        life: 4000,
      });
      console.error("Error starting rule:", error);
    }

    fetchRules();
  };

  // const handleStop = async (ruleId) => {
  //   setRuleActions({ ...ruleActions, [ruleId]: "stopped" });
  //   setRuleStatus({ ...ruleStatus, [ruleId]: "Stopped" });
  //   const ruleCode = ruleId.substring(0, 3);
  //   const axiosInstance = await myAxios();
  //   selectedViewRole.name === "DEV"
  //     ? await axiosInstance
  //         .post(`${ruleCode}/sch/stop/${ruleId}`)
  //         .then((res) => res.data)
  //         .then((fres) => console.log(fres))
  //         .catch((e) => console.error(e))
  //     : await axiosInstance
  //         .post(
  //           `${selectedViewRole.name.toLowerCase()}/${ruleCode}/sch/stop/${ruleId}`
  //         )
  //         .then((res) => res.data)
  //         .then((fres) => console.log(fres))
  //         .catch((e) => console.error(e));

  //   fetchRules();
  // };

  const handleStop = async (ruleId) => {
    setRuleActions({ ...ruleActions, [ruleId]: "stopped" });
    setRuleStatus({ ...ruleStatus, [ruleId]: "Stopped" });

    try {
      const fres = await stopRuleService(ruleId, selectedViewRole);
      console.log(fres);
    } catch (error) {
      console.error("Error stopping rule:", error);
    }

    fetchRules();
  };

  // const handlePause = async (ruleId) => {
  //   setRuleActions({ ...ruleActions, [ruleId]: "paused" });
  //   setRuleStatus({ ...ruleStatus, [ruleId]: "Paused" });
  //   const ruleCode = ruleId.substring(0, 3);
  //   const axiosInstance = await myAxios();
  //   selectedViewRole.name === "DEV"
  //     ? await axiosInstance
  //         .post(`${ruleCode}/sch/pause/${ruleId}`)
  //         .then((res) => res.data)
  //         .then((fres) => console.log(fres))
  //         .catch((e) => console.error(e))
  //     : await axiosInstance
  //         .post(
  //           `${selectedViewRole.name.toLowerCase()}/${ruleCode}/sch/pause/${ruleId}`
  //         )
  //         .then((res) => res.data)
  //         .then((fres) => console.log(fres))
  //         .catch((e) => console.error(e));

  //   fetchRules();
  // };

  const handlePause = async (ruleId) => {
    setRuleActions({ ...ruleActions, [ruleId]: "paused" });
    setRuleStatus({ ...ruleStatus, [ruleId]: "Paused" });

    try {
      const fres = await pauseRuleService(ruleId, selectedViewRole);
      console.log(fres);
    } catch (error) {
      console.error("Error pausing rule:", error);
    }

    fetchRules();
  };

  // const handleResume = async (ruleId) => {
  //   setRuleActions({ ...ruleActions, [ruleId]: "resumed" });
  //   setRuleStatus({ ...ruleStatus, [ruleId]: "Resumed" });
  //   const ruleCode = ruleId.substring(0, 3);
  //   const axiosInstance = await myAxios();
  //   selectedViewRole.name === "DEV"
  //     ? await axiosInstance
  //         .post(`${ruleCode}/sch/resume/${ruleId}`)
  //         .then((res) => res.data)
  //         .then((fres) => console.log(fres))
  //         .catch((e) => console.error(e))
  //     : await axiosInstance
  //         .post(
  //           `${selectedViewRole.name.toLowerCase()}/${ruleCode}/sch/resume/${ruleId}`
  //         )
  //         .then((res) => res.data)
  //         .then((fres) => console.log(fres))
  //         .catch((e) => console.error(e));

  //   fetchRules();
  // };

  const handleResume = async (ruleId) => {
    setRuleActions({ ...ruleActions, [ruleId]: "resumed" });
    setRuleStatus({ ...ruleStatus, [ruleId]: "Resumed" });

    try {
      const fres = await resumeRuleService(ruleId, selectedViewRole);
      console.log(fres);
    } catch (error) {
      console.error("Error resuming rule:", error);
    }

    fetchRules();
  };

  // const handleDelete = async (ruleId) => {
  //   setRuleActions({ ...ruleActions, [ruleId]: "delete" });
  //   setRuleStatus({ ...ruleStatus, [ruleId]: "Deleted" });
  //   const ruleCode = ruleId.substring(0, 3);
  //   const axiosInstance = await myAxios();
  //   if (selectedViewRole.name === "DEV") {
  //     await axiosInstance
  //       .delete(`${ruleCode}/sch/delete/${ruleId}`)
  //       .then((res) => res.data)
  //       .then((fres) => console.log(fres))
  //       .catch((e) => console.error(e));
  //   } else if (selectedViewRole.name === "QA") {
  //     await axiosInstance
  //       .delete(`qa/${ruleCode}/sch/delete/${ruleId}`)
  //       .then((res) => res.data)
  //       .then((fres) => console.log(fres))
  //       .catch((e) => console.error(e));
  //   } else if (selectedViewRole.name === "PROD") {
  //     await axiosInstance
  //       .delete(`prod/${ruleCode}/sch/delete/${ruleId}`)
  //       .then((res) => res.data)
  //       .then((fres) => console.log(fres))
  //       .catch((e) => console.error(e));
  //   }

  //   fetchRules();
  // };

  const handleDelete = async (ruleId) => {
    setRuleActions({ ...ruleActions, [ruleId]: "delete" });
    setRuleStatus({ ...ruleStatus, [ruleId]: "Deleted" });

    try {
      const fres = await deleteRuleService(ruleId, selectedViewRole);
      // console.log(fres);
    } catch (error) {
      console.error("Error deleting rule:", error);
    }

    fetchRules();
  };

  const renderActionButtons = (rowData) => {
    const { ruleId, active } = rowData;

    switch (active) {
      case 0: // Not Started
        return (
          <Button
            tooltip="Start"
            tooltipOptions={{ position: "bottom" }}
            className="i-20px"
            onClick={() => handleStart(ruleId)}
            style={{ fontSize: "1rem" }}
            icon="pi pi-play-circle"
            rounded
            text
            severity="danger"
            aria-label="Start"
          />
        );
      case 1:
      case 3: // Started
        return (
          <>
            <Button
              tooltip="Stop"
              tooltipOptions={{ position: "bottom" }}
              className="i-20px"
              icon="pi pi-stop-circle"
              onClick={() => handleStop(ruleId)}
              rounded
              text
              severity="danger"
              aria-label="Stop"
            />
            <Button
              tooltip="Pause"
              tooltipOptions={{ position: "bottom" }}
              className="i-20px"
              icon="pi pi-pause-circle"
              onClick={() => handlePause(ruleId)}
              rounded
              text
              severity="danger"
              aria-label="Pause"
            />
          </>
        );
      case 2: // Paused
        return (
          <>
            <Button
              tooltip="Stop"
              tooltipOptions={{ position: "bottom" }}
              className="i-20px"
              icon="pi pi-stop-circle"
              onClick={() => handleStop(ruleId)}
              rounded
              text
              severity="danger"
              aria-label="Stop"
            />
            <Button
              tooltip="Resume"
              tooltipOptions={{ position: "bottom" }}
              className="i-20px"
              icon="pi pi-circle-fill"
              onClick={() => handleResume(ruleId)}
              rounded
              text
              severity="success"
              aria-label="Resume"
            />
          </>
        );
      case 4: // Deleted
        return null; // or any specific UI for deleted rules
      default:
        return (
          <Button
            tooltip="Start"
            tooltipOptions={{ position: "bottom" }}
            className="i-20px"
            onClick={() => handleStart(ruleId)}
            icon="pi pi-play-circle"
            rounded
            text
            severity="danger"
            aria-label="Start"
          />
        );
    }
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = rules
    .filter((rule) => rule.active !== 4)
    .map((rule, idx) => ({
      ...rule,
      ruleID: (currentPage - 1) * itemsPerPage + idx + 1,
      createdTime: format(rule.createdTime, "dd/MM/yyyy HH:mm:ss"),
      modifiedTime: format(rule.modifiedTime, "dd/MM/yyyy HH:mm:ss"),
      status: ruleStatus[rule.status]
        ? ruleStatus[rule.status]
        : checkStatus(rule.active), // status new_fix
    }));

  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    fileName: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    createdTime: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    modifiedTime: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    createdby: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    updatedby: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    status: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
  });

  const [loading, setLoading] = useState(true);
  const [globalFilterValue, setGlobalFilterValue] = useState("");

  const getSeverity = (status) => {
    switch (status) {
      case "Stopped":
        return "danger";

      case "Started":
        return "success";

      case "Resume":
        return "info";

      case "Pause":
        return "warning";

      case "Deleted":
        return null;
    }
  };

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const gotoCreatePost = () => {
    navigate("/rules/create");
  };

  // const renderHeader = () => {
  //   return (
  //     <>
  //     <div className="flex justify-content-end flex-row align-items-center gap-3">
  //       <Button
  //         onClick={() => gotoCreatePost()}
  //         severity="primary"
  //         size="small"
  //         className="border-round-lg"
  //         tooltip="Create Job"
  //         tooltipOptions={{ position: "bottom" }}
  //         label="Create Job"
  //         icon="pi pi-plus"
  //       />
  //       <IconField iconPosition="left">
  //         <InputIcon className="pi pi-search" />
  //         <InputText
  //           value={globalFilterValue}
  //           onChange={onGlobalFilterChange}
  //           placeholder="Keyword Search"
  //           className="p-inputtext-sm"
  //         />
  //       </IconField>
  //     </div>
  // </>
  //   );
  // };

  const renderHeader = () => {
    return (
      <>
        {/* currenet dept status */}

        <div className="">
          <div className="current-view">
            {
              <div className="circleInd">
                {/* <FaCircle color={selectedViewRole ? selectedViewRole.color : ''}/>   */}
                <h4
                  style={{
                    margin: "auto",
                  }}
                >
                  {" "}
                  {selectedViewRole ? selectedViewRole.name : ""}
                </h4>
              </div>
            }

            <div className="viewas-createjob-search flex justify-content-end flex-row align-items-center gap-3">
              <div>
                <Dropdown
                  value={selectedViewRole}
                  onChange={(e) => {
                    setSelectedViewRole(e.value);
                  }}
                  options={viewRoles}
                  optionLabel="name"
                  placeholder="Select View As"
                  className="p-inputtext-sm viewDd"
                />
              </div>
              {role !== "PROD" && (
                <Button
                  onClick={() => gotoCreatePost()}
                  severity="primary"
                  size="small"
                  className="border-round-lg"
                  tooltip="Create Job"
                  tooltipOptions={{ position: "bottom" }}
                  label="Create Job"
                  icon="pi pi-plus"
                />
              )}

              <IconField iconPosition="left">
                <InputIcon className="pi pi-search" />
                <InputText
                  value={globalFilterValue}
                  onChange={onGlobalFilterChange}
                  placeholder="Keyword Search"
                  className="p-inputtext-sm"
                />
              </IconField>
            </div>
          </div>
        </div>
      </>
    );
  };

  const statusBodyTemplate = (rowData) => {
    return (
      <Tag
        value={rowData.status}
        severity={getSeverity(rowData.status?.trim())}
      />
    );
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <>
        <div className="flex flex-row align-items-center justify-content-end gap-2">
          {renderActionButtons(rowData)}
          <Button
            tooltip="Edit"
            tooltipOptions={{ position: "bottom" }}
            icon="pi pi-pencil"
            rounded
            text
            severity="secondary"
            aria-label="Edit"
            onClick={() => handleEditClick(rowData.ruleId)}
          />
          <Button
            tooltip="Delete"
            tooltipOptions={{ position: "bottom" }}
            icon="pi pi-trash"
            rounded
            text
            severity="danger"
            aria-label="Delete"
            onClick={() => {
              setCurrentRuleId(rowData.ruleId);
              confirm1();
            }}
          />{" "}
          {/**new_fix */}
        </div>
      </>
    );
  };

  const header = renderHeader();

  //this is for edit page

  const handleEditClick = async (ruleId) => {
    setEditLoading(true);
    const ruleCode = ruleId.substring(0, 3);

    // console.log("RuleCode:", ruleCode);

    // If the ruleCode is "mtm", perform the logic similar to handleMountToMountClick
    if (ruleCode === "mtm") {
      // setLoading(true); // Set loading state to true

      // const axiosInstance = await myAxios();

      // const response = await axiosInstance
      //   .get(`/secrets/get-by-company/get?company=${company}&type=sftp`)
      //   .then((res) => res.data)
      //   .catch((e) => e.response);

      const response = await editSFTPService(company);

      // setLoading(false);

      if (response.status !== undefined && response.status === 401) {
        toast.error("Token Expired, Please Login Again", {
          theme: "colored",
        });
        localStorage.clear();
        navigate("/login");
        return;
      }

      // After successful response, navigate with the response and ruleId
      navigate(`/${ruleCode}/rules/edit`, {
        state: { ruleId, response, viewRole: selectedViewRole.name },
      });
    }
    // If the ruleCode is "mtd", perform the logic similar to handleMountToDatabaseClick
    else if (ruleCode === "mtd") {
      // setLoading(true); // Set loading state to true

      // const axiosInstance = await myAxios();

      // // Fetch SFTP data
      // const sftpResponse = await axiosInstance
      //   .get(`/secrets/get-by-company/get?company=${company}&type=sftp`)
      //   .then((res) => res.data)
      //   .catch((e) => e.response);
      const sftpResponse = await editSFTPService(company);

      if (sftpResponse.status !== undefined && sftpResponse.status === 401) {
        setEditLoading(false);
        toast.error("Token Expired, Please Login Again", {
          theme: "colored",
        });
        localStorage.clear();
        navigate("/login");
        return;
      }

      // Fetch Database data
      // const dbResponse = await axiosInstance
      //   .get(`/secrets/get-by-company/get?company=${company}&type=db`)
      //   .then((res) => res.data)
      //   .catch((e) => e.response);

      const dbResponse = await editDBService(company);

      if (dbResponse.status !== undefined && dbResponse.status === 401) {
        setEditLoading(false);
        toast.error("Token Expired, Please Login Again", {
          theme: "colored",
        });
        localStorage.clear();
        navigate("/login");
        return;
      }

      const responsePGP = await editPGPService(company);
      if (responsePGP.status !== undefined && responsePGP.status === 401) {
        setEditLoading(false);
        toast.error("Token Expired, Please Login Again", {
          theme: "colored",
        });
        localStorage.clear();
        navigate("/login");
        return;
      }

      // setLoading(false);
      // Navigate to the page with both SFTP and DB responses
      navigate(`/${ruleCode}/rules/edit`, {
        state: {
          ruleId,
          dbResponse,
          sftpResponse,
          responsePGP,

          viewRole: selectedViewRole.name,
        },
      });
    } else if (ruleCode === "dtm") {
      // setLoading(true); // Set loading state to true

      // const axiosInstance = await myAxios();

      // const sftpResponse = await axiosInstance
      //   .get(`/secrets/get-by-company/get?company=${company}&type=sftp`)
      //   .then((res) => res.data)
      //   .catch((e) => e.response);

      const sftpResponse = await editSFTPService(company);

      if (sftpResponse.status !== undefined && sftpResponse.status === 401) {
        setEditLoading(false);
        toast.error("Token Expired, Please Login Again", {
          theme: "colored",
        });
        localStorage.clear();
        navigate("/login");
        return;
      }
      // Fetch Database data

      // const dbResponse = await axiosInstance
      //   .get(`/secrets/get-by-company/get?company=${company}&type=db`)
      //   .then((res) => res.data)
      //   .catch((e) => e.response);
      const dbResponse = await editDBService(company);
      if (dbResponse.status !== undefined && dbResponse.status === 401) {
        setEditLoading(false);
        toast.error("Token Expired, Please Login Again", {
          theme: "colored",
        });
        localStorage.clear();
        navigate("/login");
        return;
      }

      const responsePGP = await editPGPService(company);
      if (responsePGP.status !== undefined && responsePGP.status === 401) {
        setEditLoading(false);
        toast.error("Token Expired, Please Login Again", {
          theme: "colored",
        });
        localStorage.clear();
        navigate("/login");
        return;
      }

      setEditLoading(false);
      // Navigate to the appropriate page with both responses
      navigate(`/${ruleCode}/rules/edit`, {
        state: {
          ruleId,
          dbResponse,
          sftpResponse,
          responsePGP,

          viewRole: selectedViewRole.name,
        },
      });
    } else if (ruleCode === "dtd") {
      // setLoading(true); // Set loading state to true
      // console.log(ruleCode + "hhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhh");
      // try {
      // Fetch Database data
      // const axiosInstance = await myAxios();

      // const response = await axiosInstance
      //   .get(`/secrets/get-by-company/get?company=${company}&type=db`)
      //   .then((res) => res.data)
      //   .catch((e) => e.response);

      const response = await editDBService(company);

      setEditLoading(false);
      if (response.status !== undefined && response.status === 401) {
        toast.error("Token Expired, Please Login Again", {
          theme: "colored",
        });
        localStorage.clear();
        navigate("/login");
        return;
      }
      // Navigate to the appropriate page with Database response
      navigate(`/${ruleCode}/rules/edit`, {
        state: { ruleId, response, viewRole: selectedViewRole.name },
      });
    }
  };

  // const handleHistory = (rowData) => {
  //   const ruleId = rowData.ruleId;
  //   navigate("/home/history", { state: { ruleId } });
  // };

  const handleHistory = (rowData) => {
    const ruleId = rowData.ruleId;
    navigate("/home/history", {
      state: { ruleId, viewRole: selectedViewRole.name },
    });
  };

  // this method will get the name from the jira url
  const getLastSegment = (url) => {
    if (!url) return ""; // Handle null or undefined URLs
    const segments = url.split("/"); // Split the URL by "/"
    return segments.pop(); // Get the last segment
  };

  return loading ? (
    <RuleListSkeleton /> // Show RuleListSkeleton if loading is true
  ) : editLoading ? (
    <EditJobSkeleton /> // Show CreateJobSkeleton if editLoading is true
  ) : (
    <>
      <Toast ref={toast} />
      <DataTable
        value={currentItems}
        paginator
        rowsPerPageOptions={[10, 50, 100, 500]}
        rows={itemsPerPage}
        dataKey="id"
        filters={filters}
        filterDisplay="row"
        globalFilterFields={[
          "ruleID",
          "fileName",
          "createdTime",
          "modifiedTime",
          "createdby",
          "updatedby",
          "jiraUrl",
          "modifiedTime",
        ]}
        header={header}
        emptyMessage="No customers found."
      >
        <Column field="ruleID" header="Sl No" style={{ minWidth: "6rem" }} />
        <Column
          header="Job Name"
          field="fileName"
          filterField="fileName"
          filter
          filterPlaceholder="Search by file"
          style={{ minWidth: "12rem", cursor: "pointer" }}
          body={(rowData) => (
            <div
              style={{
                display: "inline-block",
                padding: "0.5rem",
                borderRadius: "4px",
                transition: "background-color 0.3s",
                cursor: "pointer",
              }}
              onClick={() => handleHistory(rowData)}
              className="underline-text highlight-row"
              data-pr-tooltip="View History"
            >
              {rowData.fileName}
              <hr style={{ marginTop: "2px" }}></hr>
              <Tooltip target=".highlight-row" />
            </div>
          )}
        />
        <Column
          header="Created Time"
          field="createdTime"
          filterField="createdTime"
          filter
          style={{ minWidth: "14rem" }}
        />
        <Column
          field="modifiedTime"
          header="Modified Time"
          filterMenuStyle={{ width: "14rem" }}
          filter
          style={{ minWidth: "12rem" }}
        />
        <Column
          field="createdby"
          header="Created By"
          filter
          style={{ minWidth: "6rem" }}
        />
        <Column
          field="updatedby"
          header="Updated By"
          filter
          style={{ minWidth: "6rem" }}
        />
        <Column
          field="jiraUrl"
          header="JIRA URL"
          body={(rowData) => (
            <a href={rowData.jiraUrl} target="_blank" rel="noopener noreferrer">
              <SiJira color="blue" />[{getLastSegment(rowData.jiraUrl)}]
            </a>
          )}
          filter
          style={{ minWidth: "6rem" }}
        />
        <Column
          field="status"
          header="Status"
          filter
          style={{ minWidth: "6rem" }}
          body={statusBodyTemplate}
        />
        {((role === "DEV" && selectedViewRole.name === "DEV") ||
          (role === "QA" && selectedViewRole.name === "QA") ||
          role === "ADMIN" ||
          (role === "PROD" && selectedViewRole.name === "PROD")) && (
          <Column
            header="Action"
            style={{ minWidth: "6rem" }}
            body={actionBodyTemplate}
          />
        )}
      </DataTable>

      {/* Confirm Dialog */}
      <ConfirmDialog
        group="headless"
        content={({ headerRef, contentRef, footerRef, hide, message }) => (
          <div className="flex flex-column align-items-center p-5 surface-overlay border-round">
            <div className="border-circle bg-primary inline-flex justify-content-center align-items-center h-6rem w-6rem -mt-8">
              <i className="pi pi-exclamation-triangle text-5xl"></i>
            </div>
            <span
              className="font-bold text-2xl block mb-2 mt-4"
              ref={headerRef}
            >
              {message.header}
            </span>
            <p className="mb-0" ref={contentRef}>
              {message.message}
            </p>
            <div className="flex align-items-center gap-2 mt-4" ref={footerRef}>
              <Button
                label="Yes"
                severity="danger"
                onClick={(event) => {
                  hide(event);
                  handleDelete(currentRuleId);
                }}
                className="w-8rem"
              ></Button>
              <Button
                label="No"
                outlined
                onClick={(event) => {
                  hide(event);
                  setCurrentRuleId(null);
                }}
                className="w-8rem"
              ></Button>
            </div>
          </div>
        )}
      />
    </>
  );
};
export default RuleList;
