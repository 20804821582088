// import React, { useState, useEffect, useRef } from "react";
// import { FilterMatchMode } from "primereact/api";
// import { DataTable } from "primereact/datatable";
// import { Column } from "primereact/column";
// import { InputText } from "primereact/inputtext";
// import { IconField } from "primereact/iconfield";
// import { InputIcon } from "primereact/inputicon";
// import { Dropdown } from "primereact/dropdown";
// import { Button } from "primereact/button"; // For action buttons (Edit/Delete)
// import Breadcrumbs from "../components/Breadcrumb";
// import { Dialog } from "primereact/dialog";
// import { Toast } from "primereact/toast";
// import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
// import { Password } from "primereact/password";
// import { useUserContext } from "../components/contextAPIs/UserContext";
// import { handleEncryptedData } from "../Encrypt API/ModifiedEncrypt";
// import {
//   checkEmptyField,
//   checkEmptyFieldForVault,
// } from "../common/handleEmptyField";
// import {
//   deleteSecret,
//   getAllSecretsSftpAndDB,
//   updateDBSecret,
//   updateSecret,
// } from "../API Services/VaultSecrets/ViewSecretsService";

// export default function ViewSecret() {
//   const [secrets, setSecrets] = useState([]);
//   const [filters, setFilters] = useState({
//     global: { value: null, matchMode: FilterMatchMode.CONTAINS },
//     name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
//     host: { value: null, matchMode: FilterMatchMode.CONTAINS },
//     port: { value: null, matchMode: FilterMatchMode.EQUALS },
//     username: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
//   });
//   const [loading, setLoading] = useState(true);
//   const [globalFilterValue, setGlobalFilterValue] = useState("");
//   const [sftpList, setSftpList] = useState([]);
//   const [dbList, setDbList] = useState([]);
//   const [selectedViewSecrets, setSelectedViewSecrets] =
//     useState("SFTP secrets");
//   const toast = useRef(null);
//   const { getRole } = useUserContext();
//   const role = getRole();

//   const [sftpVisible, setSftpVisible] = useState(false);
//   const [sftpValue, setSftpValue] = useState({
//     secretName: "",
//     company: localStorage.getItem("company") || "",
//     type: "sftp",
//     host_dev: "",
//     port_dev: "",
//     username_dev: "",
//     password_dev: "",
//     host_qa: "",
//     port_qa: "",
//     username_qa: "",
//     password_qa: "",
//     host_prod: "",
//     port_prod: "",
//     username_prod: "",
//     password_prod: "",
//   });
//   const [sftpError, setSftpError] = useState({});
//   const [dbValue, setDbValue] = useState({
//     secretName: "",
//     company: localStorage.getItem("company") || "",
//     type: "db",
//     dburl_dev: "",
//     username_dev: "",
//     password_dev: "",
//     dburl_qa: "",
//     username_qa: "",
//     password_qa: "",
//     dburl_prod: "",
//     username_prod: "",
//     password_prod: "",
//   });
//   const [dbError, setDbError] = useState({});
//   const [dbVisible, setDbVisible] = useState(false);
//   const handleSftpInputChange = (e) => {
//     const { name, value } = e.target;
//     setSftpValue({
//       ...sftpValue,
//       [name]: value,
//     });
//     setSftpError({});
//   };
//   const handleDbInputChange = (e) => {
//     const { name, value } = e.target;
//     setDbValue({
//       ...dbValue,
//       [name]: value,
//       name,
//     });
//     setDbError({});
//   };
//   const saveHandler = async () => {
//     const requestData = {
//       name: sftpValue.secretName,
//       type: "sftp",
//       company: localStorage.getItem("company") || "",
//       dev: {
//         host: sftpValue.host_dev,
//         port: sftpValue.port_dev,
//         username: sftpValue.username_dev,
//         password: sftpValue.password_dev,
//       },
//       qa: {
//         host: sftpValue.host_qa,
//         port: sftpValue.port_qa,
//         username: sftpValue.username_qa,
//         password: sftpValue.password_qa,
//       },
//       prod: {
//         host: sftpValue.host_prod,
//         port: sftpValue.port_prod,
//         username: sftpValue.username_prod,
//         password: sftpValue.password_prod,
//       },
//     };

//     try {
//       let sftpEmptyError = checkEmptyFieldForVault(requestData);
//       console.log("sftpEmptyError", sftpEmptyError);
//       if (Object.keys(sftpEmptyError).length !== 0) {
//         setSftpError(sftpEmptyError);
//         return;
//       }
//       const encryptedData = await handleEncryptedData(requestData);
//       const response = await updateSecret(encryptedData);
//       console.log(response, " update response ");

//       if (response.status === 200) {
//         toast.current.show({
//           severity: "success",
//           summary: "Success",
//           detail: "SFTP details updated successfully.",
//           life: 3000,
//         });

//         setSecrets((secrets) =>
//           secrets.map((secret) =>
//             secret.name === requestData.name ? requestData : secret
//           )
//         );
//         setSftpList((secrets) =>
//           secrets.map((secret) =>
//             secret.name === requestData.name ? requestData : secret
//           )
//         );
//       }
//     } catch (error) {
//       const errorMessage = error.response
//         ? error.response.data.message
//         : "Something went wrong!";
//       toast.current.show({
//         severity: "error",
//         summary: "Error",
//         detail: errorMessage,
//         life: 3000,
//       });
//     }
//     setSftpVisible(false); // Close the dialog after saving
//   };

//   const onGlobalFilterChange = (e) => {
//     const value = e.target.value;
//     let _filters = { ...filters };

//     _filters["global"].value = value;

//     setFilters(_filters);
//     setGlobalFilterValue(value);
//   };

//   const viewSecrets = [{ name: "SFTP secrets" }, { name: "Database secrets" }];
//   const renderHeader = () => {
//     return (
//       <div
//         style={{
//           display: "flex",
//           justifyContent: "space-between",
//           alignItems: "center",
//           width: "100%",
//         }}
//       >
//         <div style={{ flex: 1 }} />
//         <h2 style={{ textAlign: "center", flex: 2 }}>{selectedViewSecrets}</h2>
//         <div style={{ display: "flex", alignItems: "center" }}>
//           <Dropdown
//             value={viewSecrets.find((e) => e.name === selectedViewSecrets)}
//             onChange={(e) => {
//               console.log(e.value, "value of secrets ");
//               setSelectedViewSecrets(e.value.name);
//               if (e.value.name === "Database secrets") {
//                 setSecrets(dbList); // Show DB secrets
//               } else {
//                 setSecrets(sftpList); // Show SFTP secrets
//               }
//             }}
//             options={viewSecrets}
//             optionLabel="name"
//             placeholder="Select Secret"
//             className="p-inputtext-m"
//             style={{ width: "13rem", marginRight: "1rem" }}
//           />
//           <IconField iconPosition="left">
//             <InputIcon className="pi pi-search" />
//             <InputText
//               value={globalFilterValue}
//               onChange={onGlobalFilterChange}
//               placeholder="Keyword Search"
//               className="p-inputtext-m"
//             />
//           </IconField>
//         </div>
//       </div>
//     );
//   };

//   const saveDbHandler = async () => {
//     const requestData = {
//       name: dbValue.secretName,
//       type: "db",
//       company: localStorage.getItem("company") || "",

//       dev: {
//         dburl: dbValue.dburl_dev,
//         username: dbValue.username_dev,
//         password: dbValue.password_dev,
//       },
//       qa: {
//         dburl: dbValue.dburl_qa,
//         username: dbValue.username_qa,
//         password: dbValue.password_qa,
//       },
//       prod: {
//         dburl: dbValue.dburl_prod,
//         username: dbValue.username_prod,
//         password: dbValue.password_prod,
//       },
//     };

//     try {
//       let emptyFieldError = checkEmptyField(requestData);
//       if (Object.keys(emptyFieldError).length !== 0) {
//         setDbError(emptyFieldError);
//         return;
//       }
//       const encryptedData = await handleEncryptedData(requestData);
//       const response = await updateDBSecret(encryptedData);
//       if (response.status === 200) {
//         toast.current.show({
//           severity: "success",
//           summary: "Success",
//           detail: "Database details updated successfully.",
//           life: 3000,
//         });

//         setSecrets((secrets) =>
//           secrets.map((secret) =>
//             secret.name === requestData.name ? requestData : secret
//           )
//         );

//         setDbList((secrets) =>
//           secrets.map((secret) =>
//             secret.name === requestData.name ? requestData : secret
//           )
//         );
//       }
//     } catch (error) {
//       const errorMessage = error.response
//         ? error.response.data.message
//         : "Something went wrong!";
//       toast.current.show({
//         severity: "error",
//         summary: "Error",
//         detail: errorMessage,
//         life: 3000,
//       });
//     }
//     setDbVisible(false);
//   };

//   const sftpFooterContent = (
//     <div>
//       <Button
//         label="Cancel"
//         icon="pi pi-times"
//         onClick={() => setSftpVisible(false)}
//       />
//       <Button label="Update" icon="pi pi-check" onClick={() => saveHandler()} />
//     </div>
//   );

//   const dbFooterContent = (
//     <div>
//       <Button
//         label="Cancel"
//         icon="pi pi-times"
//         onClick={() => setDbVisible(false)}
//       />
//       <Button
//         label="Update"
//         icon="pi pi-check"
//         onClick={() => saveDbHandler()}
//       />
//     </div>
//   );

//   // fetch sftp and db request .
//   async function loadSecretsWithRetry(retries = 3, delay = 2000) {
//     try {
//       const responsedb = await getAllSecretsSftpAndDB("db");
//       return responsedb;
//     } catch (error) {
//       if (retries > 0) {
//         console.warn(`Retrying request... Attempts left: ${retries}`);
//         await new Promise((resolve) => setTimeout(resolve, delay));
//         return loadSecretsWithRetry(retries - 1, delay);
//       } else {
//         throw error;
//       }
//     }
//   }
//   async function loadSecretsWithRetrySFTP(retries = 3, delay = 2000) {
//     try {
//       const response = await getAllSecretsSftpAndDB("sftp");
//       return response;
//     } catch (error) {
//       if (retries > 0) {
//         console.warn(`Retrying request... Attempts left: ${retries}`);
//         await new Promise((resolve) => setTimeout(resolve, delay));
//         return loadSecretsWithRetry(retries - 1, delay);
//       } else {
//         throw error;
//       }
//     }
//   }

//   useEffect(() => {
//     setLoading(true);
//     async function loadSftpSecrets() {
//       try {
//         const response = await loadSecretsWithRetrySFTP();
//         if (response.status === 200) {
//           setSftpList(response.data);
//           if (selectedViewSecrets === "SFTP secrets") {
//             setSecrets(response.data);
//           }
//         } else {
//           setSftpList([]);
//         }
//       } catch (error) {
//         console.log(error, " faild to load after retry ");
//         setSftpList([]);
//       } finally {
//         setLoading(false);
//       }
//     }
//     loadSftpSecrets();
//   }, []);

//   useEffect(() => {
//     setLoading(true);

//     async function loadSecrets() {
//       try {
//         const responsedb = await loadSecretsWithRetry();

//         if (responsedb.status === 200) {
//           setDbList(responsedb.data);
//           if (selectedViewSecrets === "Database secrets") {
//             setSecrets(responsedb.data);
//           }
//         } else {
//           setDbList([]);
//         }
//       } catch (error) {
//         console.error("Failed to load secrets after retries:", error);
//         setDbList([]); // Clear dbList in case of error
//       } finally {
//         setLoading(false);
//       }
//     }

//     loadSecrets();
//   }, []);

//   const headerTemplate = (data) => {
//     return (
//       <React.Fragment>
//         <span className="vertical-align-middle ml-2 font-bold line-height-3">
//           {data.name}
//         </span>
//       </React.Fragment>
//     );
//   };
//   const headerDevBodyTemplate = (data) => {
//     return (
//       <>
//         {selectedViewSecrets === "SFTP secrets" ? (
//           <div>
//             <p className="vertical-align-middle ml-2  line-height-3">
//               {data.dev.host}
//             </p>
//             <p className="vertical-align-middle ml-2  line-height-3">
//               {data.dev.port}
//             </p>
//             <p className="vertical-align-middle ml-2  line-height-3">
//               {data.dev.username}
//             </p>
//             <Password
//               className="vertical-align-middle ml-2  line-height-3"
//               value={data.dev.password}
//               toggleMask={role === "ADMIN"}
//               disabled
//             ></Password>
//           </div>
//         ) : (
//           <div>
//             <p className="vertical-align-middle ml-2  line-height-3">
//               {data.dev.dburl}
//             </p>
//             <p className="vertical-align-middle ml-2  line-height-3">
//               {data.dev.username}
//             </p>
//             <Password
//               className="vertical-align-middle ml-2  line-height-3"
//               value={data.dev.password}
//               toggleMask={role === "ADMIN"}
//               disabled
//             ></Password>
//           </div>
//         )}
//       </>
//     );
//   };
//   const headerQABodyTemplate = (data) => {
//     return (
//       <>
//         {selectedViewSecrets === "SFTP secrets" ? (
//           <div>
//             <p className="vertical-align-middle ml-2  line-height-3">
//               {data.qa.host}
//             </p>
//             <p className="vertical-align-middle ml-2  line-height-3">
//               {data.qa.port}
//             </p>
//             <p className="vertical-align-middle ml-2  line-height-3">
//               {data.qa.username}
//             </p>
//             <Password
//               className="vertical-align-middle ml-2  line-height-3"
//               value={data.qa.password}
//               toggleMask={role === "ADMIN"}
//               disabled
//             ></Password>
//           </div>
//         ) : (
//           <div>
//             <p className="vertical-align-middle ml-2  line-height-3">
//               {data.qa.dburl}
//             </p>
//             <p className="vertical-align-middle ml-2  line-height-3">
//               {data.qa.username}
//             </p>
//             <Password
//               className="vertical-align-middle ml-2  line-height-3"
//               value={data.qa.password}
//               toggleMask={role === "ADMIN"}
//               disabled
//             ></Password>
//           </div>
//         )}
//       </>
//     );
//   };
//   const headerProdBodyTemplate = (data) => {
//     return (
//       <>
//         {selectedViewSecrets === "SFTP secrets" ? (
//           <div>
//             <p className="vertical-align-middle ml-2  line-height-3">
//               {data.prod.host}
//             </p>
//             <p className="vertical-align-middle ml-2  line-height-3">
//               {data.prod.port}
//             </p>
//             <p className="vertical-align-middle ml-2  line-height-3">
//               {data.prod.username}
//             </p>
//             <Password
//               className="vertical-align-middle ml-2  line-height-3"
//               value={data.prod.password}
//               toggleMask={role === "ADMIN"}
//               disabled
//             ></Password>
//           </div>
//         ) : (
//           <div>
//             <p className="vertical-align-middle ml-2  line-height-3">
//               {data.prod.dburl}
//             </p>
//             <p className="vertical-align-middle ml-2  line-height-3">
//               {data.prod.username}
//             </p>
//             <Password
//               className="vertical-align-middle ml-2  line-height-3"
//               value={data.prod.password}
//               toggleMask={role === "ADMIN"}
//               disabled
//             ></Password>
//           </div>
//         )}
//       </>
//     );
//   };

//   const editHandler = (rowData) => {
//     // console.log(rowData);
//     if (selectedViewSecrets === "SFTP secrets") {
//       //         // If "SFTP secrets" is selected, open the SFTP dialog and populate the values
//       setSftpValue({
//         secretName: rowData.name,
//         company: localStorage.getItem("company") || "",
//         type: "sftp",

//         host_dev: rowData.dev.host,
//         port_dev: rowData.dev.port,
//         username_dev: rowData.dev.username,
//         password_dev: rowData.dev.password,

//         host_qa: rowData.qa.host,
//         port_qa: rowData.qa.port,
//         username_qa: rowData.qa.username,
//         password_qa: rowData.qa.password,

//         host_prod: rowData.prod.host,
//         port_prod: rowData.prod.port,
//         username_prod: rowData.prod.username,
//         password_prod: rowData.prod.password,
//       });
//       setSftpVisible(true); // Open SFTP dialog
//     } else if (selectedViewSecrets === "Database secrets") {
//       // If "Database secrets" is selected, open the Database dialog and populate the values
//       // console.log(rowData);
//       setDbValue({
//         secretName: rowData.name,
//         company: localStorage.getItem("company") || "",
//         type: "db",

//         dburl_dev: rowData.dev.dburl,
//         username_dev: rowData.dev.username,
//         password_dev: rowData.dev.password,

//         dburl_qa: rowData.qa.dburl,
//         username_qa: rowData.qa.username,
//         password_qa: rowData.qa.password,

//         dburl_prod: rowData.prod.dburl,
//         username_prod: rowData.prod.username,
//         password_prod: rowData.prod.password,
//       });

//       setDbVisible(true); // Open Database dialog
//     }
//   };

//   const accept = async (name) => {
//     // remove secrets by name
//     try {
//       const response = await deleteSecret(name);
//       if (response.status === 200) {
//         // console.log(response);
//         toast.current.show({
//           severity: "success",
//           summary: "Deleted",
//           detail: `Record with ID ${name} has been deleted.`,
//           life: 3000,
//         });

//         setSecrets((secrets) =>
//           secrets.filter((secret) => secret.name != name)
//         );

//         if (selectedViewSecrets === "SFTP secrets") {
//           setSftpList((secrets) =>
//             secrets.filter((secret) => secret.name != name)
//           );
//         } else {
//           setDbList((secrets) =>
//             secrets.filter((secret) => secret.name != name)
//           );
//         }

//         //
//       } else {
//         toast.current.show({
//           severity: "error",
//           summary: "Delete Failed",
//           detail: `Failed to delete record with ID ${name}.`,
//           life: 3000,
//         });
//       }
//     } catch (error) {
//       toast.current.show({
//         severity: "error",
//         summary: "Error",
//         detail: `Error occurred: ${error.message}`,
//         life: 3000,
//       });
//     }
//   };

//   // Function to handle the reject action
//   const reject = () => {
//     toast.current.show({
//       severity: "warn",
//       summary: "Cancelled",
//       detail: "Delete operation cancelled",
//       life: 3000,
//     });
//   };

//   // Function to show the confirmation dialog
//   const confirmDelete = (name) => {
//     confirmDialog({
//       message: `Are you sure you want to delete the record with ID ${name}?`,
//       header: "Delete Confirmation",
//       icon: "pi pi-info-circle",
//       acceptClassName: "p-button-danger",
//       accept: () => accept(name), // Call accept with the id to delete
//       reject, // Call reject to cancel
//     });
//   };

//   const actionColumn = role === "ADMIN" && (
//     <Column
//       style={{ width: "10%" }}
//       key="actions"
//       header="Actions"
//       body={(rowData) => (
//         <div className="flex flex-row align-items-center justify-content-center gap-2">
//           <Button
//             tooltip="Edit"
//             tooltipOptions={{ position: "bottom" }}
//             icon="pi pi-pencil"
//             rounded
//             text
//             severity="secondary"
//             onClick={() => editHandler(rowData)} // Pass rowData to editHandler
//             aria-label="Edit"
//           />
//           <Button
//             icon="pi pi-trash"
//             rounded
//             text
//             severity="danger"
//             aria-label="Delete"
//             onClick={() => confirmDelete(rowData.name)} // Handle row deletion
//           />
//         </div>
//       )}
//     />
//   );

//   const [expandedRows, setExpandedRows] = useState([]);

//   const header = renderHeader();

//   return (
//     <>
//       <div style={{ marginLeft: "5px", marginBottom: "-25px" }}>
//         <Breadcrumbs />
//       </div>
//       <Toast ref={toast} />
//       <ConfirmDialog />
//       <div className="card">
//         <DataTable
//           value={secrets}
//           rowGroupMode="subheader"
//           header={header}
//           groupRowsBy="name"
//           sortMode="single"
//           sortField="name"
//           filters={filters}
//           filterDisplay="row"
//           loading={loading}
//           rowGroupHeaderTemplate={headerTemplate}
//           sortOrder={1}
//           expandableRowGroups
//           expandedRows={expandedRows}
//           onRowToggle={(e) => {
//             setExpandedRows(e.data);
//           }}
//           tableStyle={{ minWidth: "50rem" }}
//         >
//           <Column
//             field="name"
//             header="Name"
//             style={{ width: "22.5%" }}
//             filter
//             filterPlaceholder="search by name"
//           ></Column>

//           {/* <Column field="type" style={{ width: "16%" }} header="Type"></Column> */}
//           <Column
//             field="dev"
//             header="Dev"
//             style={{ width: "22.5%" }}
//             body={headerDevBodyTemplate}
//           ></Column>
//           <Column
//             field="qa"
//             header="QA"
//             style={{ width: "22.5%" }}
//             body={headerQABodyTemplate}
//           ></Column>
//           <Column
//             field="prod"
//             header="PROD"
//             style={{ width: "22.5%" }}
//             body={headerProdBodyTemplate}
//           ></Column>
//           {/* Directly render actionColumn as a JSX element */}
//           {actionColumn}
//         </DataTable>
//       </div>
//       {selectedViewSecrets === "SFTP secrets" && (
//         <Dialog
//           header="SFTP Secret Details"
//           visible={sftpVisible}
//           style={{ width: "60vw" }} // Adjust the width as needed
//           onHide={() => setSftpVisible(false)}
//           footer={sftpFooterContent}
//         >
//           <div className="flex flex-column align-items-start col-12">
//             <div
//               className="p-field"
//               style={{ width: "100%", marginBottom: "1rem" }}
//             >
//               <label htmlFor="sftpName">Name of Secrets</label>
//               <InputText
//                 id="sftpName"
//                 name="secretName"
//                 onChange={handleSftpInputChange}
//                 required
//                 style={{ width: "100%", fontSize: "1.2em" }}
//                 value={sftpValue.secretName}
//                 disabled
//               />
//             </div>

//             <div
//               className="p-field col-12"
//               style={{
//                 display: "flex",
//                 gap: "2rem",
//                 justifyContent: "space-between",
//               }}
//             >
//               {/* Column 1 for PROD */}
//               <div style={{ flex: "1" }} className="col-4">
//                 <label htmlFor="env">Environment</label>

//                 <InputText
//                   id="env"
//                   name="env"
//                   value={"DEV"}
//                   style={{ width: "100%", fontSize: "1.2em" }}
//                   disabled
//                 />
//                 <label htmlFor="host_dev">SFTP Host</label>
//                 <div></div>
//                 <InputText
//                   id="host_dev"
//                   name="host_dev"
//                   onChange={handleSftpInputChange}
//                   required
//                   style={{ width: "100%", fontSize: "1.2em" }}
//                   value={sftpValue.host_dev}
//                   invalid={sftpError.host_dev}
//                 />
//                 <label htmlFor="sftpPort_dev">Port</label>
//                 <div></div>
//                 <InputText
//                   id="sftpPort_dev"
//                   name="port_dev"
//                   onChange={handleSftpInputChange}
//                   required
//                   style={{ width: "100%", fontSize: "1.2em" }}
//                   value={sftpValue.port_dev}
//                   invalid={sftpError.port_dev}
//                 />
//                 <label htmlFor="sftpUserName_dev">Username</label>
//                 <div></div>
//                 <InputText
//                   id="sftpUserName_dev"
//                   name="username_dev"
//                   onChange={handleSftpInputChange}
//                   required
//                   style={{ width: "100%", fontSize: "1.2em" }}
//                   value={sftpValue.username_dev}
//                   invalid={sftpError.username_dev}
//                 />
//                 <label htmlFor="sftpPassword_dev">Password</label>
//                 <div></div>
//                 <Password
//                   className="w-full"
//                   id="sftpPassword_dev"
//                   name="password_dev"
//                   type="password"
//                   toggleMask
//                   onChange={handleSftpInputChange}
//                   required
//                   value={sftpValue.password_dev}
//                   invalid={sftpError.password_dev}
//                 />
//               </div>

//               {/* Column 2 for DEV */}
//               <div style={{ flex: "1" }} className="col-4">
//                 <label htmlFor="env">Environment</label>

//                 <InputText
//                   id="env"
//                   name="env"
//                   value={"QA"}
//                   style={{ width: "100%", fontSize: "1.2em" }}
//                   disabled
//                 />
//                 <label htmlFor="host_qa">SFTP Host</label>

//                 <InputText
//                   id="host_qa"
//                   name="host_qa"
//                   onChange={handleSftpInputChange}
//                   required
//                   style={{ width: "100%", fontSize: "1.2em" }}
//                   value={sftpValue.host_qa}
//                   invalid={sftpError.host_qa}
//                 />
//                 <label htmlFor="sftpPort_qa">Port</label>

//                 <InputText
//                   id="sftpPort_qa"
//                   name="port_qa"
//                   onChange={handleSftpInputChange}
//                   required
//                   style={{ width: "100%", fontSize: "1.2em" }}
//                   value={sftpValue.port_qa}
//                   invalid={sftpError.port_qa}
//                 />
//                 <label htmlFor="sftpUserName_qa">Username</label>

//                 <InputText
//                   id="sftpUserName_qa"
//                   name="username_qa"
//                   onChange={handleSftpInputChange}
//                   required
//                   style={{ width: "100%", fontSize: "1.2em" }}
//                   value={sftpValue.username_qa}
//                   invalid={sftpError.username_qa}
//                 />
//                 <label htmlFor="sftpPassword_qa">Password</label>

//                 <Password
//                   className="w-full"
//                   id="sftpPassword_qa"
//                   name="password_qa"
//                   type="password"
//                   toggleMask
//                   onChange={handleSftpInputChange}
//                   required
//                   value={sftpValue.password_qa}
//                   invalid={sftpError.password_qa}
//                 />
//               </div>

//               {/* Column 3 for QA */}
//               <div style={{ flex: "1" }} className="col-4">
//                 <label htmlFor="env">Environment</label>
//                 {/* <div>
//                   {renderMandatoryFieldMessage(sftpValue.env, sftpError.env)}
//                 </div> */}
//                 <InputText
//                   id="env"
//                   name="env"
//                   value={"PROD"}
//                   style={{ width: "100%", fontSize: "1.2em" }}
//                   disabled
//                 />
//                 <label htmlFor="host_prod">SFTP Host</label>

//                 <InputText
//                   id="host_prod"
//                   name="host_prod"
//                   onChange={handleSftpInputChange}
//                   required
//                   style={{ width: "100%", fontSize: "1.2em" }}
//                   value={sftpValue.host_prod}
//                   invalid={sftpError.host_prod}
//                 />
//                 <label htmlFor="sftpPort_prod">Port</label>

//                 <InputText
//                   id="sftpPort_prod"
//                   name="port_prod"
//                   onChange={handleSftpInputChange}
//                   required
//                   style={{ width: "100%", fontSize: "1.2em" }}
//                   value={sftpValue.port_prod}
//                   invalid={sftpError.port_prod}
//                 />
//                 <label htmlFor="sftpUserName_prod">Username</label>

//                 <InputText
//                   id="sftpUserName_prod"
//                   name="username_prod"
//                   onChange={handleSftpInputChange}
//                   required
//                   style={{ width: "100%", fontSize: "1.2em" }}
//                   value={sftpValue.username_prod}
//                   invalid={sftpError.username_prod}
//                 />
//                 <label htmlFor="sftpPassword_prod">Password</label>

//                 <Password
//                   className="w-full"
//                   id="sftpPassword_prod"
//                   name="password_prod"
//                   type="password"
//                   toggleMask
//                   onChange={handleSftpInputChange}
//                   required
//                   value={sftpValue.password_prod}
//                   invalid={sftpError.password_prod}
//                 />
//               </div>
//             </div>
//           </div>
//         </Dialog>
//       )}
//       {selectedViewSecrets === "Database secrets" && (
//         <Dialog
//           header="Database Secret Details"
//           visible={dbVisible}
//           style={{ width: "60vw" }} // Adjust the width as needed
//           onHide={() => setDbVisible(false)}
//           footer={dbFooterContent}
//         >
//           {/* <div className="flex flex-column align-items-start"> */}
//           <div className="flex flex-column align-items-start col-12">
//             <div
//               className="p-field"
//               style={{ width: "100%", marginBottom: "1rem" }}
//             >
//               <label htmlFor="dbName">Secret Name</label>

//               <InputText
//                 id="dbName"
//                 name="secretName"
//                 onChange={handleDbInputChange}
//                 required
//                 style={{ width: "100%", fontSize: "1.2em" }}
//                 value={dbValue.secretName}
//                 disabled
//               />
//             </div>

//             <div
//               className="p-field col-12"
//               style={{
//                 display: "flex",
//                 gap: "2rem",
//                 justifyContent: "space-between",
//               }}
//             >
//               {/* Column 1 for PROD */}
//               <div style={{ flex: "1" }} className="col-4">
//                 <label htmlFor="host">Environment</label>
//                 <InputText
//                   id="env"
//                   name="env"
//                   value={"DEV"}
//                   style={{ width: "100%", fontSize: "1.2em" }}
//                   disabled
//                 />
//                 <label htmlFor="dburl_dev">Database URL</label>

//                 <InputText
//                   id="dburl_dev"
//                   name="dburl_dev"
//                   onChange={handleDbInputChange}
//                   required
//                   style={{ width: "100%", fontSize: "1.2em" }}
//                   value={dbValue.dburl_dev}
//                   invalid={dbError.dburl_dev}
//                 />
//                 <label htmlFor="dbUserName_dev">Username</label>

//                 <InputText
//                   id="dbUserName_dev"
//                   name="username_dev"
//                   onChange={handleDbInputChange}
//                   required
//                   style={{ width: "100%", fontSize: "1.2em" }}
//                   value={dbValue.username_dev}
//                   invalid={dbError.username_dev}
//                 />
//                 <label htmlFor="dbPassword_dev">Password</label>

//                 <Password
//                   className="w-full"
//                   id="dbPassword_dev"
//                   name="password_dev"
//                   type="password"
//                   toggleMask
//                   onChange={handleDbInputChange}
//                   required
//                   value={dbValue.password_dev}
//                   invalid={dbError.password_dev}
//                 />
//               </div>
//               <div style={{ flex: "1" }} className="col-4">
//                 <label htmlFor="host">Environment</label>
//                 <InputText
//                   id="env"
//                   name="env"
//                   value={"QA"}
//                   style={{ width: "100%", fontSize: "1.2em" }}
//                   disabled
//                 />
//                 <label htmlFor="dburl_qa">Database URL</label>

//                 <InputText
//                   id="dburl_qa"
//                   name="dburl_qa"
//                   onChange={handleDbInputChange}
//                   required
//                   style={{ width: "100%", fontSize: "1.2em" }}
//                   value={dbValue.dburl_qa}
//                   invalid={dbError.dburl_qa}
//                 />
//                 <label htmlFor="dbUserName_qa">Username</label>

//                 <InputText
//                   id="dbUserName_qa"
//                   name="username_qa"
//                   onChange={handleDbInputChange}
//                   required
//                   style={{ width: "100%", fontSize: "1.2em" }}
//                   value={dbValue.username_qa}
//                   invalid={dbError.username_qa}
//                 />
//                 <label htmlFor="dbPassword_qa">Password</label>

//                 <Password
//                   className="w-full"
//                   id="dbPassword_qa"
//                   name="password_qa"
//                   type="password_qa"
//                   toggleMask
//                   onChange={handleDbInputChange}
//                   required
//                   value={dbValue.password_qa}
//                   invalid={dbError.password_qa}
//                 />
//               </div>
//               <div style={{ flex: "1" }} className="col-4">
//                 <label htmlFor="host">Environment</label>
//                 <InputText
//                   id="env"
//                   name="env"
//                   value={"PROD"}
//                   style={{ width: "100%", fontSize: "1.2em" }}
//                   disabled
//                 />
//                 <label htmlFor="dburl_prod">Database URL</label>

//                 <InputText
//                   id="dburl_prod"
//                   name="dburl_prod"
//                   onChange={handleDbInputChange}
//                   required
//                   style={{ width: "100%", fontSize: "1.2em" }}
//                   value={dbValue.dburl_prod}
//                   invalid={dbError.dburl_prod}
//                 />
//                 <label htmlFor="dbUserName_prod">Username</label>

//                 <InputText
//                   id="dbUserName_prod"
//                   name="username_prod"
//                   onChange={handleDbInputChange}
//                   required
//                   style={{ width: "100%", fontSize: "1.2em" }}
//                   value={dbValue.username_prod}
//                   invalid={dbError.username_prod}
//                 />
//                 <label htmlFor="dbPassword_prod">Password</label>

//                 <Password
//                   className="w-full"
//                   id="dbPassword_prod"
//                   name="password_prod"
//                   type="password_prod"
//                   toggleMask
//                   onChange={handleDbInputChange}
//                   required
//                   value={dbValue.password_prod}
//                   invalid={dbError.password_prod}
//                 />
//               </div>
//             </div>
//           </div>
//         </Dialog>
//       )}
//     </>
//   );
// }

import React, { useState, useEffect, useRef } from "react";
import { FilterMatchMode } from "primereact/api";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { IconField } from "primereact/iconfield";
import { InputIcon } from "primereact/inputicon";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button"; // For action buttons (Edit/Delete)
import Breadcrumbs from "../components/Breadcrumb";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { Password } from "primereact/password";
import { useUserContext } from "../components/contextAPIs/UserContext";
import { handleEncryptedData } from "../Encrypt API/ModifiedEncrypt";
import {
  checkEmptyField,
  checkEmptyFieldForVault,
} from "../common/handleEmptyField";
import {
  deleteSecret,
  getAllSecretsSftpAndDB,
  updateDBSecret,
  updateSecret,
} from "../API Services/VaultSecrets/ViewSecretsService";
import DataTableSkeleton from "./skeleton/DataTableSkeleton";

export default function ViewSecret() {
  const [secrets, setSecrets] = useState([]);
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    host: { value: null, matchMode: FilterMatchMode.CONTAINS },
    port: { value: null, matchMode: FilterMatchMode.EQUALS },
    username: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
  });
  const [loading, setLoading] = useState(true);
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [sftpList, setSftpList] = useState([]);
  const [dbList, setDbList] = useState([]);
  const [selectedViewSecrets, setSelectedViewSecrets] =
    useState("SFTP secrets");
  const toast = useRef(null);
  const { getRole } = useUserContext();
  const role = getRole();

  const [sftpVisible, setSftpVisible] = useState(false);
  const [sftpValue, setSftpValue] = useState({
    secretName: "",
    company: localStorage.getItem("company") || "",
    type: "sftp",
    host_dev: "",
    port_dev: "",
    username_dev: "",
    password_dev: "",
    host_qa: "",
    port_qa: "",
    username_qa: "",
    password_qa: "",
    host_prod: "",
    port_prod: "",
    username_prod: "",
    password_prod: "",
  });
  const [sftpError, setSftpError] = useState({});
  const [dbValue, setDbValue] = useState({
    secretName: "",
    company: localStorage.getItem("company") || "",
    type: "db",
    dburl_dev: "",
    username_dev: "",
    password_dev: "",
    dburl_qa: "",
    username_qa: "",
    password_qa: "",
    dburl_prod: "",
    username_prod: "",
    password_prod: "",
  });
  const [dbError, setDbError] = useState({});
  const [dbVisible, setDbVisible] = useState(false);
  const handleSftpInputChange = (e) => {
    const { name, value } = e.target;
    setSftpValue({
      ...sftpValue,
      [name]: value,
    });
    setSftpError({});
  };
  const handleDbInputChange = (e) => {
    const { name, value } = e.target;
    setDbValue({
      ...dbValue,
      [name]: value,
      name,
    });
    setDbError({});
  };
  const saveHandler = async () => {
    const requestData = {
      name: sftpValue.secretName,
      type: "sftp",
      company: localStorage.getItem("company") || "",
      dev: {
        host: sftpValue.host_dev,
        port: sftpValue.port_dev,
        username: sftpValue.username_dev,
        password: sftpValue.password_dev,
      },
      qa: {
        host: sftpValue.host_qa,
        port: sftpValue.port_qa,
        username: sftpValue.username_qa,
        password: sftpValue.password_qa,
      },
      prod: {
        host: sftpValue.host_prod,
        port: sftpValue.port_prod,
        username: sftpValue.username_prod,
        password: sftpValue.password_prod,
      },
    };

    try {
      let sftpEmptyError = checkEmptyFieldForVault(requestData);
      // console.log("sftpEmptyError", sftpEmptyError);
      if (Object.keys(sftpEmptyError).length !== 0) {
        setSftpError(sftpEmptyError);
        return;
      }
      const encryptedData = await handleEncryptedData(requestData);
      const response = await updateSecret(encryptedData);
      // console.log(response, " update response ");

      if (response.status === 200) {
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "SFTP details updated successfully.",
          life: 3000,
        });

        setSecrets((secrets) =>
          secrets.map((secret) =>
            secret.name === requestData.name ? requestData : secret
          )
        );
        setSftpList((secrets) =>
          secrets.map((secret) =>
            secret.name === requestData.name ? requestData : secret
          )
        );
      }
    } catch (error) {
      const errorMessage = error.response
        ? error.response.data.message
        : "Something went wrong!";
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: errorMessage,
        life: 3000,
      });
    }
    setSftpVisible(false); // Close the dialog after saving
  };

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const viewSecrets = [{ name: "SFTP secrets" }, { name: "Database secrets" }];
  const renderHeader = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
        }}
      >
        <div style={{ flex: 1 }} />
        <h2 style={{ textAlign: "center", flex: 2 }}>{selectedViewSecrets}</h2>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Dropdown
            value={viewSecrets.find((e) => e.name === selectedViewSecrets)}
            onChange={(e) => {
              // console.log(e.value, "value of secrets ");
              setSelectedViewSecrets(e.value.name);
              if (e.value.name === "Database secrets") {
                setSecrets(dbList); // Show DB secrets
              } else {
                setSecrets(sftpList); // Show SFTP secrets
              }
            }}
            options={viewSecrets}
            optionLabel="name"
            placeholder="Select Secret"
            className="p-inputtext-m"
            style={{ width: "13rem", marginRight: "1rem" }}
          />
          <IconField iconPosition="left">
            <InputIcon className="pi pi-search" />
            <InputText
              value={globalFilterValue}
              onChange={onGlobalFilterChange}
              placeholder="Keyword Search"
              className="p-inputtext-m"
            />
          </IconField>
        </div>
      </div>
    );
  };

  const saveDbHandler = async () => {
    const requestData = {
      name: dbValue.secretName,
      type: "db",
      company: localStorage.getItem("company") || "",

      dev: {
        dburl: dbValue.dburl_dev,
        username: dbValue.username_dev,
        password: dbValue.password_dev,
      },
      qa: {
        dburl: dbValue.dburl_qa,
        username: dbValue.username_qa,
        password: dbValue.password_qa,
      },
      prod: {
        dburl: dbValue.dburl_prod,
        username: dbValue.username_prod,
        password: dbValue.password_prod,
      },
    };

    try {
      let emptyFieldError = checkEmptyField(requestData);
      if (Object.keys(emptyFieldError).length !== 0) {
        setDbError(emptyFieldError);
        return;
      }
      const encryptedData = await handleEncryptedData(requestData);
      const response = await updateDBSecret(encryptedData);
      if (response.status === 200) {
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Database details updated successfully.",
          life: 3000,
        });

        setSecrets((secrets) =>
          secrets.map((secret) =>
            secret.name === requestData.name ? requestData : secret
          )
        );

        setDbList((secrets) =>
          secrets.map((secret) =>
            secret.name === requestData.name ? requestData : secret
          )
        );
      }
    } catch (error) {
      const errorMessage = error.response
        ? error.response.data.message
        : "Something went wrong!";
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: errorMessage,
        life: 3000,
      });
    }
    setDbVisible(false);
  };

  const sftpFooterContent = (
    <div>
      <Button
        label="Cancel"
        icon="pi pi-times"
        onClick={() => setSftpVisible(false)}
      />
      <Button label="Update" icon="pi pi-check" onClick={() => saveHandler()} />
    </div>
  );

  const dbFooterContent = (
    <div>
      <Button
        label="Cancel"
        icon="pi pi-times"
        onClick={() => setDbVisible(false)}
      />
      <Button
        label="Update"
        icon="pi pi-check"
        onClick={() => saveDbHandler()}
      />
    </div>
  );

  // fetch sftp and db request .
  async function loadSecretsWithRetry(retries = 3, delay = 2000) {
    try {
      const responsedb = await getAllSecretsSftpAndDB("db");
      return responsedb;
    } catch (error) {
      if (retries > 0) {
        console.warn(`Retrying request... Attempts left: ${retries}`);
        await new Promise((resolve) => setTimeout(resolve, delay));
        return loadSecretsWithRetry(retries - 1, delay);
      } else {
        throw error;
      }
    }
  }
  async function loadSecretsWithRetrySFTP(retries = 3, delay = 2000) {
    try {
      const response = await getAllSecretsSftpAndDB("sftp");
      return response;
    } catch (error) {
      if (retries > 0) {
        console.warn(`Retrying request... Attempts left: ${retries}`);
        await new Promise((resolve) => setTimeout(resolve, delay));
        return loadSecretsWithRetry(retries - 1, delay);
      } else {
        throw error;
      }
    }
  }

  useEffect(() => {
    // setLoading(true);
    async function loadSftpSecrets() {
      try {
        const response = await loadSecretsWithRetrySFTP();
        if (response.status === 200) {
          setSftpList(response.data);
          if (selectedViewSecrets === "SFTP secrets") {
            setSecrets(response.data);
          }
        } else {
          setSftpList([]);
        }
      } catch (error) {
        console.log(error, " faild to load after retry ");
        setSftpList([]);
      } finally {
        setLoading(false);
      }
    }
    loadSftpSecrets();
  }, []);

  useEffect(() => {
    // setLoading(true);

    async function loadSecrets() {
      try {
        const responsedb = await loadSecretsWithRetry();

        if (responsedb.status === 200) {
          setDbList(responsedb.data);
          if (selectedViewSecrets === "Database secrets") {
            setSecrets(responsedb.data);
          }
        } else {
          setDbList([]);
        }
      } catch (error) {
        console.error("Failed to load secrets after retries:", error);
        setDbList([]); // Clear dbList in case of error
      } finally {
        // setLoading(false);
      }
    }

    loadSecrets();
  }, []);

  const headerTemplate = (data) => {
    return (
      <React.Fragment>
        <span className="vertical-align-middle ml-2 font-bold line-height-3">
          {data.name}
        </span>
      </React.Fragment>
    );
  };
  const headerDevBodyTemplate = (data) => {
    return (
      <>
        {selectedViewSecrets === "SFTP secrets" ? (
          <div>
            <p className="vertical-align-middle ml-2  line-height-3">
              {data.dev.host}
            </p>
            <p className="vertical-align-middle ml-2  line-height-3">
              {data.dev.port}
            </p>
            <p className="vertical-align-middle ml-2  line-height-3">
              {data.dev.username}
            </p>
            <Password
              className="vertical-align-middle ml-2  line-height-3"
              value={data.dev.password}
              toggleMask={role === "ADMIN"}
              disabled
            ></Password>
          </div>
        ) : (
          <div>
            <p className="vertical-align-middle ml-2  line-height-3">
              {data.dev.dburl}
            </p>
            <p className="vertical-align-middle ml-2  line-height-3">
              {data.dev.username}
            </p>
            <Password
              className="vertical-align-middle ml-2  line-height-3"
              value={data.dev.password}
              toggleMask={role === "ADMIN"}
              disabled
            ></Password>
          </div>
        )}
      </>
    );
  };
  const headerQABodyTemplate = (data) => {
    return (
      <>
        {selectedViewSecrets === "SFTP secrets" ? (
          <div>
            <p className="vertical-align-middle ml-2  line-height-3">
              {data.qa.host}
            </p>
            <p className="vertical-align-middle ml-2  line-height-3">
              {data.qa.port}
            </p>
            <p className="vertical-align-middle ml-2  line-height-3">
              {data.qa.username}
            </p>
            <Password
              className="vertical-align-middle ml-2  line-height-3"
              value={data.qa.password}
              toggleMask={role === "ADMIN"}
              disabled
            ></Password>
          </div>
        ) : (
          <div>
            <p className="vertical-align-middle ml-2  line-height-3">
              {data.qa.dburl}
            </p>
            <p className="vertical-align-middle ml-2  line-height-3">
              {data.qa.username}
            </p>
            <Password
              className="vertical-align-middle ml-2  line-height-3"
              value={data.qa.password}
              toggleMask={role === "ADMIN"}
              disabled
            ></Password>
          </div>
        )}
      </>
    );
  };
  const headerProdBodyTemplate = (data) => {
    return (
      <>
        {selectedViewSecrets === "SFTP secrets" ? (
          <div>
            <p className="vertical-align-middle ml-2  line-height-3">
              {data.prod.host}
            </p>
            <p className="vertical-align-middle ml-2  line-height-3">
              {data.prod.port}
            </p>
            <p className="vertical-align-middle ml-2  line-height-3">
              {data.prod.username}
            </p>
            <Password
              className="vertical-align-middle ml-2  line-height-3"
              value={data.prod.password}
              toggleMask={role === "ADMIN"}
              disabled
            ></Password>
          </div>
        ) : (
          <div>
            <p className="vertical-align-middle ml-2  line-height-3">
              {data.prod.dburl}
            </p>
            <p className="vertical-align-middle ml-2  line-height-3">
              {data.prod.username}
            </p>
            <Password
              className="vertical-align-middle ml-2  line-height-3"
              value={data.prod.password}
              toggleMask={role === "ADMIN"}
              disabled
            ></Password>
          </div>
        )}
      </>
    );
  };

  const editHandler = (rowData) => {
    // console.log(rowData);
    if (selectedViewSecrets === "SFTP secrets") {
      //         // If "SFTP secrets" is selected, open the SFTP dialog and populate the values
      setSftpValue({
        secretName: rowData.name,
        company: localStorage.getItem("company") || "",
        type: "sftp",

        host_dev: rowData.dev.host,
        port_dev: rowData.dev.port,
        username_dev: rowData.dev.username,
        password_dev: rowData.dev.password,

        host_qa: rowData.qa.host,
        port_qa: rowData.qa.port,
        username_qa: rowData.qa.username,
        password_qa: rowData.qa.password,

        host_prod: rowData.prod.host,
        port_prod: rowData.prod.port,
        username_prod: rowData.prod.username,
        password_prod: rowData.prod.password,
      });
      setSftpVisible(true); // Open SFTP dialog
    } else if (selectedViewSecrets === "Database secrets") {
      // If "Database secrets" is selected, open the Database dialog and populate the values
      // console.log(rowData);
      setDbValue({
        secretName: rowData.name,
        company: localStorage.getItem("company") || "",
        type: "db",

        dburl_dev: rowData.dev.dburl,
        username_dev: rowData.dev.username,
        password_dev: rowData.dev.password,

        dburl_qa: rowData.qa.dburl,
        username_qa: rowData.qa.username,
        password_qa: rowData.qa.password,

        dburl_prod: rowData.prod.dburl,
        username_prod: rowData.prod.username,
        password_prod: rowData.prod.password,
      });

      setDbVisible(true); // Open Database dialog
    }
  };

  const accept = async (name) => {
    // remove secrets by name
    try {
      const response = await deleteSecret(name);
      if (response.status === 200) {
        // console.log(response);
        toast.current.show({
          severity: "success",
          summary: "Deleted",
          detail: `Record with ID ${name} has been deleted.`,
          life: 3000,
        });

        setSecrets((secrets) =>
          secrets.filter((secret) => secret.name != name)
        );

        if (selectedViewSecrets === "SFTP secrets") {
          setSftpList((secrets) =>
            secrets.filter((secret) => secret.name != name)
          );
        } else {
          setDbList((secrets) =>
            secrets.filter((secret) => secret.name != name)
          );
        }

        //
      } else {
        toast.current.show({
          severity: "error",
          summary: "Delete Failed",
          detail: `Failed to delete record with ID ${name}.`,
          life: 3000,
        });
      }
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: `Error occurred: ${error.message}`,
        life: 3000,
      });
    }
  };

  // Function to handle the reject action
  const reject = () => {
    toast.current.show({
      severity: "warn",
      summary: "Cancelled",
      detail: "Delete operation cancelled",
      life: 3000,
    });
  };

  // Function to show the confirmation dialog
  const confirmDelete = (name) => {
    confirmDialog({
      message: `Are you sure you want to delete the record with ID ${name}?`,
      header: "Delete Confirmation",
      icon: "pi pi-info-circle",
      acceptClassName: "p-button-danger",
      accept: () => accept(name), // Call accept with the id to delete
      reject, // Call reject to cancel
    });
  };

  const actionColumn = role === "ADMIN" && (
    <Column
      style={{ width: "10%" }}
      key="actions"
      header="Actions"
      body={(rowData) => (
        <div className="flex flex-row align-items-center justify-content-center gap-2">
          <Button
            tooltip="Edit"
            tooltipOptions={{ position: "bottom" }}
            icon="pi pi-pencil"
            rounded
            text
            severity="secondary"
            onClick={() => editHandler(rowData)} // Pass rowData to editHandler
            aria-label="Edit"
          />
          <Button
            icon="pi pi-trash"
            rounded
            text
            severity="danger"
            aria-label="Delete"
            onClick={() => confirmDelete(rowData.name)} // Handle row deletion
          />
        </div>
      )}
    />
  );

  const [expandedRows, setExpandedRows] = useState([]);

  const header = renderHeader();

  return (
    <div>
      <Breadcrumbs />
      {loading ? (
        <DataTableSkeleton />
      ) : (
        <>
          <Toast ref={toast} />
          <ConfirmDialog />
          <div className="card">
            <DataTable
              value={secrets}
              rowGroupMode="subheader"
              header={header}
              groupRowsBy="name"
              sortMode="single"
              sortField="name"
              filters={filters}
              filterDisplay="row"
              rowGroupHeaderTemplate={headerTemplate}
              sortOrder={1}
              expandableRowGroups
              expandedRows={expandedRows}
              onRowToggle={(e) => {
                setExpandedRows(e.data);
              }}
              tableStyle={{ minWidth: "50rem" }}
            >
              <Column
                field="name"
                header="Name"
                style={{ width: "22.5%" }}
                filter
                filterPlaceholder="search by name"
              ></Column>

              {/* <Column field="type" style={{ width: "16%" }} header="Type"></Column> */}
              <Column
                field="dev"
                header="Dev"
                style={{ width: "22.5%" }}
                body={headerDevBodyTemplate}
              ></Column>
              <Column
                field="qa"
                header="QA"
                style={{ width: "22.5%" }}
                body={headerQABodyTemplate}
              ></Column>
              <Column
                field="prod"
                header="PROD"
                style={{ width: "22.5%" }}
                body={headerProdBodyTemplate}
              ></Column>
              {/* Directly render actionColumn as a JSX element */}
              {actionColumn}
            </DataTable>
          </div>
          {selectedViewSecrets === "SFTP secrets" && (
            <Dialog
              header="SFTP Secret Details"
              visible={sftpVisible}
              style={{ width: "60vw" }} // Adjust the width as needed
              onHide={() => setSftpVisible(false)}
              footer={sftpFooterContent}
            >
              <div className="flex flex-column align-items-start col-12">
                <div
                  className="p-field"
                  style={{ width: "100%", marginBottom: "1rem" }}
                >
                  <label htmlFor="sftpName">Name of Secrets</label>
                  <InputText
                    id="sftpName"
                    name="secretName"
                    onChange={handleSftpInputChange}
                    required
                    style={{ width: "100%", fontSize: "1.2em" }}
                    value={sftpValue.secretName}
                    disabled
                  />
                </div>

                <div
                  className="p-field col-12"
                  style={{
                    display: "flex",
                    gap: "2rem",
                    justifyContent: "space-between",
                  }}
                >
                  {/* Column 1 for PROD */}
                  <div style={{ flex: "1" }} className="col-4">
                    <label htmlFor="env">Environment</label>

                    <InputText
                      id="env"
                      name="env"
                      value={"DEV"}
                      style={{ width: "100%", fontSize: "1.2em" }}
                      disabled
                    />
                    <label htmlFor="host_dev">SFTP Host</label>
                    <div></div>
                    <InputText
                      id="host_dev"
                      name="host_dev"
                      onChange={handleSftpInputChange}
                      required
                      style={{ width: "100%", fontSize: "1.2em" }}
                      value={sftpValue.host_dev}
                      invalid={sftpError.host_dev}
                    />
                    <label htmlFor="sftpPort_dev">Port</label>
                    <div></div>
                    <InputText
                      id="sftpPort_dev"
                      name="port_dev"
                      onChange={handleSftpInputChange}
                      required
                      style={{ width: "100%", fontSize: "1.2em" }}
                      value={sftpValue.port_dev}
                      invalid={sftpError.port_dev}
                    />
                    <label htmlFor="sftpUserName_dev">Username</label>
                    <div></div>
                    <InputText
                      id="sftpUserName_dev"
                      name="username_dev"
                      onChange={handleSftpInputChange}
                      required
                      style={{ width: "100%", fontSize: "1.2em" }}
                      value={sftpValue.username_dev}
                      invalid={sftpError.username_dev}
                    />
                    <label htmlFor="sftpPassword_dev">Password</label>
                    <div></div>
                    <Password
                      className="w-full"
                      id="sftpPassword_dev"
                      name="password_dev"
                      type="password"
                      toggleMask
                      onChange={handleSftpInputChange}
                      required
                      value={sftpValue.password_dev}
                      invalid={sftpError.password_dev}
                    />
                  </div>

                  {/* Column 2 for DEV */}
                  <div style={{ flex: "1" }} className="col-4">
                    <label htmlFor="env">Environment</label>

                    <InputText
                      id="env"
                      name="env"
                      value={"QA"}
                      style={{ width: "100%", fontSize: "1.2em" }}
                      disabled
                    />
                    <label htmlFor="host_qa">SFTP Host</label>

                    <InputText
                      id="host_qa"
                      name="host_qa"
                      onChange={handleSftpInputChange}
                      required
                      style={{ width: "100%", fontSize: "1.2em" }}
                      value={sftpValue.host_qa}
                      invalid={sftpError.host_qa}
                    />
                    <label htmlFor="sftpPort_qa">Port</label>

                    <InputText
                      id="sftpPort_qa"
                      name="port_qa"
                      onChange={handleSftpInputChange}
                      required
                      style={{ width: "100%", fontSize: "1.2em" }}
                      value={sftpValue.port_qa}
                      invalid={sftpError.port_qa}
                    />
                    <label htmlFor="sftpUserName_qa">Username</label>

                    <InputText
                      id="sftpUserName_qa"
                      name="username_qa"
                      onChange={handleSftpInputChange}
                      required
                      style={{ width: "100%", fontSize: "1.2em" }}
                      value={sftpValue.username_qa}
                      invalid={sftpError.username_qa}
                    />
                    <label htmlFor="sftpPassword_qa">Password</label>

                    <Password
                      className="w-full"
                      id="sftpPassword_qa"
                      name="password_qa"
                      type="password"
                      toggleMask
                      onChange={handleSftpInputChange}
                      required
                      value={sftpValue.password_qa}
                      invalid={sftpError.password_qa}
                    />
                  </div>

                  {/* Column 3 for QA */}
                  <div style={{ flex: "1" }} className="col-4">
                    <label htmlFor="env">Environment</label>
                    {/* <div>
                  {renderMandatoryFieldMessage(sftpValue.env, sftpError.env)}
                </div> */}
                    <InputText
                      id="env"
                      name="env"
                      value={"PROD"}
                      style={{ width: "100%", fontSize: "1.2em" }}
                      disabled
                    />
                    <label htmlFor="host_prod">SFTP Host</label>

                    <InputText
                      id="host_prod"
                      name="host_prod"
                      onChange={handleSftpInputChange}
                      required
                      style={{ width: "100%", fontSize: "1.2em" }}
                      value={sftpValue.host_prod}
                      invalid={sftpError.host_prod}
                    />
                    <label htmlFor="sftpPort_prod">Port</label>

                    <InputText
                      id="sftpPort_prod"
                      name="port_prod"
                      onChange={handleSftpInputChange}
                      required
                      style={{ width: "100%", fontSize: "1.2em" }}
                      value={sftpValue.port_prod}
                      invalid={sftpError.port_prod}
                    />
                    <label htmlFor="sftpUserName_prod">Username</label>

                    <InputText
                      id="sftpUserName_prod"
                      name="username_prod"
                      onChange={handleSftpInputChange}
                      required
                      style={{ width: "100%", fontSize: "1.2em" }}
                      value={sftpValue.username_prod}
                      invalid={sftpError.username_prod}
                    />
                    <label htmlFor="sftpPassword_prod">Password</label>

                    <Password
                      className="w-full"
                      id="sftpPassword_prod"
                      name="password_prod"
                      type="password"
                      toggleMask
                      onChange={handleSftpInputChange}
                      required
                      value={sftpValue.password_prod}
                      invalid={sftpError.password_prod}
                    />
                  </div>
                </div>
              </div>
            </Dialog>
          )}
          {selectedViewSecrets === "Database secrets" && (
            <Dialog
              header="Database Secret Details"
              visible={dbVisible}
              style={{ width: "60vw" }} // Adjust the width as needed
              onHide={() => setDbVisible(false)}
              footer={dbFooterContent}
            >
              {/* <div className="flex flex-column align-items-start"> */}
              <div className="flex flex-column align-items-start col-12">
                <div
                  className="p-field"
                  style={{ width: "100%", marginBottom: "1rem" }}
                >
                  <label htmlFor="dbName">Secret Name</label>

                  <InputText
                    id="dbName"
                    name="secretName"
                    onChange={handleDbInputChange}
                    required
                    style={{ width: "100%", fontSize: "1.2em" }}
                    value={dbValue.secretName}
                    disabled
                  />
                </div>

                <div
                  className="p-field col-12"
                  style={{
                    display: "flex",
                    gap: "2rem",
                    justifyContent: "space-between",
                  }}
                >
                  {/* Column 1 for PROD */}
                  <div style={{ flex: "1" }} className="col-4">
                    <label htmlFor="host">Environment</label>
                    <InputText
                      id="env"
                      name="env"
                      value={"DEV"}
                      style={{ width: "100%", fontSize: "1.2em" }}
                      disabled
                    />
                    <label htmlFor="dburl_dev">Database URL</label>

                    <InputText
                      id="dburl_dev"
                      name="dburl_dev"
                      onChange={handleDbInputChange}
                      required
                      style={{ width: "100%", fontSize: "1.2em" }}
                      value={dbValue.dburl_dev}
                      invalid={dbError.dburl_dev}
                    />
                    <label htmlFor="dbUserName_dev">Username</label>

                    <InputText
                      id="dbUserName_dev"
                      name="username_dev"
                      onChange={handleDbInputChange}
                      required
                      style={{ width: "100%", fontSize: "1.2em" }}
                      value={dbValue.username_dev}
                      invalid={dbError.username_dev}
                    />
                    <label htmlFor="dbPassword_dev">Password</label>

                    <Password
                      className="w-full"
                      id="dbPassword_dev"
                      name="password_dev"
                      type="password"
                      toggleMask
                      onChange={handleDbInputChange}
                      required
                      value={dbValue.password_dev}
                      invalid={dbError.password_dev}
                    />
                  </div>
                  <div style={{ flex: "1" }} className="col-4">
                    <label htmlFor="host">Environment</label>
                    <InputText
                      id="env"
                      name="env"
                      value={"QA"}
                      style={{ width: "100%", fontSize: "1.2em" }}
                      disabled
                    />
                    <label htmlFor="dburl_qa">Database URL</label>

                    <InputText
                      id="dburl_qa"
                      name="dburl_qa"
                      onChange={handleDbInputChange}
                      required
                      style={{ width: "100%", fontSize: "1.2em" }}
                      value={dbValue.dburl_qa}
                      invalid={dbError.dburl_qa}
                    />
                    <label htmlFor="dbUserName_qa">Username</label>

                    <InputText
                      id="dbUserName_qa"
                      name="username_qa"
                      onChange={handleDbInputChange}
                      required
                      style={{ width: "100%", fontSize: "1.2em" }}
                      value={dbValue.username_qa}
                      invalid={dbError.username_qa}
                    />
                    <label htmlFor="dbPassword_qa">Password</label>

                    <Password
                      className="w-full"
                      id="dbPassword_qa"
                      name="password_qa"
                      type="password_qa"
                      toggleMask
                      onChange={handleDbInputChange}
                      required
                      value={dbValue.password_qa}
                      invalid={dbError.password_qa}
                    />
                  </div>
                  <div style={{ flex: "1" }} className="col-4">
                    <label htmlFor="host">Environment</label>
                    <InputText
                      id="env"
                      name="env"
                      value={"PROD"}
                      style={{ width: "100%", fontSize: "1.2em" }}
                      disabled
                    />
                    <label htmlFor="dburl_prod">Database URL</label>

                    <InputText
                      id="dburl_prod"
                      name="dburl_prod"
                      onChange={handleDbInputChange}
                      required
                      style={{ width: "100%", fontSize: "1.2em" }}
                      value={dbValue.dburl_prod}
                      invalid={dbError.dburl_prod}
                    />
                    <label htmlFor="dbUserName_prod">Username</label>

                    <InputText
                      id="dbUserName_prod"
                      name="username_prod"
                      onChange={handleDbInputChange}
                      required
                      style={{ width: "100%", fontSize: "1.2em" }}
                      value={dbValue.username_prod}
                      invalid={dbError.username_prod}
                    />
                    <label htmlFor="dbPassword_prod">Password</label>

                    <Password
                      className="w-full"
                      id="dbPassword_prod"
                      name="password_prod"
                      type="password_prod"
                      toggleMask
                      onChange={handleDbInputChange}
                      required
                      value={dbValue.password_prod}
                      invalid={dbError.password_prod}
                    />
                  </div>
                </div>
              </div>
            </Dialog>
          )}
        </>
      )}
    </div>
  );
}
