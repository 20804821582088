import React from "react";
import { Skeleton } from "primereact/skeleton";

const CreateJobSkeleton = () => {
  return (
    <div className="flex flex-column align-items-start justify-content-start">
      <form className="w-12 sm:w-8 m-auto p-3">
        <div className="grid">
          {/* Job Name Skeleton */}
          <div className="col-12">
            <Skeleton width="200px" height="2rem" className="mb-3" />
          </div>

          {/* Source Section Skeleton */}
          <div className="col-12 sm:col-4">
            <div className="grid w-full">
              <div className="col-12">
                <Skeleton width="150px" height="1.5rem" className="mb-3" />
              </div>
              <div className="col-12">
                <Skeleton width="100%" height="2.5rem" className="mb-3" />
              </div>
              <div className="col-12">
                <Skeleton width="100%" height="2.5rem" className="mb-3" />
              </div>
              <div className="col-12">
                <Skeleton width="100%" height="2.5rem" className="mb-3" />
              </div>
              <div className="col-12">
                <Skeleton width="100%" height="2.5rem" className="mb-3" />
              </div>
              <div className="col-12">
                <Skeleton width="100%" height="2.5rem" className="mb-3" />
              </div>
            </div>
          </div>

          {/* Spacer Skeleton */}
          <div className="col-2"></div>

          {/* Target Section Skeleton */}
          <div className="col-12 sm:col-4">
            <div className="grid w-full">
              <div className="col-12">
                <Skeleton width="150px" height="1.5rem" className="mb-3" />
              </div>
              <div className="col-12">
                <Skeleton width="100%" height="2.5rem" className="mb-3" />
              </div>
              <div className="col-12">
                <Skeleton width="100%" height="2.5rem" className="mb-3" />
              </div>
              <div className="col-12">
                <Skeleton width="100%" height="2.5rem" className="mb-3" />
              </div>
              <div className="col-12">
                <Skeleton width="100%" height="2.5rem" className="mb-3" />
              </div>
            </div>
          </div>

          {/* Schedule Button Skeleton */}
          <div className="col-12">
            <Skeleton width="150px" height="3rem" className="mb-3" />
          </div>
        </div>
      </form>
    </div>
  );
};

export default CreateJobSkeleton;
