import React from "react";
import { Skeleton } from "primereact/skeleton";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "../UserList.css";

export default function UserTableSkeleton({ rows = 5 }) {
  const items = Array.from({ length: rows }, (_, i) => i);

  return (
    <div className="card">
      {/* Skeleton for Header Section */}
      <div className="current-view flex justify-content-end align-items-center mb-3">
        {/* <div width="2rem" /></div> */}
        <div className="flex align-items-center gap-2">
          <Skeleton width="12rem" height="2rem" />
        </div>
      </div>

      {/* Skeleton for Table */}
      <DataTable value={items}>
        <Column field="First Name" header="First Name" body={<Skeleton />} />
        <Column field="Last Name" header="Last Name" body={<Skeleton />} />
        <Column field="Email" header="Email" body={<Skeleton />} />
        <Column field="Roles" header="Roles" body={<Skeleton />} />
        <Column field="Action" header="Action" body={<Skeleton />} />
      </DataTable>
    </div>
  );
}
