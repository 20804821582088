import React from "react";
import { Skeleton } from "primereact/skeleton";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

export default function DataTableSkeleton() {
  // Create dummy data for the skeleton (5 rows)
  const items = Array.from({ length: 5 }, (v, i) => i);

  // Skeleton version of the header
  const skeletonHeader = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          marginTop: "2rem",
        }}
      >
        {/* Left placeholder */}
        <div style={{ flex: 1 }}>
          <Skeleton width="100%" height="1.5rem" />
        </div>

        {/* Center placeholder for the title */}
        <div style={{ flex: 2, textAlign: "center" }}>
          <Skeleton width="50%" height="1.5rem" />
        </div>

        {/* Right placeholder for dropdown and search */}
        <div style={{ display: "flex", alignItems: "center", gap: "1rem" }}>
          <Skeleton width="13rem" height="2.5rem" />{" "}
          {/* Dropdown placeholder */}
          <Skeleton width="15rem" height="2.5rem" /> {/* Search placeholder */}
        </div>
      </div>
    );
  };

  // Skeleton version of the row group header template
  const skeletonRowGroupHeaderTemplate = (data) => {
    return (
      <React.Fragment>
        <span className="vertical-align-middle ml-2 font-bold line-height-3">
          <Skeleton width="100px" height="1.2rem" />{" "}
          {/* Placeholder for group header */}
        </span>
      </React.Fragment>
    );
  };

  return (
    <div className="card">
      {/* Render the skeleton header */}
      {skeletonHeader()}

      {/* Skeleton DataTable */}
      <DataTable
        value={items}
        rowGroupMode="subheader"
        sortMode="single"
        sortField="name"
        tableStyle={{ minWidth: "50rem" }}
      >
        {/* Name Column */}
        <Column
          field="name"
          header="Name"
          style={{ width: "22.5%" }}
          body={<Skeleton />} // Skeleton placeholder
        ></Column>

        {/* Dev Column */}
        <Column
          field="dev"
          header="Dev"
          style={{ width: "22.5%" }}
          body={<Skeleton />} // Skeleton placeholder
        ></Column>

        {/* QA Column */}
        <Column
          field="qa"
          header="QA"
          style={{ width: "22.5%" }}
          body={<Skeleton />} // Skeleton placeholder
        ></Column>

        {/* Prod Column */}
        <Column
          field="prod"
          header="PROD"
          style={{ width: "22.5%" }}
          body={<Skeleton />} // Skeleton placeholder
        ></Column>

        {/* Action Column (Optional) */}
        <Column
          header="Actions"
          style={{ width: "10%" }}
          body={<Skeleton />} // Skeleton placeholder
        ></Column>
      </DataTable>
    </div>
  );
}
