import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Password } from "primereact/password";
import { InputTextarea } from "primereact/inputtextarea";
import Breadcrumbs from "../components/Breadcrumb";
import { useUserContext } from "../components/contextAPIs/UserContext";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { FloatLabel } from "primereact/floatlabel";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import {
  deletePgpService,
  fetchPgpService,
  updatePgpService,
} from "../API Services/VaultSecrets/pgpTableService";
import ViewPGPTableSkeleton from "./skeleton/ViewPGPTableSkeleton";

export default function PgpSecretKeyTable() {
  const toast = useRef(null);

  const [pgpVisible, setPgpVisible] = useState(false);
  const [loading, setLoading] = useState(true);
  const [pgpSecrets, setPgpSecrets] = useState([]);
  const [expandedRows, setExpandedRows] = useState([]);
  const [pgpError, setPgpError] = useState([]);

  const { getRole } = useUserContext();
  const role = getRole();
  const company = localStorage.getItem("company");
  const type = "pgp";

  //   console.log(pgpSecrets);
  const showToast = (severity, summary, detail) => {
    toast.current.show({ severity, summary, detail, life: 3000 });
  };
  const [pgpValue, setPgpValue] = useState({
    name: "",
    company: localStorage.getItem("company") || "",
    type: "pgp",
    publicKey: "",
    privateKey: "",
    passphrase: "",
  });

  const handlePgpInputChange = (e) => {
    const { name, value } = e.target;
    setPgpValue((prev) => ({ ...prev, [name]: value }));
    setPgpError([]);
  };

  useEffect(() => {
    setLoading(true);
    async function getAllPGPSecrets() {
      // const axiosInstance = await myAxios();
      // const response = await axiosInstance
      //   .get(`/pgp/secret/get/pgp/all?company=${company}&type=${type}`)
      //   .then((resp) => resp);
      const response = await fetchPgpService(company, type);
      if (response.status === 200) {
        setPgpSecrets(response.data);
        setLoading(false);
      }
    }
    getAllPGPSecrets();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const headerTemplate = (data) => {
    return (
      <React.Fragment>
        <span className="vertical-align-middle ml-2 font-bold line-height-3">
          {data.name}
        </span>
      </React.Fragment>
    );
  };

  const createSecretValue = (data, field) => {
    const secret = data.secret;
    return (
      <>
        {field === "passphrase" ? (
          <Password
            value={secret[field]}
            // onChange={(e) => setValue(e.target.value)}
            toggleMask
          />
        ) : (
          <div className="row">
            <InputTextarea rows={6} cols={40} value={secret[field]} />
          </div>
        )}
      </>
    );
  };

  const editHandler = (pgpSecrets) => {
    setPgpValue({
      name: pgpSecrets.name,
      company: localStorage.getItem("company") || "",
      type: "pgp",
      publicKey: pgpSecrets.secret.publicKey,
      privateKey: pgpSecrets.secret.privateKey,
      passphrase: pgpSecrets.secret.passphrase,
    });
    setPgpVisible(true); // Open SFTP dialog
  };

  const confirmDelete = (name) => {
    // console.log(name);
    confirmDialog({
      message: `Are you sure you want to delete the record with ID ${name}?`,
      header: "Delete Confirmation",
      icon: "pi pi-info-circle",
      acceptClassName: "p-button-danger",
      accept: () => accept(name), // Call accept with the id to delete
      reject, // Call reject to cancel
    });
    // console.log(name);
  };

  const accept = async (name) => {
    try {
      // const response = await deleteSecret(name);
      // const axiosInstance = await myAxios();
      // const response = await axiosInstance.delete(
      //   `/pgp/secret/delete?secretName=${name}`
      // );
      //   console.log(response);
      const response = await deletePgpService(name);
      if (response.status === 200) {
        setPgpSecrets((secrets) =>
          secrets.filter((secret) => secret.name !== name)
        );
        // console.log(response);
        toast.current.show({
          severity: "success",
          summary: "Deleted",
          detail: `Record with ID ${name} has been deleted.`,
          life: 3000,
        });

        // setTimeout(() => {
        //   window.location.reload(); // Reloads the page
        // }, 1000);
      } else {
        toast.current.show({
          severity: "error",
          summary: "Delete Failed",
          detail: `Failed to delete record with ID ${name}.`,
          life: 3000,
        });
      }
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: `Error occurred: ${error.message}`,
        life: 3000,
      });
    }
  };

  // Function to handle the reject action
  const reject = () => {
    toast.current.show({
      severity: "warn",
      summary: "Cancelled",
      detail: "Delete operation cancelled",
      life: 3000,
    });
  };

  const actionColumn = role === "ADMIN" && (
    <Column
      style={{ width: "10%" }}
      key="actions"
      header="Actions"
      body={(pgpSecrets) => (
        <div className="flex flex-row align-items-center justify-content-center gap-2">
          <Button
            tooltip="Edit"
            tooltipOptions={{ position: "bottom" }}
            icon="pi pi-pencil"
            rounded
            text
            severity="secondary"
            onClick={() => editHandler(pgpSecrets)} // Pass rowData to editHandler
            aria-label="Edit"
          />
          <Button
            icon="pi pi-trash"
            rounded
            text
            severity="danger"
            aria-label="Delete"
            onClick={() => confirmDelete(pgpSecrets.name)} // Handle row deletion
          />
        </div>
      )}
    />
  );

  const handlePgpUpdate = async (e) => {
    e.preventDefault();
    // console.log(pgpValue, " is the pgp value ");

    const pgpData = {
      name: pgpValue.name,
      company: localStorage.getItem("company") || "",
      type: "pgp",
      secret: {
        publicKey: pgpValue.publicKey,
        privateKey: pgpValue.privateKey,
        passphrase: pgpValue.passphrase,
      },
    };

    // console.log("data prepared for the pgp creation", pgpData);

    const errors = Object.entries(pgpValue)
      .filter(([key, value]) => value === "") // Filter keys where value is an empty string
      .map(([key, value]) => key);

    setPgpError(errors);
    // console.log(errors, " is the error ");
    if (errors.length > 0) {
      showToast("error", "error", "please fill the all details ");
      return;
    }
    // const axiosInstance = await myAxios();
    // const response = await axiosInstance.put("/pgp/secret/update", pgpData);
    const response = await updatePgpService(pgpData);
    // console.log(response);
    if (response.status === 204) {
      setPgpSecrets((secrets) =>
        secrets.map((secret) =>
          secret.name === pgpData.name ? pgpData : secret
        )
      );
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: "PGP details updated successfully.",
        life: 3000,
      });
    } else if (response.status === 401) {
      toast.current.show({
        severity: "warn",
        summary: "Waring",
        detail: "Unauthorised Access, Please login again",
        life: 3000,
      });
    } else if (response.status === 500) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Internal Server Error",
        life: 3000,
      });
    }
    setPgpVisible(false);
    // setTimeout(() => {
    //   window.location.reload(); // Reloads the page
    // }, 1000);
  };

  const pgpFooterContent = (
    <div>
      <Button
        label="No"
        icon="pi pi-times"
        onClick={() => setPgpVisible(false)}
        className="p-button-text"
      />
      <Button
        label="Update"
        icon="pi pi-check"
        onClick={handlePgpUpdate}
        autoFocus
      />
    </div>
  );

  return (
    <>
      <Toast ref={toast} />
      <ConfirmDialog />

      <Breadcrumbs />

      <div className="card">
        {loading ? (
          <ViewPGPTableSkeleton />
        ) : (
          <DataTable
            value={pgpSecrets}
            rowGroupMode="subheader"
            groupRowsBy="name"
            sortMode="single"
            sortField="name"
            sortOrder={1}
            // loading={loading}
            expandableRowGroups
            expandedRows={expandedRows}
            onRowToggle={(e) => setExpandedRows(e.data)}
            rowGroupHeaderTemplate={headerTemplate}
            tableStyle={{ minWidth: "50rem" }}
          >
            <Column field="name" header="Name" style={{ width: "20%" }} />

            <Column
              field="publicKey"
              header="PublicKey"
              style={{ width: "20%" }}
              body={(data) => createSecretValue(data, "publicKey")}
              disabled
            />
            <Column
              field="privateKey"
              header="PrivateKey"
              style={{ width: "20%" }}
              body={(data) => createSecretValue(data, "privateKey")}
              disabled
            />
            <Column
              field="passphrase"
              header="Password"
              style={{ width: "20%" }}
              body={(data) => createSecretValue(data, "passphrase")}
              disabled
            />
            {actionColumn}
          </DataTable>
        )}

        <Dialog
          header="PGP Secret Details"
          visible={pgpVisible}
          style={{ width: "60vw" }} // Adjust the width as needed
          onHide={() => setPgpVisible(false)}
          footer={pgpFooterContent}
        >
          {/* <div className="flex flex-column align-items-start"> */}
          <div className="flex flex-column align-items-start col-12">
            <div
              className="p-field col-12"
              style={{
                display: "flex",
                gap: "2rem",
                justifyContent: "space-between",
              }}
            >
              {/* Column 1 for pgp secrets */}
              <div style={{ flex: "1" }} className="col-4">
                <label style={{ visibility: "hidden" }}>Secret Name </label>
                <div className="my-2 ">
                  <FloatLabel>
                    <label htmlFor="pgpname">Secret Name</label>
                    <InputText
                      id="pgpname"
                      className="w-full"
                      name="name"
                      placeholder="Enter Pgp Secret Name "
                      value={pgpValue.name}
                      onChange={handlePgpInputChange}
                      required
                      invalid={pgpError.includes("name")}
                      disabled
                    />
                  </FloatLabel>
                </div>

                <div className="my-4  ">
                  <FloatLabel>
                    <label htmlFor="pgpPublickey">Public Key :</label>
                    <InputTextarea
                      value={pgpValue.publicKey}
                      name="publicKey"
                      id="pgpPublickey"
                      cols={40}
                      rows={15}
                      placeholder="Enter pgp public key here"
                      onChange={handlePgpInputChange}
                      required
                      invalid={pgpError.includes("publicKey")}
                    />
                  </FloatLabel>
                </div>
              </div>

              {/* Column 2 for the pgp secret */}
              <div style={{ flex: "1" }} className="col-4">
                <div className="my-2">
                  <label htmlFor="passphrase">Password</label>
                  {/* <FloatLabel> */}
                  <Password
                    className="w-full"
                    id="passphrase"
                    name="passphrase"
                    type="password"
                    placeholder="Enter Password"
                    toggleMask
                    value={pgpValue.passphrase}
                    onChange={handlePgpInputChange}
                    required
                    invalid={pgpError.includes("passphrase")}
                  />
                  {/* </FloatLabel> */}
                </div>
                <div className="my-4  ">
                  <FloatLabel>
                    <label htmlFor="pgpPrivatekey">Private Key :</label>
                    <InputTextarea
                      value={pgpValue.privateKey}
                      name="privateKey"
                      id="pgpPrivateckey"
                      cols={40}
                      rows={15}
                      placeholder="Enter pgp private key here"
                      onChange={handlePgpInputChange}
                      required
                      invalid={pgpError.includes("privateKey")}
                    />
                  </FloatLabel>
                </div>
              </div>
            </div>
          </div>
        </Dialog>
      </div>
    </>
  );
}
