////////////////////////this is dynamic code ///////////////////////

import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { FilterMatchMode } from "primereact/api";
import Breadcrumbs from "../Breadcrumb";
import { fetchFilesService } from "../../API Services/HistoryServices/FileDetailsService";
import FileViewSkeleton from "./skeleton/FileViewSkeleton";
//excel
import { TabView, TabPanel } from "primereact/tabview";

export default function FileDetails() {
  const location = useLocation();
  const { fileForm, viewRole } = location.state || {};
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage] = useState(200); // Number of records per page
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });
  const [loading, setLoading] = useState(false); // Loading state

  //excel things
  const [activeIndex, setActiveIndex] = useState(0); // Tracks the active tab
  const [resetKey, setResetKey] = useState(0);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     if (!fileForm || !fileForm.archivePath || !fileForm.ruleId) {
  //       console.error("Missing fileForm data");
  //       return;
  //     }

  //     setLoading(true); // Start loading

  //     try {
  //       const response = await fetchFilesService(viewRole, fileForm);
  //       const data = response.data;

  //       // console.log("in file reading ");

  //       if (data.data && data.data.length > 0) {
  //         const columnNames = Object.keys(data.data[0]);
  //         setColumns(columnNames);
  //         // Initialize filters for each column
  //         const initialFilters = columnNames.reduce((acc, col) => {
  //           acc[col] = { value: null, matchMode: FilterMatchMode.CONTAINS };
  //           return acc;
  //         }, {});
  //         setFilters((prevFilters) => ({
  //           ...prevFilters,
  //           ...initialFilters,
  //         }));
  //         setData(data.data); // Ensure to set the correct data
  //       } else {
  //         setData([]); // Set empty data if no data returned
  //       }
  //     } catch (error) {
  //       console.error("Error fetching data:", error);
  //       setData([]); // Set empty data on error
  //     } finally {
  //       setLoading(false); // End loading
  //     }
  //   };

  //   fetchData();
  // }, [fileForm]); // Re-run effect when fileForm changes

  //excel use effect changes

  useEffect(() => {
    const fetchData = async () => {
      if (!fileForm || !fileForm.archivePath || !fileForm.ruleId) {
        console.error("Missing fileForm data");
        return;
      }

      setLoading(true); // Start loading
      try {
        const response = await fetchFilesService(viewRole, fileForm);
        console.log(response, "response");
        const resultData = response.data;

        if (resultData.data?.length > 0) {
          setData(resultData.data);

          const initialSheetData = resultData.data?.[0]?.data || [];
          const initialColumns = initialSheetData.length
            ? [
                ...new Set(
                  initialSheetData.flatMap((obj) => Object.keys(obj || {}))
                ),
              ]
            : Object.keys(resultData.data?.[0] || {});

          setColumns(initialColumns);

          const initialFilters = initialColumns.reduce((acc, col) => {
            acc[col] = { value: null, matchMode: FilterMatchMode.CONTAINS };
            return acc;
          }, {});

          setFilters((prevFilters) => ({
            ...prevFilters,
            ...initialFilters,
          }));
        } else {
          setData([]);
          setColumns([]); // Set empty data if no data returned
          console.log("noo data");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setData([]); // Set empty data on error
      } finally {
        setLoading(false); // End loading
      }
    };

    fetchData();
  }, [fileForm]); // Re-run effect when fileForm changes

  //console.log(data,"data")

  useEffect(() => {
    if (data.length > 0 && data[0].data) {
      const activeSheetData = data[activeIndex]?.data || [];
      const activeColumns = [
        ...new Set(activeSheetData.flatMap((obj) => Object.keys(obj))),
      ];

      setColumns(activeColumns);

      const updatedFilters = activeColumns.reduce((acc, col) => {
        acc[col] = { value: null, matchMode: FilterMatchMode.CONTAINS };
        return acc;
      }, {});

      setFilters(updatedFilters); // Update reset key
    }
  }, [activeIndex, data]);
  //excel ends here

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    setFilters({
      ...filters,
      global: { value, matchMode: FilterMatchMode.CONTAINS },
    });
    setGlobalFilterValue(value);
  };

  // const renderHeader = () => {
  //   return (
  //     <div className="flex justify-content-end">
  //       <InputText
  //         value={globalFilterValue}
  //         onChange={onGlobalFilterChange}
  //         placeholder="Keyword Search"
  //         style={{ width: "15rem" }} // Match the style from the old code
  //       />
  //     </div>
  //   );
  // };

  return (
    <>
      {/* <Breadcrumbs /> */}
      {/* excel code */}
      <Breadcrumbs />
      <div className="flex align-items-center justify-content-between m-3">
  
        <InputText
          value={globalFilterValue}
          onChange={onGlobalFilterChange}
          placeholder="Keyword Search"
          style={{ width: "15rem" }}
        />
      </div>

      {loading ? (
        <FileViewSkeleton />
      ) : (
        // <div className="d-flex">
        //   <DataTable
        //     value={data}
        //     paginator
        //     rows={rowsPerPage}
        //     first={page * rowsPerPage}
        //     onPage={(event) => setPage(event.page)}
        //     filters={filters}
        //     globalFilterFields={columns}
        //     header={renderHeader()}
        //     tableStyle={{ minWidth: "50rem" }}
        //   >
        //     {columns.map((col) => (
        //       <Column
        //         key={col}
        //         field={col}
        //         header={col}
        //         filter
        //         filterPlaceholder={`Search by ${col}`}
        //         style={{ width: "20%" }}
        //       />
        //     ))}
        //   </DataTable>
        // </div>
        // excel code added here
        <>
          <TabView
            activeIndex={activeIndex}
            onTabChange={(e) => setActiveIndex(e.index)}
          >
            {Array.isArray(data) && data.length > 0 ? ( // Check if data is an array
              Array.isArray(data[0]?.data) ? ( // If first item has 'data', it as an Excel file
                data.map((sheet, index) => (
                  <TabPanel key={index} header={sheet.sheetName}>
                    <DataTable
                      key={resetKey}
                      value={sheet.data}
                      paginator
                      rows={rowsPerPage}
                      first={page * rowsPerPage}
                      onPage={(event) => setPage(event.page)}
                      filters={filters}
                      globalFilterFields={columns}
                      //tableStyle={{ minWidth: "50rem" }}
                    >
                      {columns.map((col, i) => (
                        <Column
                          key={i}
                          field={col}
                          header={col}
                          filter
                          filterPlaceholder={`Search by ${col}`}
                          style={{ overflowX: "scroll" }}
                        />
                      ))}
                    </DataTable>
                  </TabPanel>
                ))
              ) : (
                // If data is just an array of objects (CSV case), directly display it
                <TabPanel key="csv" header="CSV Data">
                  <DataTable
                    key={resetKey}
                    value={data} // Directly use data for CSV
                    paginator
                    rows={rowsPerPage}
                    first={page * rowsPerPage}
                    onPage={(event) => setPage(event.page)}
                    filters={filters}
                    globalFilterFields={columns}
                    tableStyle={{ minWidth: "50rem" }}
                  >
                    {columns.map((col, i) => (
                      <Column
                        key={i}
                        field={col}
                        header={col}
                        filter
                        filterPlaceholder={`Search by ${col}`}
                        style={{ overflow: "hidden" }} // Multi-line text support
                      />
                    ))}
                  </DataTable>
                </TabPanel>
              )
            ) : (
              <p>No data available</p>
            )}
          </TabView>
        </>
      )}
    </>
  );
}
