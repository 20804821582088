import React, { useEffect, useRef, useState } from "react";
import { Sidebar } from "primereact/sidebar";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { FloatLabel } from "primereact/floatlabel";
import { SelectButton } from "primereact/selectbutton";
import { Checkbox } from "primereact/checkbox";
import { Password } from "primereact/password";
import { Toast } from "primereact/toast";
import { Dropdown } from "primereact/dropdown";
import { Dialog } from "primereact/dialog";
import { useNavigate } from "react-router-dom";
import {
  getEditUserService,
  registerUserService,
  UpdateUserService,
} from "../API Services/PagesServices/UserService";
import { handleEncryptedData } from "../Encrypt API/ModifiedEncrypt";

import { checkEmptyField } from "../common/handleEmptyField";

const UserRegistration = ({ onClose, mail }) => {
  const toast = useRef(null);
  const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [isEditPage, setIsEditPage] = useState(false);

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    gender: "",
    email: "",
    phone: "",
    company: localStorage.getItem("company") || "",
    designation: "",
    permissions: null,
    userpassword: "",
    repeatPassword: "",
  });

  useEffect(() => {
    if (mail) {
      setIsEditPage(true);
      const fetchData = async () => {
        try {
          const response = await getEditUserService(mail);

          const data = response.data;
          setFormData({
            firstName: data.firstName || "",
            lastName: data.lastName || "",
            gender: data.gender || "",
            email: data.email || "",
            phone: data.phone || "",
            company: localStorage.getItem("company") || "",
            designation: data.designation || "",
            permissions: data.permissions || null,
            userpassword: "",
            repeatPassword: "",
          });
        } catch (err) {
          if (err.response) {
            toast.current.show({
              severity: "error",
              summary: "Error",
              detail: err.response.data,
              life: 3000,
            });
          }
        }
      };
      fetchData();
    } else {
      setIsEditPage(false);
      setFormData({
        firstName: "",
        lastName: "",
        gender: "",
        email: "",
        phone: "",
        company: localStorage.getItem("company") || "",
        designation: "",
        permissions: null,
        userpassword: "",
        repeatPassword: "",
      });
    }
  }, [mail]);

  // useEffect(() => {
  //   setIsValidEmail(emailRegex.test(formData.email));
  // }, [formData.email]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const [displayDialog, setDisplayDialog] = useState(false);
  const dialogFooter = (
    <div>
      <Button
        label="Close"
        onClick={() => {
          setDisplayDialog(false);
          // Navigate to home page when closing dialog
          navigate("/login");
        }}
      />
    </div>
  );

  const [showPasswordFields, setShowPasswordFields] = useState(false);
  const handlePasswordCheckboxChange = (e) => {
    setShowPasswordFields(e.checked);
  };

  const [emptyError, setEmptyError] = useState({});

  const handleSubmit = async (e) => {
    e.preventDefault();

    // const publicKey = await getPublicKeyInstance();

    let err = checkEmptyField(formData);
    if (!showPasswordFields) {
      delete err.userpassword;
      delete err.repeatPassword;
    }
    setEmptyError(err);

    formData.company = localStorage.getItem("company");

    if (Object.keys(err).length !== 0) return;
    else if (!emailRegex.test(formData.email)) {
      toast.current.show({
        severity: "error",
        summary: "Invalid Email",
        detail: "Please provide a valid email id",
        life: 3000,
      });
      return;
    }
    if (isEditPage) {
      if (formData.userpassword !== formData.repeatPassword) {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: `Password does not matched `,
          life: 3000,
        });
        return;
      }
    }

    try {
      setLoading(true);

      if (!isEditPage) {
        // const encryptedData = await handleEncryptedRequest(formData);
        const encryptedData = await handleEncryptedData(formData);

        let response = await registerUserService(encryptedData);
        if (response.status === 409) {
          toast.current.show({
            severity: "warn",
            summary: "Warning",
            detail: `Email Already Exists`,
            life: 3000,
          });
          return;
        }

        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "User Updated Successfully",
          life: 3000,
        });
        setTimeout(() => {
          onClose();
        }, 500);
      } else if (isEditPage) {
        try {
          const encryptedData = await handleEncryptedData(formData);

          let response = await UpdateUserService(mail, encryptedData).then(
            () => {
              toast.current.show({
                severity: "success",
                summary: "Success",
                detail: "User Updated Successfully",
                life: 3000,
              });

              if (mail === localStorage.getItem("userId")) {
                setDisplayDialog(true);
              } else {
                setTimeout(() => {
                  onClose();
                }, 500);
              }
            }
          );
        } catch (err) {
          if (err.response) {
            toast.current.show({
              severity: "error",
              summary: "Error",
              detail: err.response.data,
              life: 3000,
            });
          }
        }
      }
    } catch (e) {
      if (e.response && e.response.status === 403) {
        toast.current.show({
          severity: "warn",
          summary: "Warn",
          detail: e.response.data,
          life: 3000,
        });
      }
    } finally {
      setLoading(false);
    }
  };

  const permissionsList = ["ADMIN", "USER", "DEV", "QA"];

  const genderOptions = ["Male", "Female"];

  return (
    <>
      <Toast ref={toast} />

      <Sidebar
        visible={true}
        position="right"
        onHide={onClose}
        className="w-full md:w-4 lg:w-3"
      >
        <h2>{isEditPage ? "EditUser" : "Register"}</h2>
        <div className="w-full mt-5">
          <form onSubmit={handleSubmit} className="flex flex-column gap-5">
            <FloatLabel>
              <InputText
                id="firstName"
                name="firstName"
                className="w-full p-inputtext-sm"
                value={formData.firstName}
                onChange={handleChange}
                invalid={emptyError.firstName}
              />
              <label htmlFor="firstName">First Name</label>
            </FloatLabel>

            <FloatLabel>
              <InputText
                id="lastName"
                name="lastName"
                className="w-full p-inputtext-sm"
                value={formData.lastName}
                onChange={handleChange}
                invalid={emptyError.lastName}
              />
              <label htmlFor="lastName">Last Name</label>
            </FloatLabel>

            <div className="flex flex-column mt-m1">
              <label htmlFor="gender" className="text-xs">
                Gender
              </label>
              <SelectButton
                id="gender"
                name="gender"
                className="p-selectbutton-xs"
                value={formData.gender}
                onChange={handleChange}
                options={genderOptions}
                invalid={emptyError.gender}
              />
            </div>

            <FloatLabel>
              <InputText
                id="email"
                name="email"
                type="email"
                className="w-full p-inputtext-sm"
                value={formData.email}
                onChange={handleChange}
                disabled={isEditPage ? true : false}
                invalid={emptyError.email}
              />
              <label htmlFor="email">Email</label>
            </FloatLabel>

            <FloatLabel>
              <InputText
                id="phone"
                name="phone"
                keyfilter="int"
                className="w-full p-inputtext-sm"
                value={formData.phone}
                onChange={handleChange}
                invalid={emptyError.phone}
              />
              <label htmlFor="phone">Phone Number</label>
            </FloatLabel>

            <FloatLabel>
              <InputText
                id="designation"
                name="designation"
                className="w-full p-inputtext-sm"
                value={formData.designation}
                onChange={handleChange}
                invalid={emptyError.designation}
              />
              <label htmlFor="designation">Designation</label>
            </FloatLabel>

            <FloatLabel>
              <Dropdown
                id="permissions"
                name="permissions"
                value={formData.permissions}
                onChange={handleChange}
                options={permissionsList}
                placeholder="Select Permission"
                className="w-full"
                invalid={emptyError.permissions}
              />
              <label htmlFor="permissions">Permissions</label>
            </FloatLabel>

            {isEditPage && (
              <div className="flex align-items-center">
                <Checkbox
                  inputId="changePassword"
                  checked={showPasswordFields}
                  onChange={handlePasswordCheckboxChange}
                />
                <label htmlFor="changePassword" className="ml-2">
                  Change Password
                </label>
              </div>
            )}

            {!isEditPage || (isEditPage && showPasswordFields) ? (
              <>
                <FloatLabel>
                  <Password
                    id="userpassword"
                    name="userpassword"
                    className="w-full"
                    value={formData.userpassword}
                    feedback={false}
                    onChange={handleChange}
                    placeholder="Password"
                    invalid={emptyError.userpassword}
                  />
                  <label htmlFor="userpassword">Password</label>
                </FloatLabel>

                <FloatLabel>
                  <Password
                    id="repeatPassword"
                    name="repeatPassword"
                    className="w-full"
                    value={formData.repeatPassword}
                    feedback={false}
                    onChange={handleChange}
                    placeholder="Password"
                    invalid={emptyError.repeatPassword}
                  />
                  <label htmlFor="repeatPassword">Confirm Password</label>
                </FloatLabel>
              </>
            ) : null}

            <Button
              label={isEditPage ? "Update" : "Register"}
              type="submit"
              icon="pi pi-check"
              severity="primary"
              className="border-round-lg"
              loading={loading}
            />
          </form>
        </div>
      </Sidebar>

      <Dialog
        header="Profile Update"
        visible={displayDialog}
        style={{ width: "50vw" }}
        footer={dialogFooter}
        onHide={() => {
          setDisplayDialog(false); // Close the dialog
          navigate("/login"); // Navigate to the home page
        }}
      >
        <p>You have recently Updated your profile Please Login again</p>
      </Dialog>
    </>
  );
};

export default UserRegistration;
