import React from "react";
import { Skeleton } from "primereact/skeleton";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "../RuleList.css";

export default function RuleListSkeleton({ rows = 10 }) {
  const items = Array.from({ length: rows }, (_, i) => i);

  return (
    <div className="card">
      {/* Skeleton for Header Section */}
      <div className="current-view flex justify-content-end align-items-center mb-3">
        {/* <div width="2rem" /></div> */}
        <div className="flex align-items-center gap-2">
          <Skeleton width="10rem" height="2rem" className="mr-2" />
          <Skeleton width="7rem" height="2rem" className="mr-2" />
          <Skeleton width="12rem" height="2rem" />
        </div>
      </div>

      {/* Skeleton for Table */}
      <DataTable value={items}>
        <Column field="ruleID" header="Sl No" body={<Skeleton />} />
        <Column field="fileName" header="Job Name" body={<Skeleton />} />
        <Column field="createdTime" header="Created Time" body={<Skeleton />} />
        <Column
          field="modifiedTime"
          header="Modified Time"
          body={<Skeleton />}
        />
        <Column field="createdby" header="Created By" body={<Skeleton />} />
        <Column field="updatedby" header="Updated By" body={<Skeleton />} />
        <Column field="jiraUrl" header="JIRA URL" body={<Skeleton />} />
        <Column field="status" header="Status" body={<Skeleton />} />
        <Column field="action" header="Action" body={<Skeleton />} />
      </DataTable>
    </div>
  );
}
